import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import moment from "moment";
import axios from "axios";
import Svg from "../Svg";
import { parseError, formatMoney } from "../../helpers/common";
import { formatPhoneNumber } from "../../assets/js/formatePhoneNumber";
import { useTranslation } from "react-i18next";
import authProvider from "../../helpers/authProvider";
import { Button } from "reactstrap";

const CheckoutDetails = (props) => {
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [people, setPeople] = useState([]);
  const [address, setAddress] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [appointmentType, setAppointmentType] = useState("scheduled");
  const [discountCode, setDiscountCode] = useState();
  const [costData, setCostData] = useState(null);

  useEffect(() => {
    setPeople(props.people);
  }, [props.people]);

  useEffect(() => {
    setAddress(props.address);
  }, [props.address]);

  useEffect(() => {
    setScheduledDate(props.scheduledDate);
  }, [props.scheduledDate]);

  useEffect(() => {
    setAppointmentType(props.appointmentType);
  }, [props.appointmentType]);

  useEffect(() => {
    setDiscountCode(props.discountCode);
  }, [props.discountCode]);

  useEffect(() => {
    calculateCost();
  }, [people, address, scheduledDate, appointmentType, discountCode]);

  useEffect(() => {
    if (props.costUpdated) {
      props.costUpdated(costData);
    }
  }, [costData]);

  useEffect(() => {
    if (props.costData) {
      setCostData(props.costData);
    }
  }, [props.costData]);

  const shouldCalculateCost = () => {
    if (props.editMode && props.appointment) {
      let oldLocation = props.appointment.location;
      let oldDiscount = props.appointment.discountCodeUsed;
      let oldDate = props.appointment.startTime;
      let oldServices = props.appointment.appointmentPatients
        .flatMap((ap) => ap.appointmentItems)
        .map((s) => s.service);
      let currentServices = people.flatMap((ap) => ap.services);

      if (
        !address ||
        address.latitude !== oldLocation.latitude ||
        address.longitude !== oldLocation.longitude
      ) {
        return true;
      }

      if (oldDiscount !== discountCode) {
        return true;
      }

      var allTheSameServices =
        oldServices.every(
          (os) =>
            oldServices.filter((s) => s.id === os.id).length ===
            currentServices.filter((cs) => cs.id === os.id).length
        ) &&
        currentServices.every(
          (cs) =>
            currentServices.filter((s) => s.id === cs.id).length ===
            oldServices.filter((os) => os.id === cs.id).length
        );

      if (
        currentServices.length !== oldServices.length ||
        !allTheSameServices
      ) {
        return true;
      }

      if (oldDate && scheduledDate) {
        const oldStartDate = moment(
          `${oldDate.day}/${oldDate.month}/${oldDate.year} ${oldDate.hour}:${oldDate.minute}:${oldDate.second}`,
          "DD/MM/YYYY HH:mm:ss"
        );
        const currentStartDate = moment(scheduledDate);
        const differenceInMinutes = oldStartDate.diff(
          currentStartDate,
          "minutes"
        );

        return differenceInMinutes > 0;
      }

      return false;
    }

    return true;
  };

  const calculateCost = () => {
    if (loading) {
      setTimeout(() => {
        calculateCost();
      }, 1500);
      return;
    }

    if (!shouldCalculateCost() || props.disabled === true) {
      if (props.appointment) {
        setCostData(props.appointment.cost);
      }
      return;
    }

    setLoading(true);
    setError(null);
    setCostData(null);

    if (
      !address ||
      !appointmentType ||
      (appointmentType === "scheduled" && !scheduledDate) ||
      people.length === 0 ||
      people.filter((s) => !s.services || s.services.length === 0).length > 0
    ) {
      setLoading(false);
      return;
    }

    const allServices = people.flatMap((s) => s.services);
    const serviceIds = allServices
      .map((s) => s.id)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

    const requestedServices = serviceIds.map((sId) => {
      return {
        serviceId: sId,
        count: allServices.filter((s) => s.id === sId).length,
      };
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/appointments/calculate-cost`, {
        province: address.provinceCode || address.province,
        discountCode: discountCode,
        appointmentCode: props.appointment ? props.appointment.id : null,
        services: requestedServices,
        type: appointmentType,
        profile: props.appointment
          ? props.appointment.profile
          : authProvider.getCurrentProfileType(),
        location: {
          latitude: address.latitude,
          longitude: address.longitude,
        },
        startTime:
          appointmentType === "asap"
            ? null
            : {
                year: scheduledDate.getFullYear(),
                month: scheduledDate.getMonth() + 1,
                day: scheduledDate.getDate(),
                hour: scheduledDate.getHours(),
                minute: scheduledDate.getMinutes(),
              },
      })
      .then((res) => {
        setLoading(false);
        setCostData(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setError(error);
        setCostData(null);
        setLoading(false);
      });
  };

  return (
    <section
      className="checkout-section d-flex flex-column gap-16"
      style={Object.assign({}, props.style || {})}>
      {people.map((person, index) => {
        return (
          <div key={index} className="avvy-card d-flex flex-column gap-12">
            <div className="d-flex flex-column gap-6">
              <div
                className="d-flex flex-row mr-auto"
                style={{ alignItems: "center", gap: "12px" }}>
                <Svg
                  className="avvy-card-icon"
                  name="user-icon"
                  alt="Person Avatar"
                />
                <span className="avvy-card-header">
                  {person.firstName} {person.lastName}
                </span>
              </div>
            </div>

            <div className="d-flex flex-column" style={{ gap: "6px" }}>
              <div className="app-details-row">
                <span className="app-details-label">
                  {t("newBooking.email")}
                </span>
                <span className="app-details-value">{person.email}</span>
              </div>
              <div className="app-details-row">
                <span className="app-details-label">
                  {t("newBooking.phone")}
                </span>
                <span className="app-details-value">
                  {formatPhoneNumber(person.phone)}
                </span>
              </div>
            </div>

            {person.services && person.services.length > 0 ? (
              <div className="avvy-card-footer">
                {person.services.map((service, svcIndex) => {
                  let servicePrice = service.price;
                  let dynamicPrice = false;

                  if (costData && costData.services) {
                    const realPrice = costData.services.find(
                      (s) => s.id === service.id
                    );
                    if (realPrice) {
                      servicePrice = realPrice.price;
                      dynamicPrice = realPrice.dynamicPrice;
                    }
                  }

                  return (
                    <div className="d-flex flew-row gap-12" key={svcIndex}>
                      <span
                        className="theme-dark-text bold"
                        style={{ fontSize: "14px" }}>
                        {service.title || service.service}
                      </span>
                      <span
                        className="main-text bold ml-auto"
                        style={{ fontSize: "14px" }}>
                        {formatMoney(servicePrice, i18n.language)}
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="avvy-card-footer">
                <span className="theme-dark-text" style={{ fontSize: "14px" }}>
                  {t("services.emptyText")}
                </span>
              </div>
            )}
          </div>
        );
      })}

      <div
        className="d-flex flex-row gap-12"
        style={{ paddingRight: "16px", paddingLeft: "16px" }}>
        <span className="main-text bold">{t("newBooking.subtotal")}</span>
        <span className="ml-auto theme-dark-text bold">
          {costData ? `${formatMoney(costData.subtotal, i18n.language)}` : "--"}
        </span>
      </div>

      <div
        className="avvy-card-blue d-flex flex-column gap-12"
        style={{ fontSize: "14px" }}>
        <div className="d-flex flex-row gap-12">
          <span className="white-text" style={{ fontSize: "14px" }}>
            {t("newBooking.discount")}
          </span>
          <span className="ml-auto white-text">
            {costData
              ? `-${formatMoney(costData.discount, i18n.language)}`
              : "--"}
          </span>
        </div>
      </div>

      <hr
        className="m-0"
        style={{ background: "var(--theme-dark-color)", height: "1px" }}
      />

      <div
        className="d-flex flex-row gap-12 bold"
        style={{ paddingRight: "16px", paddingLeft: "16px", fontSize: "18px" }}>
        <span className="main-text bold">{t("newBooking.total")}</span>
        <span className="ml-auto theme-dark-text bold">
          {costData ? `${formatMoney(costData.total, i18n.language)}` : "--"}
        </span>
      </div>
      <Button
        className="ml-auto btn-accent"
        color={props.checkoutEnabled ? "" : "success"}
        disabled={props.checkoutEnabled}
        onClick={() => {
          props.handleCheckOutBtn();
        }}>
        {t("newBooking.checkoutBtn")}
      </Button>
      {props.showPaidAmount === true &&
        costData != null &&
        costData.amountPaid > 0 && (
          <React.Fragment>
            <hr className="m-0" />
            <div
              className="d-flex flex-row gap-12 bold"
              style={{
                paddingRight: "16px",
                paddingLeft: "16px",
                fontSize: "14px",
              }}>
              <span className="main-text bold">
                {t("newBooking.amountPaid")}
              </span>
              <span className="ml-auto main-text bold">
                {costData
                  ? `${formatMoney(costData.amountPaid, i18n.language)}`
                  : "--"}
              </span>
            </div>

            <div
              className="d-flex flex-row gap-12 bold"
              style={{
                paddingRight: "16px",
                paddingLeft: "16px",
                fontSize: "14px",
                marginTop: "-6px",
              }}>
              <span
                className={`bold ${
                  costData.toPay > 0 ? "error-text" : "success-text"
                }`}>
                {t("newBooking.toPay")}
              </span>
              <span
                className={`ml-auto bold ${
                  costData.toPay > 0 ? "error-text" : "success-text"
                }`}>
                {costData
                  ? `${formatMoney(costData.toPay, i18n.language)}`
                  : "--"}
              </span>
            </div>
          </React.Fragment>
        )}

      {loading && <Loader title={t("common.loading")} />}

      {error && (
        <div
          className="error-alert"
          style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}>
          {error}
        </div>
      )}
    </section>
  );
};

export default CheckoutDetails;
