import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import Index from "../Index"
import { Button } from "reactstrap"
import SectionImage from "../../assets/img/backgrounds/welcome.jpg"
import { useHistory } from "react-router-dom"
import { AUTH_LOGIN_URL, AUTH_SIGN_UP_PERSONAL_INFO_URL, AUTH_WELCOME_URL } from "../../urls"
import { useTranslation } from "react-i18next"
import Svg from "../../components/Svg"

const Welcome = (props) => {
  const { t } = useTranslation("common")
  const history = useHistory()

  return (
    <React.Fragment>
      <Helmet>
        <title>Noosu - {t("welcome.banner")}</title>
      </Helmet>

      <Index image={SectionImage} {...props}>
        <Svg className="landing-logo accent-text" name="logo-nav" style={{ height: "44px" }} />
        <h4 className="text-white position-relative font-weight-400 text-left mb-5" style={{ fontSize: "20px" }}>
          {t("welcome.subText")}
        </h4>
        <Link to={AUTH_SIGN_UP_PERSONAL_INFO_URL} className="w-full">
          <Button className="btn-accent" onClick={() => history.push(AUTH_WELCOME_URL)}>
            {t("welcome.createAccount")}
          </Button>
        </Link>

        <div className="d-flex align-baseline text-white text-center">
          <span className="mr-2">{t("login.haveAccount")}</span>
          <Link to={AUTH_LOGIN_URL} className="text-white">
            <span className="text-underline">{t("common.signIn")}</span>
          </Link>
        </div>
      </Index>
    </React.Fragment>
  )
}

export default Welcome
