import React, { useState, useEffect } from "react";
import Svg from "../Svg";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import authProvider from "../../helpers/authProvider";
import axios from "axios";

const ProfileTypeMenu = (props) => {
  const { t } = useTranslation("common");
  const [profileType, setProfileType] = useState(
    authProvider.getCurrentProfileType()
  );
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const currentUser = authProvider.getUser();

  useEffect(() => {
    if (profileType === "corporate") {
      getcorporateProfile();
    }
  }, []);

  const getcorporateProfile = () => {
    const profileType = authProvider.getCurrentProfileType();

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/CorporateClients/${currentUser?.corporateClientId}`
      )
      .then((res) => {
        localStorage.setItem(
          "isBilledExternally",
          res?.data?.isBilledExternally
        );
      })
      .catch((err) => {});
  };

  return (
    <UncontrolledDropdown
      nav
      style={props.style || {}}
      className={props.className || ""}
      isOpen={profileMenuOpen}
      toggle={(e) => {
        setProfileMenuOpen((prev) => !prev);
      }}>
      <DropdownToggle nav className="p-0">
        <span className="nav-link-inner--text nav-profile-text text-small text-white">
          {profileType === "personal"
            ? t("corporate.personalAccount")
            : t("corporate.corporateAccount")}
        </span>
        <Svg
          className="w-16 h-16 ml-1 nav-profile-text text-white"
          name="arrow-down"
        />
      </DropdownToggle>

      <DropdownMenu
        style={{
          top: "48px",
          left: "-10%",
          overflow: "auto",
          minWidth: "280px",
          padding: 0,
        }}>
        <DropdownItem
          onClick={() => {
            localStorage.setItem("profileType", "personal");
            localStorage.removeItem("isBilledExternally");
            window.dispatchEvent(new CustomEvent("profileTypeChanged"));
            setProfileType("personal");
            window.location.reload();
          }}>
          <div className="nav px-2 py-2 items-center d-flex">
            <span className="mr-2">
              <Svg
                name="checkmark"
                className="w-24 h-24 accent-text"
                style={{ opacity: profileType === "personal" ? 1 : 0 }}
              />
            </span>
            <span className="bold">{t("corporate.personalAccount")}</span>
          </div>
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            localStorage.setItem("profileType", "corporate");
            setProfileType("corporate");
            window.dispatchEvent(new CustomEvent("profileTypeChanged"));
            window.location.reload();
          }}>
          <div className="nav px-2 py-2 items-center d-flex">
            <span className="mr-2">
              <Svg
                name="checkmark"
                className="w-24 h-24 accent-text"
                style={{ opacity: profileType === "corporate" ? 1 : 0 }}
              />
            </span>
            <span className="bold">{t("corporate.corporateAccount")}</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ProfileTypeMenu;
