import React, { useEffect, useState, useRef } from "react";
import SideBar from ".";
import ReactDatetime from "react-datetime";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import {
  Button,
  FormGroup,
  InputGroup,
  Input,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
  Dropdown,
  InputGroupAddon,
  InputGroupText,
  DropdownMenu,
  Label,
} from "reactstrap";
import EditAddressMap from "../SideBar/EditAddressMap.js";
import ChangePassword from "../SideBar/ChangePassword.js";
import { useTranslation } from "react-i18next";
import Svg from "../Svg";
import authProvider from "../../helpers/authProvider";
import { parseError, dateFormat, formatFullAddress } from "../../helpers/common";
import { toast } from "react-toastify";

const EditPersonInfoSidebar = (props) => {
  const inputFile = useRef(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState(null);
  const [location, setLocation] = useState(null);
  const [allergies, setAllergies] = useState("");
  const [date, setDate] = useState(null);
  const [phoneNumber, setPhone] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [phoneVerificationField, setPhoneVerificationField] = useState(false);
  const [phoneVerificationCode, setPhoneVerificationCode] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true);
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);

  const [gender, setGender] = useState(null);
  const [healthCareCardProvince, setHealthCareCardProvince] = useState(null);
  const [healthCareCardNumber, setHealthCareCardNumber] = useState("");
  const [healthCareCardExpiry, setHealthCareCardExpiry] = useState(null);
  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [insuranceProvider, setInsuranceProvider] = useState("");

  const [cardNumberError, setCardNumberError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [DOBError, setDOBError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [provinceError, setProvinceError] = useState(null);

  const [locationSideBarOpen, setLocationSideBarOpen] = useState(false);
  const [changePasswordSidebarOpen, setChangePasswordSidebarOpen] = useState(false);
  const [provincesOpen, setProvincesOpen] = useState(false);

  const [existingHealthCardFile, setExistingHealthCardFile] = useState(null);
  const [uploadedHealthCardFile, setUploadedHealthCardFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [allProvinces, setAllProvinces] = useState([]);

  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    if (props.editPerson && props.isOpen) {
      getProvinces();
      initializePersonDetails(props.editPerson);
    } else {
      resetState();
    }
  }, [props.editPerson, props.isOpen]);

  const getProvinces = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/platform/provinces`)
      .then((res) => {
        setAllProvinces(res.data);
        if (
          !healthCareCardProvince &&
          props.editPerson &&
          props.editPerson.healthCareCard &&
          props.editPerson.healthCareCard.province
        ) {
          setHealthCareCardProvince(
            res.data.find((g) => g.key === props.editPerson.healthCareCard.province)
          );
        }
      })
      .catch((err) => {
        console.error(err);
        setAllProvinces([]);
      });
  };

  const initializePersonDetails = (personData) => {
    let address = personData.address;
    if (address) {
      address.placeName = formatFullAddress(personData.address);
      address.fullAddress = address.placeName;
      if (personData.location) {
        Object.assign(address, personData.location);
      }
    }
    setLocation(personData.location);
    setFirstName(personData.firstName);
    setLastName(personData.lastName);
    setEmail(personData.email);
    if (personData.dateOfBirth) {
      let dob = moment(
        `${personData.dateOfBirth.day}/${personData.dateOfBirth.month}/${personData.dateOfBirth.year}`,
        "DD/MM/YYYY"
      ).toDate();
      setDate(dob);
    }
    setPhone(personData.phone);
    setGender(personData.gender || null);
    setAddress(address);
    setAllergies(personData.issuesAllergies || "");

    if (personData.insurance) {
      setInsuranceNumber(personData.insurance.policyNumber || "");
      setInsuranceProvider(personData.insurance.provider || "");
    }

    if (personData.healthCareCard) {
      setHealthCareCardNumber(personData.healthCareCard.cardNumber);

      if (personData.healthCareCard.expiryDate) {
        let hcExpiryDate = moment(
          `${personData.healthCareCard.expiryDate.day}/${personData.healthCareCard.expiryDate.month}/${personData.healthCareCard.expiryDate.year}`,
          "D/M/YYYY"
        );

        setHealthCareCardExpiry(hcExpiryDate.isValid() ? hcExpiryDate.toDate() : null);
      } else {
        setHealthCareCardExpiry(null);
      }
      setHealthCareCardProvince(
        (allProvinces || []).find(
          (province) => province.key === personData.healthCareCard.province
        ) || {
          key: "QC",
        }
      );

      if (personData.healthCareCard.picture && personData.healthCareCard.picture.fileName) {
        setExistingHealthCardFile(personData.healthCareCard.picture.fileName);

        if (personData.type === "me" || personData.id === authProvider.getUser().id) {
          getHealthCardImagePreview(`${process.env.REACT_APP_API_URL}/account/health-card`);
        }
      }
    } else {
      setProvinceError(<small className="text-danger">{t("healthCare.provinceError")}</small>);
      setCardNumberError(
        <small className="text-danger">{t("healthCare.healthCardNumberError")}</small>
      );
    }
  };

  const preparePersonObject = () => {
    let person = Object.assign({}, props.editPerson || {});

    person.firstName = firstName;
    person.lastName = lastName;
    person.phone = phoneNumber;
    person.email = email;
    person.dateOfBirth = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    person.gender = gender;
    // person.healthCareCard = {
    //   cardNumber: healthCareCardNumber,
    //   expiryDate:
    //     healthCareCardExpiry != null
    //       ? {
    //           year: healthCareCardExpiry.getFullYear(),
    //           month: healthCareCardExpiry.getMonth() + 1,
    //           day: healthCareCardExpiry.getDate(),
    //         }
    //       : null,
    //   picture: {
    //     fileName: uploadedHealthCardFile ? null : existingHealthCardFile,
    //     uploadedFile: uploadedHealthCardFile,
    //   },
    //   province: healthCareCardProvince.key,
    // };
    person.insurance = {
      policyNumber: insuranceNumber,
      provider: insuranceProvider,
    };
    person.issuesAllergies = allergies;
    person.address = address;
    person.location = location;
    return person;
  };

  const onPersonUpdate = () => {
    let person = preparePersonObject();
    updatePerson(person);
  };

  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updatePerson = async (data) => {
    let user = {
      id: data.id,
      picture: {
        fileName: "",
      },
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      address: {
        province: data.address.provinceCode || data.address.province,
        city: data.address.city,
        street: data.address.street,
        postalCode: data.address.postalCode,
        unitNumber: data.address.unitNumber,
      },
      healthCareCard: data.healthCareCard,
      insurance: data.insurance,
      gender: data.gender,
      dateOfBirth: data.dateOfBirth,
      location: data.location,
      issuesAllergies: data.issuesAllergies,
    };

    let uploadCompleted = false;
    setLoading(true);
    setError(null);

    try {
      if (user.healthCareCard?.picture?.uploadedFile) {
        const file = user.healthCareCard?.picture?.uploadedFile;
        let formData = new FormData();
        formData.append("file", file, file.name);

        let fileRes = await axios.post(
          `${process.env.REACT_APP_API_URL}/file/upload/healthcard`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        user.healthCareCard.picture = {
          fileName: fileRes.data,
        };
      }

      uploadCompleted = true;
    } catch (err) {
      let error = parseError(err);
      setError(error);
    }

    if (!uploadCompleted) {
      setLoading(false);
      return;
    }

    axios
      .put(`${process.env.REACT_APP_API_URL}/account`, user)
      .then((res) => {
        authProvider.setUser(res.data);
        toast.dark(t("personalInfo.updatedText"), {
          position: "top-left",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        props.toggle();
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const resetState = () => {
    setCardNumberError(null);
    setEmailError(null);
    setFirstNameError(null);
    setLastNameError(null);
    setDOBError(null);
    setPhoneError(null);
    setProvinceError(null);

    setFirstName("");
    setLastName("");
    setEmail("");
    setDate(null);
    setPhone("");
    setAddress(null);
    setLocation(null);
    setAllergies("");
    setGender(null);
    setHealthCareCardProvince(null);
    setHealthCareCardNumber("");
    setHealthCareCardExpiry(null);
    setInsuranceNumber("");
    setInsuranceProvider("");
    setAllergies("");
    setAllProvinces([]);

    removeImage();

    setResendDisabled(true);
    setVerifyButtonDisabled(false);
    setNewPhoneNumber("");
    setPhoneVerificationCode("");
  };

  const handleNameValidation = (e, type) => {
    if (type === "firstName") {
      setFirstName(e.target.value);
      setFirstNameError(
        e.target.value ? null : (
          <small className="text-danger">{t("personalInfo.firstNameError")}</small>
        )
      );
    } else if (type === "lastName") {
      setLastName(e.target.value);
      setFirstNameError(
        e.target.value ? null : (
          <small className="text-danger">{t("personalInfo.lastNameError")}</small>
        )
      );
    }
  };

  const submitVerificationCode = () => {
    let data = {
      phone: newPhoneNumber,
      code: phoneVerificationCode,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/confirmphone`, data)
      .then((res) => {
        setPhone(newPhoneNumber);
        setNewPhoneNumber("");
        setPhoneVerificationField(false);
        showSuccess(t("personalInfo.phoneVerified"));
      })
      .catch((err) => {
        let error = parseError(err);
        showError(error);
        setTimeout(() => setResendDisabled(false), 60000);
      });
  };

  const handleSendPhoneCode = () => {
    setResendDisabled(true);
    if (newPhoneNumber !== phoneNumber && newPhoneNumber.length !== 0 && phoneError == null) {
      setPhoneVerificationField(true);
      setVerifyButtonDisabled(true);

      axios
        .post(`${process.env.REACT_APP_API_URL}/account/sms/${encodeURIComponent(newPhoneNumber)}`)
        .then((res) => {
          setTimeout(() => setResendDisabled(false), 60000);
          showSuccess("Verification code sent to " + newPhoneNumber);
        })
        .catch((err) => {
          let error = parseError(err);
          showError(error);
          setTimeout(() => setResendDisabled(false), 60000);
        });
    } else {
      toast.dark(t("personalInfo.samePhone"), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const resendVerificationCode = () => {
    setResendDisabled(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/sms/${encodeURIComponent(newPhoneNumber)}`)
      .then((res) => {
        setTimeout(() => setResendDisabled(false), 60000);
        showSuccess(`${t("personalInfo.verificationCodeSent")} ${newPhoneNumber}`);
      })
      .catch((err) => {
        let error = parseError(err);
        showError(error);
        setTimeout(() => setResendDisabled(false), 60000);
      });
  };

  const showSuccess = (message) => {
    toast.dark(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const disableFutureDt = (current) => {
    return moment(current).isBefore(moment(), "day");
  };

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setUploadedHealthCardFile(file);

      try {
        setPreviewImage(URL.createObjectURL(file));
      } catch (err) {
        console.error(err);
      }
    }
  };

  const removeImage = () => {
    if (previewImage) {
      try {
        URL.revokeObjectURL(previewImage);
      } catch {}
    }

    setPreviewImage(null);
    setExistingHealthCardFile(null);
    setUploadedHealthCardFile(null);

    if (inputFile && inputFile.current && inputFile.current.value) {
      try {
        inputFile.current.value = "";
      } catch (err) {}
    }
  };

  const getHealthCardImagePreview = (url) => {
    axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        try {
          const base64 = btoa(
            new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
          );
          setPreviewImage("data:;base64," + base64);
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const Footer = () => {
    return (
      <Row>
        <Col lg={6}></Col>
        <Col lg={6} className="content-end">
          <Button
            size="sm"
            className="px-5 py-2 ml-auto btn-accent"
            disabled={
              !firstName ||
              firstNameError !== null ||
              !lastName ||
              lastNameError !== null ||
              !date ||
              !address ||
              !location ||
              !phoneNumber ||
              phoneError !== null ||
              !email ||
              emailError !== null
              // || !healthCareCardProvince ||
              // provinceError !== null ||
              // !healthCareCardNumber ||
              // cardNumberError !== null ||
              // (!existingHealthCardFile && !uploadedHealthCardFile)
            }
            onClick={() => {
              onPersonUpdate();
            }}
          >
            {t("editPerson.btnSave")}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <React.Fragment>
      <SideBar
        loading={loading}
        error={error}
        onOverlayDismiss={() => setError(null)}
        title={t("editPerson.title")}
        {...props}
        footer={<Footer />}
      >
        <div className="d-flex flex-column gap-12" style={{ color: "black" }}>
          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.firstNameLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                value={firstName}
                maxLength={50}
                onChange={(e) => handleNameValidation(e, "firstName")}
                placeholder={t("personalInfo.firstNamePlaceholder")}
                type="text"
              />
            </InputGroup>
            {firstNameError}
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.lastNameLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                value={lastName}
                maxLength={50}
                onChange={(e) => handleNameValidation(e, "lastName")}
                placeholder={t("personalInfo.lastNamePlaceholder")}
                type="text"
              />
            </InputGroup>
            {lastNameError}
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.emailLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                value={email}
                placeholder={t("personalInfo.emailPlaceholder")}
                type="email"
                disabled={true}
              />
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.phoneLabel")}</Label>
            <div className="form-group phone-input">
              <PhoneInput
                maxLength="16"
                defaultCountry="CA"
                extension="true"
                placeholder={t("personalInfo.phonePlaceholder")}
                value={phoneNumber}
                onChange={(value) => {
                  setNewPhoneNumber(value);
                  if (value && !isPossiblePhoneNumber(value)) {
                    setPhoneError(
                      <small className="text-danger px-4">{t("personalInfo.phoneError")}</small>
                    );
                  } else {
                    setPhoneError(null);
                  }
                }}
                required
              />
              {phoneError}
            </div>
          </FormGroup>
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              className="btn-accent ml-auto"
              disabled={verifyButtonDisabled}
              onClick={() => {
                handleSendPhoneCode();
              }}
            >
              {t("personalInfo.verifyPhoneBtn")}
            </Button>
            {phoneVerificationField && (
              <Button disabled={resendDisabled} onClick={() => resendVerificationCode()}>
                {t("accountVerification.ButtonResend")}
              </Button>
            )}
            {phoneVerificationField && (
              <Button
                onClick={() => {
                  setPhoneVerificationField(false);
                  setResendDisabled(true);
                }}
              >
                {t("dashboard.cancelBtn")}
              </Button>
            )}
          </div>
          {phoneVerificationField && (
            <FormGroup>
              <Label className="bold-blue">{t("accountVerification.title")}</Label>
              <div
                style={{
                  display: "flex",
                }}
              >
                <InputGroup className="input-group-alternative">
                  <Input
                    required
                    onChange={(e) => setPhoneVerificationCode(e.target.value)}
                    placeholder={t("accountVerification.placeholder")}
                    type="text"
                  />
                </InputGroup>
                <Button
                  onClick={() => {
                    submitVerificationCode();
                  }}
                >
                  {t("personalInfo.verifyCodeBtn")}
                </Button>
              </div>
            </FormGroup>
          )}

          <FormGroup style={{ color: "black" }}>
            <Label className="bold-blue">{t("personalInfo.dateOfBirthLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  className: "dob-readonly-input form-control",
                  placeholder: t("personalInfo.dateOfBirthLabel"),
                  readOnly: true,
                }}
                locale={i18n.language}
                dateFormat={dateFormat}
                onBlur={(event) => {
                  event === "" &&
                    setDOBError(
                      <small className="text-danger">{t("personalInfo.dateOfBirthError")}</small>
                    );
                }}
                onChange={(e) => {
                  if (e && typeof e.toDate === "function") {
                    setDate(e.toDate());
                    setDOBError(null);
                  } else {
                    setDOBError(
                      <small className="text-danger">{t("personalInfo.dateOfBirthError")}</small>
                    );
                  }
                }}
                value={date}
                timeFormat={false}
                closeOnSelect={true}
                isValidDate={disableFutureDt}
              />
            </InputGroup>
            {DOBError}
          </FormGroup>
          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.addressLabel")}</Label>
            <div
              onClick={() => setLocationSideBarOpen(true)}
              color="link"
              className="input-group-alternative"
              style={{ borderRadius: "0.25rem", padding: "0.625rem 0.75rem" }}
            >
              <Col
                lg={12}
                style={{ paddingLeft: 0, paddingRight: 0, color: "black" }}
                className="d-flex justify-content-between"
              >
                {address && <span>{address.fullAddress}</span>}
                <Svg name="icon_location_outline" className="w-24 h-24 accent-text" />
              </Col>
            </div>
          </FormGroup>

          <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />

          <FormGroup>
            <Label className="bold-blue">{t("allergies.subText")}</Label>
            <InputGroup>
              <Input
                style={{ height: 100 }}
                type="textarea"
                value={allergies}
                placeholder={t("allergies.allergiesPlaceholder")}
                onChange={(event) => {
                  setAllergies(event.target.value);
                }}
              />
            </InputGroup>
          </FormGroup>

          <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />

          {/* <FormGroup>
            <Label className="bold-blue">{t("healthCare.provinceLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Dropdown
                direction="down"
                style={{ width: "100%", height: "44px" }}
                isOpen={provincesOpen}
                toggle={() => setProvincesOpen((prev) => !prev)}
              >
                <Svg
                  className={provincesOpen ? "flip-y w-24 h-24" : "w-24 h-24"}
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    pointerEvents: "none",
                  }}
                  name="icon_down"
                />
                <DropdownToggle
                  tag="div"
                  data-toggle="dropdown"
                  style={{
                    cursor: "pointer",
                    padding: "9.5px 12px",
                    width: "100%",
                    color: "black",
                  }}
                  aria-expanded={provincesOpen}
                >
                  {healthCareCardProvince
                    ? healthCareCardProvince.value
                    : t("healthCare.provincePlaceholder")}
                </DropdownToggle>
                <DropdownMenu style={{ width: "100%", marginTop: 10, padding: "10px" }}>
                  {allProvinces != null &&
                    allProvinces.map((item) => {
                      return (
                        <DropdownItem
                          key={item.key}
                          onClick={() => {
                            setHealthCareCardProvince(item);
                            setProvinceError(null);
                          }}
                        >
                          {item.value}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </InputGroup>
            {provinceError}
          </FormGroup>

          <div className="d-flex items-start flex-row gap-16">
            <FormGroup style={{ width: "50%" }}>
              <Label className="bold-blue">{t("healthCare.healthCardNumberLabel")}</Label>
              <InputGroup className="input-group-alternative">
                <Input
                  value={healthCareCardNumber}
                  onChange={(event) => {
                    setHealthCareCardNumber(event.target.value);
                  }}
                  onBlur={(event) => {
                    event.target.value === ""
                      ? setCardNumberError(
                          <small className="text-danger">
                            {t("healthCare.healthCardNumberError")}
                          </small>
                        )
                      : setCardNumberError(null);
                  }}
                  placeholder={t("healthCare.healthCardNumberPlaceholder")}
                  type="text"
                />
              </InputGroup>
              {cardNumberError}
            </FormGroup>

            <FormGroup style={{ width: "50%", color: "black" }}>
              <Label className="bold-blue">{t("healthCare.expiryDateLabel")}</Label>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  locale={i18n.language}
                  dateFormat={dateFormat}
                  style={{ color: "black" }}
                  className="right-picker"
                  onChange={(e) => {
                    if (e && typeof e.toDate === "function") {
                      setHealthCareCardExpiry(e.toDate());
                    } else {
                      setHealthCareCardExpiry(null);
                    }
                  }}
                  value={healthCareCardExpiry}
                  timeFormat={false}
                  closeOnSelect={true}
                  inputProps={{
                    autoComplete: "off",
                    placeholder: t("healthCare.expiryDatePlaceholder"),
                    className: "dob-readonly-input form-control",
                  }}
                />
              </InputGroup>
              <p
                className="sub-text text-small"
                style={{ marginTop: "6px", marginBottom: 0, fontWeight: 400 }}
              >
                {t("personalInfo.healthCardExpiryNote")}
              </p>
            </FormGroup>
          </div>

          {uploadedHealthCardFile || existingHealthCardFile ? (
            <div
              style={{
                overflow: "hidden",
                position: "relative",
                minHeight: "160px",
                border: "1px solid var(--border-color)",
                borderRadius: "6px",
                background: "#f8f8f8",
              }}
              className="upload d-flex flex-column"
            >
              <Button
                onClick={() => removeImage()}
                color="link"
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#EA1B27",
                  background: "white",
                  position: "absolute",
                  right: "6px",
                  top: "6px",
                }}
              >
                <Svg name="icon_cancel" style={{ width: "24px", height: "24px" }} />
              </Button>

              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "360px",
                  objectPosition: "top",
                  objectFit: "contain",
                }}
                alt="Card Preview"
                src={previewImage}
              />
            </div>
          ) : (
            <div className="upload-file">
              <div
                className="upload-file-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  inputFile.current.click();
                }}
              >
                <div className="d-flex flex-column items-center gap-6">
                  <Svg
                    name="icon_camera"
                    className="theme-dark-text"
                    style={{ width: "32px", height: "32px" }}
                  />
                  <span className="theme-dark-text">{t("requisitionForm.text")}</span>
                </div>
              </div>
            </div>
          )} */}

          {false && (
            <React.Fragment>
              <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />
              <FormGroup className="mb-3">
                <Label className="bold-blue">{t("insurance.providerNameLabel")}</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    value={insuranceProvider}
                    placeholder={t("insurance.providerNamePlaceholder")}
                    type="text"
                    onChange={(event) => {
                      setInsuranceProvider(event.target.value);
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="bold-blue">{t("insurance.policyNumberLabel")}</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder={t("insurance.policyNumberPlaceholder")}
                    type="text"
                    value={insuranceNumber}
                    onChange={(event) => {
                      setInsuranceNumber(event.target.value);
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </React.Fragment>
          )}

          <React.Fragment>
            <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />
            <FormGroup style={{ position: "relative" }}>
              <Label className="bold-blue">{t("editPerson.password")}</Label>
              <InputGroup>
                <Input type="password" onChange={() => {}} value="************" />
              </InputGroup>
              <Button
                style={{
                  margin: 0,
                  position: "absolute",
                  bottom: 12,
                  right: 12,
                }}
                color="link"
                className="bold theme-dark-text"
                onClick={() => setChangePasswordSidebarOpen(true)}
              >
                {t("editPerson.changeBtn")}
              </Button>
            </FormGroup>
          </React.Fragment>
        </div>

        <EditAddressMap
          address={address || null}
          sidebarGroup="profile-edit"
          callBack={(address) => {
            setAddress(address);
            setLocation({
              latitude: address.latitude,
              longitude: address.longitude,
            });
          }}
          isOpen={locationSideBarOpen}
          toggle={() => {
            setLocationSideBarOpen(false);
          }}
          title={t("personalInfo.addressLabel")}
        />
        <ChangePassword
          sidebarGroup="profile-edit"
          isOpen={changePasswordSidebarOpen}
          toggle={() => {
            setChangePasswordSidebarOpen(false);
          }}
          title={t("changePassword.title")}
        />
      </SideBar>
      <input
        id="profile-health-card-file-upload"
        type="file"
        accept=".jpeg,.jpg,.png"
        ref={inputFile}
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
    </React.Fragment>
  );
};

export default EditPersonInfoSidebar;
