import React, { useEffect, useState } from "react"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "reactstrap"
import SideBar from "."
import MembersCard from "../MembersCard"
import Loader from "../Loader"
import EditAddFamilyMemberView from "../../components/SideBar/EditAddFamilyMember"
import { parseError } from "../../helpers/common"
import { useTranslation } from "react-i18next"

const ManageFamilyMembersSidebar = (props) => {
  const { t } = useTranslation("common")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showMemberSidebar, setShowMemberSidebar] = useState(false)
  const [members, setMembers] = useState([])
  const [selectedMember, setSelectedMember] = useState(null)
  const [nextPageToken, setNextPageToken] = useState(null)
  const user = useSelector((state) => state.user)
  // GET ​/v1​/CorporateClients​/{id}​/members
  const corporate_or_family = user.hasCorporateProfile ? `CorporateClients/${user?.corporateClientId}/members` : "account/familymembers"

  useEffect(() => {
    if (props.isOpen) {
      if (members.length === 0) {
        getMembers()
      }
    } else {
      resetState()
    }
  }, [props.isOpen])

  const resetState = () => {
    setMembers([])
    setSelectedMember(null)
    setNextPageToken(null)
    setShowMemberSidebar(false)
    setError(false)
    setLoading(false)
  }

  const getMembers = (pageToken = null) => {
    setLoading(true)
    setError(null)

    if (!pageToken) {
      setMembers([])
      setNextPageToken(null)
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/${corporate_or_family}?pageSize=15&paginationToken=${pageToken || ""}`)
      .then((res) => {
        setLoading(false)
        const list = res.data.items
        list.forEach((s) => {
          s.checked = false
        })
        setMembers((prev) => prev.concat(list))
        setNextPageToken(res.data.nextPageToken)
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)
        setError(error)
      })
  }

  const onEditMember = (person) => {
    setSelectedMember(person)
    setShowMemberSidebar(true)
  }

  const onMemberCreated = (person) => {
    setMembers((members) => [...members, person])
  }

  const onMemberUpdated = (person) => {
    setMembers((list) =>
      list.map((p) => {
        if (p.id === person.id) {
          return person
        }
        return p
      })
    )
    setSelectedMember(null)
    setShowMemberSidebar(false)
  }

  const onMemberDeleted = (personId) => {
    setMembers((list) => list.filter((m) => m.id !== personId))
    setSelectedMember(null)
    setShowMemberSidebar(false)
  }

  return (
    <React.Fragment>
      <SideBar title={user.hasCorporateProfile ? t("managePatients.title") : t("manageFamilyMembers.title")} back={true} {...props}>
        <div className="d-flex flex-column">
          <div className="gap-12 d-flex flex-column">
            {members.length === 0 && !loading && !error && (
              <div className="avvy-card">
                <span className="sub-text text-normal">{t("selectMembers.noMembers")}</span>
              </div>
            )}
            {members.map((member) => {
              return <MembersCard key={member.id} member={member} edit={true} onEdit={(person) => onEditMember(person)} />
            })}
          </div>

          {loading && <Loader title={t("common.loading")} />}
          {error && (
            <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
              {error}
            </div>
          )}

          {!error && !loading && nextPageToken && (
            <div className="d-flex items-center mx-auto mt-4">
              <Button
                outline
                color="primary"
                onClick={() => {
                  getMembers(nextPageToken)
                }}>
                {t("memberSelect.loadMore")}
              </Button>
            </div>
          )}

          <hr style={{ margin: "16px 0" }} />

          <Button
            disabled={loading}
            className="w-full btn-accent"
            onClick={() => {
              setSelectedMember(null)
              setShowMemberSidebar(true)
            }}>
            {t("manageFamilyMembers.btn")}
          </Button>
        </div>
      </SideBar>

      <EditAddFamilyMemberView
        sidebarGroup="profile-members"
        mode={selectedMember ? "edit" : "new"}
        person={selectedMember}
        onCreated={(person) => onMemberCreated(person)}
        onUpdated={(person) => onMemberUpdated(person)}
        onDeleted={(personId) => onMemberDeleted(personId)}
        toggle={() => setShowMemberSidebar(false)}
        isOpen={showMemberSidebar}
      />
    </React.Fragment>
  )
}

export default ManageFamilyMembersSidebar
