import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Input, Button } from "reactstrap"
import { connect, useDispatch } from "react-redux"
import SideBar from "."
import CheckoutDetails from "../CheckoutDetails"
import CheckoutPaymentSidebar from "../../components/SideBar/CheckoutPaymentReorder"
import Schedule from "../SideBar/Schedule"
import RatingSideBar from "./Rating"
import Loader from "../Loader"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import Svg from "../Svg"
import axios from "axios"
import { formatFullAddress, parseError } from "../../helpers/common"
import moment from "moment"
import Rating from "react-rating"

const AppDetailsSidebar = (props) => {
  const { t, i18n } = useTranslation("common")
  const [appointmentData, setAppointmentData] = useState(null)
  const [people, setPeople] = useState([])
  const [address, setAddress] = useState(null)
  const [scheduledDate, setScheduledDate] = useState(null)
  const [showRatingSidebar, setShowRatingSidebar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [receiptLoading, setReceiptLoading] = useState(false)
  const [scheduleSideBarOpen, setScheduleSideBarOpen] = useState(false)
  const [appointmentType, setAppointmentType] = useState("scheduled")
  const [error, setError] = useState(null)
  const [paymentSideBarOpen, setpPaymentSidebBarOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (props.isOpen && props.appointmentId) {
      const appId = props.appointmentId
      getAppointment(appId)
    } else {
      setAppointmentData(null)
      setAddress(null)
      setScheduledDate(null)
      setPeople([])
      setShowRatingSidebar(false)
      setError(null)
      setLoading(false)
    }
  }, [props.appointmentId, props.isOpen])

  const getAppointment = (appId) => {
    setLoading(true)
    setError(null)
    setAppointmentData(null)

    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/${appId}`)
      .then((res) => {
        setLoading(false)
        initializeAppointment(res.data)
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)
        setError(error)
      })
  }

  const initializeAppointment = (appointment) => {
    const peopleList = appointment.appointmentPatients
    peopleList.forEach((person) => {
      const services = person.appointmentItems.map((ai) => {
        const service = Object.assign({ key: ai.key }, ai.service)
        if (service.requisitionFormId) service.requisitionFormFile = service.requisitionFormId
        if (service.insuranceFormId) service.insuranceFormFile = service.insuranceFormId
        return service
      })
      person.services = services
    })
    setPeople(peopleList)
    setAddress(
      Object.assign(
        Object.assign({ fullAddress: formatFullAddress(appointment.address), provinceCode: appointment.address.province }, appointment.address),
        appointment.location
      )
    )

    if (appointment.startTime) {
      const appTime = appointment.startTime
      const scheduledDate = moment(`${appTime.day}/${appTime.month}/${appTime.year} ${appTime.hour}:${appTime.minute}:${appTime.second}`, "DD/MM/YYYY HH:mm:ss")
      setScheduledDate(scheduledDate.toDate())
    } else {
      setScheduledDate(null)
    }
    setAppointmentData(appointment)
    dispatch({ type: "reorder/setReorder", payload: appointment })
  }

  const resendReceipt = () => {
    setReceiptLoading(true)

    axios
      .put(`${process.env.REACT_APP_API_URL}/appointments/${appointmentData.id}/resend-receipt`)
      .then((res) => {
        setReceiptLoading(false)
        toast.dark(t("appointmentDetails.receiptConfirmation"), {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
      .catch((err) => {
        setReceiptLoading(false)
        let error = parseError(err)
        toast.error(error, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  // subtract 72 hours from the current datetime for appointment rating
  const diffHours = (startTime) => {
    let newDate = new Date(
      appointmentData.startTime.year,
      appointmentData.startTime.month,
      appointmentData.startTime.day,
      appointmentData.startTime.hour,
      appointmentData.startTime.minute
    )
    newDate.setMonth(newDate.getMonth() - 1)
    let diff = moment().diff(newDate, "hours")
    return diff
  }

  const onDateSelected = (scheduleData) => {
    setScheduledDate(scheduleData.date)
    setAppointmentType(scheduleData.type)
    props.scheduleChanged(scheduleData.date, scheduleData.type)
  }
  return (
    <React.Fragment>
      <SideBar title={t("appointmentDetails.title")} {...props}>
        <div className="d-flex flex-column">
          {loading && <Loader className="mt-5" title={t("common.loading")} />}
          {error && (
            <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
              {error}
            </div>
          )}

          {appointmentData && (
            <div className="d-flex flex-column gap-16">
              <div className="d-flex flex-row gap-12">
                <div className="avvy-card d-flex flex-column gap-12" style={{ width: "100%" }}>
                  <div className="d-flex flex-row gap-12">
                    <Svg name={appointmentData.type === "asap" ? "icon_clock" : "calendar-day-blue"} className="avvy-card-icon" />
                    <span className="avvy-card-header">{appointmentData.type === "asap" ? t("schedule.asap") : t("newBooking.schedule")}</span>
                  </div>

                  {scheduledDate && (
                    <div className=" d-flex flex-row gap-12">
                      <div className="avvy-card-small d-flex flex-column">
                        <span className="theme-dark-text bold">
                          {new Intl.DateTimeFormat(i18n.language, { month: "long" }).format(scheduledDate)} {scheduledDate.getDate()}
                        </span>
                        <span className="sub-text" style={{ fontSize: "14px" }}>
                          {new Intl.DateTimeFormat(i18n.language, { weekday: "long" }).format(scheduledDate)}
                        </span>
                      </div>
                      <div className="avvy-card-small d-flex flex-column">
                        <span className="theme-dark-text bold">
                          {new Intl.DateTimeFormat(i18n.language, {
                            hour: "numeric",
                            minute: "numeric",
                          })
                            .format(scheduledDate)
                            .replace(/^0+/, "")}
                        </span>
                        <span className="sub-text" style={{ fontSize: "14px" }}>
                          {t("appointmentDetails.time")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {address && (
                <div className="avvy-card d-flex flex-column gap-6">
                  <div className="d-flex flex-row gap-12">
                    <Svg className="avvy-card-icon" name="icon_location_outline" />
                    <span className="avvy-card-header">{t("newBooking.address")}</span>
                  </div>
                  <span className="bold theme-dark-text">{formatFullAddress(address)}</span>
                </div>
              )}
              <CheckoutDetails
                appointment={appointmentData}
                discountCode={appointmentData.discountCodeUsed}
                address={address}
                scheduledDate={scheduledDate}
                appointmentType={appointmentData.type}
                people={people}
                costData={appointmentData.cost}
                disabled={true}
                costUpdated={() => {}}
              />
              {appointmentData.technician && (
                <div className="d-flex flex-column gap-12 mt-4">
                  <div className="bold theme-dark-text">{t("appointmentDetails.technician")}</div>
                  <div className="d-flex items-center gap-12">
                    {!appointmentData.technician.picture && <Svg name="profile_empty" style={{ width: "40px", height: "40px" }} />}
                    {appointmentData.technician.picture && (
                      <img
                        src={appointmentData.technician.picture.url}
                        style={{
                          width: "44px",
                          height: "44px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          border: "1px solid (var--border-color)",
                          objectFit: "cover",
                        }}
                        alt="Technician Avatar"
                      />
                    )}

                    <div className="d-flex flex-column">
                      <div className="bold text-normal main-text">
                        {appointmentData.technician.firstName} {appointmentData.technician.lastName}
                      </div>
                      <Rating
                        className="d-flex  text-normal mr-auto"
                        initialRating={appointmentData.technician.rating}
                        emptySymbol="fa fa-star-o theme-dark-text"
                        fullSymbol="fa fa-star theme-dark-text"
                        readonly={true}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex flex-column gap-6">
                <div className="bold theme-dark-text">{t("appointmentDetails.notes")}</div>
                <Input
                  onChange={(e) => {}}
                  type="textarea"
                  value={appointmentData.patientNotes || ""}
                  rows={3}
                  style={{ borderColor: "var(--border-color)", overflowY: "auto" }}
                  className="main-text"
                  readOnly={true}
                />
              </div>

              {(appointmentData.status === "completed" || appointmentData.status === "ended") && (
                <React.Fragment>
                  <Button color="primary" outline className="w-full" onClick={() => resendReceipt()}>
                    {t("appointmentDetails.btnResend")}
                  </Button>
                  {receiptLoading && <Loader className="mt-3" title={t("common.loading")} />}
                </React.Fragment>
              )}
              {(appointmentData.status === "completed" || appointmentData.status === "ended") && (
                <React.Fragment>
                  <Button
                    color="primary"
                    outline
                    className="w-full"
                    onClick={() => {
                      setScheduleSideBarOpen(true)
                    }}>
                    {t("appointmentDetails.btnReorder")}
                  </Button>
                  {receiptLoading && <Loader className="mt-3" title={t("common.loading")} />}
                </React.Fragment>
              )}

              {(appointmentData.status === "completed" || appointmentData.status === "ended") && (
                <div
                  className="zig-zag-container"
                  style={{
                    marginLeft: "-32px",
                    marginRight: "-32px",
                    marginBottom: "-68px",
                    paddingBottom: "68px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    width: "calc(100% + 64px)",
                    background: "var(--theme-hover-color)",
                  }}>
                  {appointmentData.isRated && (
                    <div className="d-flex flex-column items-start gap-16">
                      <div className="theme-dark-text bold">{t("appointmentDetails.ratedService")}</div>
                      <Rating
                        className="d-flex text-normal justify-content-between mr-auto"
                        style={{ width: "50%", minWidth: "200px" }}
                        initialRating={appointmentData.rating}
                        emptySymbol="fa fa-star-o fa-3x theme-dark-text"
                        fullSymbol="fa fa-star fa-3x theme-dark-text"
                        readonly={true}
                      />
                    </div>
                  )}
                  {!appointmentData.isRated && diffHours(appointmentData.startTime) < 72 ? (
                    <div className="d-flex flex-column items-start gap-16 py-2">
                      <Button
                        disabled={receiptLoading}
                        className="w-full btn-accent"
                        onClick={() => {
                          setShowRatingSidebar(true)
                        }}>
                        {t("appointmentDetails.rateService")}
                      </Button>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
        </div>
      </SideBar>

      {appointmentData && (
        <RatingSideBar
          sidebarGroup="app-history"
          isOpen={showRatingSidebar}
          toggle={() => {
            setShowRatingSidebar(false)
          }}
          onRated={(rating) => {
            setAppointmentData((appData) => Object.assign(appData, { rating: rating, isRated: true }))
            props.onRated(appointmentData, rating)
            setShowRatingSidebar(false)
          }}
          appointment={appointmentData}
        />
      )}
      {scheduleSideBarOpen && (
        <Schedule
          sidebarGroup="app-schedule"
          isOpen={scheduleSideBarOpen}
          toggle={() => setScheduleSideBarOpen(false)}
          openNext={() => setpPaymentSidebBarOpen(true)}
          onDateSelected={onDateSelected}
          people={people}
          address={address}
          selectedDate={scheduledDate}
          appointmentType={appointmentType}
          nextStep="reorder"
        />
      )}
      {paymentSideBarOpen && (
        <CheckoutPaymentSidebar
          sidebarGroup="app-payment"
          paymentMode="card"
          // paymentMode={paymentMode}
          onSuccess={(appId) => {
            toast.dark(t(`newBooking.${appointmentType === "asap" ? "asapRequestReceived" : "bookedMessage"}`), {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            history.push("/dashboard")
            setpPaymentSidebBarOpen(false)
          }}
          isReorder={true}
          isOpen={paymentSideBarOpen}
          toggle={() => setpPaymentSidebBarOpen(false)}
          address={props.reorder.address}
          people={props.reorder.people}
          appointmentType={props.reorder.appointmentType}
          technician={props.reorder.technician}
          patient={props.reorder.patient}
          bookedAt={props.reorder.bookedAt}
          location={props.reorder.location}
          startTime={props.reorder.startTime}
          startedAt={props.reorder.startedAt}
          endedAt={props.reorder.endedAt}
          estimatedArrivalMinutes={props.reorder.estimatedArrivalMinutes}
          discountCodeUsed={props.reorder.discountCodeUsed}
          patientNotes={props.reorder.patientNotes}
          appointmentPatients={props.reorder.appointmentPatients}
          type={props.reorder.type}
          latitude={props.reorder.latitude}
          longitude={props.reorder.longitude}
          cost={props.reorder.cost}
          isRated={props.reorder.isRated}
          profile={props.reorder.profile}
          // {...props}
        />
      )}
    </React.Fragment>
  )
}

export default connect((state) => state, null)(AppDetailsSidebar)
