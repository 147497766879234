import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SideBar from ".";
import axios from "axios";
import { Button } from "reactstrap";
import StripeCardElement from "../CardElement";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import { parseError } from "../../helpers/common";
import Svg from "../Svg";
import authProvider from "../../helpers/authProvider";

const CheckoutPaymentSidebar = (props) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    if (props.isOpen) {
      setPaymentMethod(null);
      setError(null);
      setLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}/account/payment-method`)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setPaymentMethod(res.data);
          } else {
            setPaymentMethod(null);
          }
        })
        .catch((err) => {
          let error = parseError(err);
          setError(error);
          setLoading(false);
        });
    } else {
      setError(null);
      setLoading(false);
      setPaymentMethod(null);
      setAppointmentLoading(false);
    }
  }, [props.isOpen]);

  const onAddNewCard = (card) => {
    setPaymentMethod(card);
  };

  const onAppointmentPay = async () => {
    if (
      !props.people ||
      !props.address ||
      !props.appointmentType ||
      !props.cost ||
      // (props.appointmentType === "scheduled" && !props.scheduledDate) ||
      props.people.length === 0
    ) {
      return;
    }

    setError(null);
    setAppointmentLoading(true);

    let appData = {};

    // appData.address = props.address;
    //  appData.people = props.people;
    // appData.type = props.appointmentType;
    // appData.technician = props.technician;
    // appData.patient = props.patient;
    // appData.bookedAt = props.bookedAt;
    // appData.location = props.location;
    // appData.startTime = props.startTime;
    // appData.startedAt = props.startedAt;
    // appData.endedAt = props.endedAt;
    // appData.estimatedArrivalMinutes = props.estimatedArrivalMinutes;
    // appData.discountCodeUsed = props.discountCodeUsed;
    // appData.notes = props.patientNotes;
    // appData.appointmentPatients = props.appointmentPatients;
    // appData.type = props.type;
    // appData.latitude = props.latitude;
    // appData.longitude = props.longitude;
    // appData.cost = props.cost;
    // appData.isRated = props.isRated;
    // appData.profile = props.profile;

    appData = {
      appointmentPatients: props.people,
      address: props.address,
      latitude: props.latitude,
      longitude: props.longitude,
      discountCode: props.discountCode || null,
      notes: props.notes,
      type: props.appointmentType,
      startTime: props.appointmentType === "asap" ? null : props.startTime,
    };
    console.log(appData);
    // try {
    let appRes =
      props.appointment != null
        ? await axios.put(
            `${process.env.REACT_APP_API_URL}/appointments/${props.appointment.id}`,
            appData
          )
        : await axios
            .post(
              `${process.env.REACT_APP_API_URL}/appointments`,
              Object.assign(appData, {
                profile: authProvider.getCurrentProfileType(),
              })
            )
            .catch(function (err) {
              alert(err?.response?.message);
            });

    let appId = appRes?.data?.id;

    if (props.onSuccess) {
      props.onSuccess(appId);
    }
    // } catch (err) {
    //   let error = parseError(err)
    //   setError(error)
    //   setAppointmentLoading(false)
    // }
  };

  return (
    <SideBar
      title={
        props.paymentMode === "nocharge"
          ? t("appointmentDetails.title")
          : t("Payment.title")
      }
      {...props}>
      <div className="d-flex gap-24 flex-column">
        {props.paymentMode === "card" && (
          <React.Fragment>
            {loading && <Loader title={t("common.loading")} />}

            {paymentMethod && (
              <React.Fragment>
                <div className="d-flex items-center avvy-card-small gap-12 py-3">
                  <Svg
                    name="credit_card"
                    className="w-24 h-24 theme-dark-text"
                  />
                  {t("paymentInformation.text")} {paymentMethod.last4}
                </div>

                <Button
                  disabled={
                    paymentMethod == null ||
                    appointmentLoading ||
                    !props.people ||
                    props.people.length === 0 ||
                    !props.address ||
                    !props.appointmentType
                    //  || (props.appointmentType === "scheduled" && !props.scheduledDate)
                  }
                  className="w-100 btn-accent"
                  onClick={() => onAppointmentPay()}>
                  {t("Payment.btn")}
                </Button>

                <hr style={{ margin: 0 }} />
              </React.Fragment>
            )}

            <StripeCardElement
              toggle={() => {}}
              isVisible={true}
              onCardAdded={(card) => onAddNewCard(card)}
            />
          </React.Fragment>
        )}

        {props.paymentMode === "nocharge" && (
          <React.Fragment>
            <div className="main-text text-normal">
              {t("appointmentDetails.appointmentChangesConfirmation")}
            </div>
            <Button
              disabled={
                appointmentLoading ||
                !props.people ||
                props.people.length === 0 ||
                !props.address ||
                !props.appointmentType ||
                (props.appointmentType === "scheduled" && !props.scheduledDate)
              }
              className="w-100 btn-accent"
              onClick={() => onAppointmentPay()}>
              {t("appointmentDetails.editConfirmBtn")}
            </Button>
          </React.Fragment>
        )}

        {appointmentLoading && <Loader title={t("common.loading")} />}

        {error && (
          <div
            className="error-alert"
            style={{
              margin: "12px auto",
              padding: "0.6rem",
              maxWidth: "500px",
            }}>
            {error}
          </div>
        )}
      </div>
    </SideBar>
  );
};

export default connect((state) => state, null)(CheckoutPaymentSidebar);
