import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { Button, FormGroup, Form, Input, Label, InputGroup } from "reactstrap"
import { Link } from "react-router-dom"
import VerifyEmailModal from "../../components/Modals/VerifyEmail"
import Loader from "../../components/Loader"
import Svg from "../../components/Svg"
import { AUTH_FORGOT_PASSWORD_URL, AUTH_SIGN_UP_PERSONAL_INFO_URL } from "../../urls"
import { useTranslation } from "react-i18next"
import { changeLanguage, detectDefaultLanguage, parseError } from "../../helpers/common"
import authProvider from "../../helpers/authProvider"

const LoginPage = (props) => {
  const { t, i18n } = useTranslation("common")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [verifyEmailModalVisible, setVerifyEmailModalVisible] = useState(false)
  const [loginType, setLoginType] = useState("password")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [emailError, setEmailError] = useState(null)
  const [language, setLanguage] = useState(detectDefaultLanguage())
  const [emailVerificationMessage, setEmailVerificationMessage] = useState("")

  useEffect(() => {
    if (props.history.location.search) {
      const preEmail = new URLSearchParams(props.history.location.search).get("email")
      if (preEmail) {
        setEmail(preEmail)
      }
    } else {
      const loginState = sessionStorage.getItem("login_state")
      if (loginState) {
        try {
          const stateJson = Buffer.from(loginState, "base64").toString("utf8")
          const stateData = JSON.parse(stateJson)
          if (stateData.email) {
            setEmail(stateData.email)
          }

          if (stateData.password) {
            setPassword(stateData.password)
          }
        } catch (err) {
          console.warn(err)
        }

        sessionStorage.removeItem("login_state")
      }
    }
    setLanguage(detectDefaultLanguage())
  }, [props.history])

  const onLogin = () => {
    if (!email || !password) {
      return
    }

    setError(null)
    setLoading(true)

    authProvider
      .login(email, password)
      .then((res) => {
        const userLanguage = res.data.language
        const currentLang = detectDefaultLanguage()

        if (currentLang !== userLanguage) {
          localStorage.setItem("language", userLanguage)
        }

        window.location.href = "/"
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)

        // Check if email needs to be verified prior to login
        if (err.response && err.response.status === 426) {
          setVerifyEmailModalVisible(true)
          setEmailVerificationMessage(error)
        } else {
          setError(error)
        }
      })
  }

  const checkValidEmail = (value) => {
    if (!value || value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) == null) {
      setEmailError(<small className="text-danger">{t("forgotPassword.error")}</small>)
    } else {
      setEmailError(null)
    }
  }

  const onChangeLanguage = (lang) => {
    if (email || password) {
      try {
        const loginState = { email: email, password: password }
        const stateJson = JSON.stringify(loginState)
        const stateData = Buffer.from(stateJson, "utf8").toString("base64")
        sessionStorage.setItem("login_state", stateData)
      } catch (err) {
        console.warn(err)
      }
    }

    setLanguage(lang)
    changeLanguage(i18n, t, lang)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Noosu - {t("login.title")}</title>
      </Helmet>
      <div className="d-flex flex-column items-center" style={{ width: "90%", maxWidth: "420px" }}>
        <h1 className="position-relative stylized-header text-center" style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: t("landingPage.banner") }}></h1>
        <p className="m-0 mt-3 theme-dark-text text-normal text-sm text-center" style={{ fontSize: "18px", fontWeight: 400 }}>
          {t("welcome.subText")}
        </p>

        <Form role="form" className="mt-4" style={{ width: "100%" }}>
          <FormGroup>
            <Label className="theme-dark-text bold">{t("login.emailLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder={t("login.emailPlaceholder")}
                type="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
                onBlur={(event) => {
                  checkValidEmail(event.target.value)
                }}
              />
            </InputGroup>
            {emailError}
          </FormGroup>

          <FormGroup className="mt-4">
            <Label className="theme-dark-text bold">{t("login.password")}</Label>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder={t("login.passwordPlaceholder")}
                type={loginType}
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value)
                }}
              />
              {loginType === "input" ? (
                <Svg className="password-eye-icon" style={{ top: 10 }} name="eye-off" onClick={() => setLoginType("password")} />
              ) : (
                <Svg className="password-eye-icon" style={{ top: 10 }} name="eye-on" onClick={() => setLoginType("input")} />
              )}
            </InputGroup>
          </FormGroup>

          <div className="d-flex flex-column gap-6">
            <div className="d-flex items-center flex-shrink-0 mt-2 gap-12">
              <FormGroup className="d-flex flex-shrink-0">
                <Label className="mb-0">
                  <Input className="mr-1" type="radio" value={"en"} checked={language === "en"} onChange={(e) => onChangeLanguage("en")} />
                  {t("languages.english")}
                </Label>
              </FormGroup>
              <FormGroup className="d-flex flex-shrink-0" onChange={() => onChangeLanguage("fr-CA")}>
                <Label className="mb-0">
                  <Input className="mr-1" type="radio" name="fr-CA" value={"fr-CA"} checked={language === "fr-CA"} onChange={() => onChangeLanguage("fr-CA")} />
                  {t("languages.french")}
                </Label>
              </FormGroup>

              <Link to={AUTH_FORGOT_PASSWORD_URL} className="text-right ml-auto theme-dark-text text-underline mb-1">
                <span style={{ fontSize: "14px" }}>{t("login.forgotPassword")}</span>
              </Link>
            </div>

            <Button onClick={() => onLogin()} className="btn-accent mt-4" block type="button" disabled={!email || emailError !== null || !password || loading}>
              {t("login.button")}
            </Button>

            {error && (
              <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
                {error}
              </div>
            )}

            <div className="text-center mt-3" style={{ fontSize: "16px" }}>
              <span className="theme-dark-text">{t("login.bottomLine")}</span>
              <Link to={AUTH_SIGN_UP_PERSONAL_INFO_URL} className="ml-2 theme-dark-text text-underline">
                {t("login.signup")}
              </Link>
            </div>

            {loading && <Loader title={t("login.logging_in")} />}
          </div>
        </Form>

        <VerifyEmailModal
          message={emailVerificationMessage}
          email={email}
          onConfirmed={() => onLogin()}
          toggle={() => {
            setVerifyEmailModalVisible(false)
          }}
          isOpen={verifyEmailModalVisible}
        />
      </div>
    </React.Fragment>
  )
}

export default LoginPage
