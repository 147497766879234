import React, { useEffect, useState } from "react"
import { connect, useSelector, useDispatch } from "react-redux"
import { Button } from "reactstrap"
import axios from "axios"
import SideBar from "."
import { useTranslation } from "react-i18next"
import moment from "moment"
import "moment/locale/fr"
import Loader from "../Loader"
import ReactDateTime from "react-datetime"
import { parseError } from "../../helpers/common"
import Checkbox from "../Checkbox"
import useAbortController from "../../helpers/useAbortController"

const ScheduleSidebar = (props) => {
  const getAbortSignal = useAbortController()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [initialized, setInitialized] = useState(false)
  const [isAsap, setIsAsap] = useState(props.appointmentType === "asap")
  const [currentMonth, setCurrentMonth] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [availabilityDates, setAvailabilityDates] = useState([])
  const { i18n, t } = useTranslation("common")
  const dispatch = useDispatch()

  useEffect(() => {
    if (!props.isOpen) {
      setSelectedTime(null)
      setSelectedDate(null)
      setCurrentMonth(new Date())
      setInitialized(false)
      setAvailabilityDates([])
    } else {
      setCurrentMonth(moment().toDate())
    }
  }, [props.isOpen])

  useEffect(() => {
    setCurrentMonth(props.selectedDate || null)
    setSelectedDate(props.selectedDate || null)
  }, [props.selectedDate])

  useEffect(() => {
    setSelectedTime(null)
    setSelectedDate(null)
    getAvailabilities(props.appointmentType === "asap")
  }, [currentMonth])

  const getAvailabilities = (asap) => {
    if (!props.isOpen || asap) {
      return
    }

    setLoading(true)
    setError(null)
    setIsAsap(asap)
    let address = null
    let people = null

    var monthStart = (currentMonth ? moment(currentMonth) : moment()).startOf("month").toDate()
    var monthEnd = (currentMonth ? moment(currentMonth) : moment()).endOf("month").toDate()

    if (props.nextStep == "reorder") {
      address = props.reorder.address
      people = props.reorder.people
    } else {
      address = props.address
      people = props.people
    }
    const allWithServices =
      people &&
      people.every((person) => {
        return person.services && person.services.length > 0
      })

    if (!address || !allWithServices || asap) {
      setLoading(false)
      return
    }

    const allServices = people.flatMap((s) => s.services)
    const serviceIds = allServices
      .map((s) => s.id)
      .filter((value, index, self) => {
        return self.indexOf(value) === index
      })

    const requestedServices = serviceIds.map((sId) => {
      return {
        serviceId: sId,
        count: allServices.filter((s) => s.id === sId).length,
      }
    })

    let availabilityData = {
      latitude: address.latitude,
      longitude: address.longitude,
      startDate: {
        year: monthStart.getFullYear(),
        month: monthStart.getMonth() + 1,
        day: monthStart.getDate(),
      },
      endDate: {
        year: monthEnd.getFullYear(),
        month: monthEnd.getMonth() + 1,
        day: monthEnd.getDate(),
      },
      requestedServices: requestedServices,
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/appointments/availabilities-range`, availabilityData, { signal: getAbortSignal() })
      .then((res) => {
        setLoading(false)

        if (res.data && res.data.length > 0) {
          setAvailabilityDates(
            res.data.map((ad) => {
              let aDate = moment(`${ad.date.year}-${ad.date.month}-${ad.date.day}`, "YYYY-MM-DD").toDate()
              return {
                date: aDate,
                availableTimes: ad.froms.map((cTime) => {
                  let parsedTime = moment(`${ad.date.year}-${ad.date.month}-${ad.date.day} ${cTime.hour}:${cTime.minute}`, "YYYY-MM-DD H:m")
                  return {
                    id: parsedTime.format("YYYY-MM-DD H:m"),
                    time: parsedTime.toDate(),
                  }
                }),
              }
            })
          )

          if (props.selectedDate && !initialized) {
            setSelectedDate(props.selectedDate)
            setSelectedTime({
              id: moment(props.selectedDate).format("YYYY-MM-DD H:m"),
              time: props.selectedDate,
            })
            setInitialized(true)
          }
        }
      })
      .catch((err) => {
        // setLoading(false);
        if (!axios.isCancel(err)) {
          let error = parseError(err)
          setError(error)
        }
      })
  }

  const onDateChanged = (date) => {
    setSelectedDate(date)
    setSelectedTime(null)
  }

  const onSelectTime = (time) => {
    setSelectedTime(time)
  }

  const changeMode = (asap) => {
    if (!asap) {
      getAvailabilities(asap)
    } else {
      setSelectedTime(null)
    }
  }

  const onSaveDate = () => {
    let scheduleData = {
      date: isAsap ? null : selectedTime.time,
      type: isAsap ? "asap" : "scheduled",
    }

    if (props.nextStep == "reorder") {
      console.log("test")
      console.log("test")
      console.log("test")
      dispatch({ type: "reorder/time", payload: scheduleData })
      props.openNext()
    } else {
      //new booking
      props.onDateSelected(scheduleData)
    }

    props.toggle()
  }

  const Footer = () => {
    return (
      <div className="d-flex items-center">
        <Button size="sm" className="px-5 py-2 ml-auto btn-accent" disabled={isAsap ? false : !selectedDate || !selectedTime} onClick={() => onSaveDate()}>
          {t("newBooking.btnSave")}
        </Button>
      </div>
    )
  }

  const disableInvalidDt = (current, selected) => {
    return (
      !loading &&
      moment(current).isSameOrAfter(moment(), "day") &&
      availabilityDates.some((ad) => moment(ad.date).isSame(moment(current), "date") && ad.availableTimes.length > 0)
    )
  }

  return (
    <SideBar title={t("schedule.selectDate")} {...props} footer={<Footer />}>
      <div className="d-flex flex-column gap-12">
        <div className="d-flex items-start gap-12 position-relative">
          <div className="flex flex-column gap-6">
            <div className="bold theme-dark-text">{t("schedule.asap")}</div>
            <span className="main-text" style={{ fontSize: "13px" }}>
              {t("schedule.note")}
            </span>
          </div>

          <Checkbox
            className="theme-dark-text bold ml-auto large"
            id="asap-checkbox"
            key={isAsap}
            checked={isAsap}
            handleChange={(id, checked) => {
              setIsAsap((prev) => !prev)
              changeMode(!isAsap)
            }}
          />
        </div>

        <hr style={{ margin: 0 }} />

        {!isAsap && (
          <div className="d-flex flex-column">
            <div className="d-flex flex-column gap-2 mt-2">
              <div className="flex flex-col schedule-calendar">
                <ReactDateTime
                  minDate={moment().toDate()}
                  initialViewMode="days"
                  input={false}
                  locale={i18n.language}
                  onChange={(e) => {
                    if (e && typeof e.toDate === "function") {
                      onDateChanged(e.toDate())
                    }
                  }}
                  value={selectedDate}
                  timeFormat={false}
                  isValidDate={disableInvalidDt}
                  onNavigateBack={(amount, viewMode) => {
                    setCurrentMonth(
                      moment(currentMonth ? currentMonth : new Date())
                        .startOf("month")
                        .subtract(amount, "month")
                        .toDate()
                    )
                  }}
                  onNavigateForward={(amount, viewMode) => {
                    setCurrentMonth(
                      moment(currentMonth ? currentMonth : new Date())
                        .startOf("month")
                        .add(amount, "month")
                        .toDate()
                    )
                  }}
                  onBeforeNavigate={(nextView, currentView, viewDate) => {
                    return false
                  }}
                />
              </div>

              {selectedDate && availabilityDates.some((s) => moment(s.date).isSame(moment(selectedDate), "day")) && (
                <React.Fragment>
                  <h4
                    className="text-normal text-center"
                    style={{
                      fontSize: "16px",
                      paddingBottom: "12px",
                      borderBottom: "2px solid var(--theme-dark-color)",
                    }}>
                    {t("newBooking.availableTimes")}
                  </h4>

                  {availabilityDates.some((s) => moment(s.date).isSame(moment(selectedDate), "day")) &&
                    availabilityDates.find((s) => moment(s.date).isSame(moment(selectedDate), "day")).availableTimes.length === 0 && (
                      <div
                        className="sub-text"
                        style={{
                          fontSize: "14px",
                          alignSelf: "center",
                          marginTop: "24px",
                          textAlign: "center",
                        }}>
                        {t("newBooking.noTimeSlots")}
                        <br />
                        {t("newBooking.noTimeSlotsDifferentDate")}
                      </div>
                    )}

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "6px",
                    }}>
                    {availabilityDates
                      .find((s) => moment(s.date).isSame(moment(selectedDate), "day"))
                      .availableTimes.map((slotTime) => {
                        return (
                          <div
                            key={slotTime.id}
                            className={`schedule-time ${selectedTime && selectedTime.id === slotTime.id ? "selected" : ""}`}
                            onClick={() => onSelectTime(slotTime)}>
                            <span>
                              {new Intl.DateTimeFormat(i18n.language, {
                                hour: "numeric",
                                minute: "numeric",
                              })
                                .format(slotTime.time)
                                .replace(/^0+/, "")}
                            </span>
                          </div>
                        )
                      })}
                  </div>
                </React.Fragment>
              )}
            </div>

            {loading && <Loader title={t("newBooking.loadingTimeSlots")} />}

            {error && (
              <div
                className="error-alert"
                style={{
                  margin: "12px auto",
                  padding: "0.6rem",
                  maxWidth: "500px",
                }}>
                {error}
              </div>
            )}
          </div>
        )}
      </div>
    </SideBar>
  )
}

export default connect((state) => state, null)(ScheduleSidebar)
