import React, { useEffect, useState, useRef } from "react"
import SideBar from "."
import "moment/locale/fr"
import ReactDatetime from "react-datetime"
import moment from "moment"
import axios from "axios"
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input"
import "react-phone-number-input/style.css"
import { Button, FormGroup, InputGroup, Input, DropdownToggle, DropdownItem, Dropdown, InputGroupAddon, InputGroupText, DropdownMenu, Label } from "reactstrap"
import { useTranslation } from "react-i18next"
import Svg from "../Svg"
import authProvider from "../../helpers/authProvider"
import { dateFormat } from "../../helpers/common"

const EditAddPatient = (props) => {
  const inputFile = useRef(null)
  const { t, i18n } = useTranslation("common")
  const [mode, setMode] = useState("new")

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [allergies, setAllergies] = useState("")
  const [date, setDate] = useState(null)
  const [phone, setPhone] = useState("")
  const [gender, setGender] = useState(null)
  const [healthCareCardProvince, setHealthCareCardProvince] = useState(null)
  const [healthCareCardNumber, setHealthCareCardNumber] = useState("")
  const [healthCareCardExpiry, setHealthCareCardExpiry] = useState(null)
  const [insuranceNumber, setInsuranceNumber] = useState("")
  const [insuranceProvider, setInsuranceProvider] = useState("")

  const [cardNumberError, setCardNumberError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [firstNameError, setFirstNameError] = useState(null)
  const [lastNameError, setLastNameError] = useState(null)
  const [DOBError, setDOBError] = useState(null)
  //const [genderError, setGenderError] = useState(null);
  const [phoneError, setPhoneError] = useState(null)
  const [provinceError, setProvinceError] = useState(null)

  //const [genders, setGenders] = useState([]);
  //const [gendersOpen, setGendersOpen] = useState(false);
  const [provinces, setProvinces] = useState([])
  const [provincesOpen, setProvincesOpen] = useState(false)

  const [existingHealthCardFile, setExistingHealthCardFile] = useState(null)
  const [uploadedHealthCardFile, setUploadedHealthCardFile] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)

  useEffect(() => {
    if (props.isOpen) {
      // if (genders.length === 0) {
      //   getGenders();
      // }
      if (provinces.length === 0) {
        getProvinces()
      }
      if (props.person) {
        setMode("edit")
        initializePersonDetails(props.person)
      } else {
        resetState()
      }
    } else {
      resetState()
    }
  }, [props.isOpen, props.person])

  useEffect(() => {
    setMode(props.mode)
  }, [props.mode])

  useEffect(() => {
    if (!healthCareCardProvince) {
      setProvinceError(<small className="text-danger">{t("addFamilyMember.provinceError")}</small>)
    } else {
      setProvinceError(null)
    }
  }, [healthCareCardProvince])

  // useEffect(() => {
  //   if (!gender) {
  //     setGenderError(<small className="text-danger">{t("addFamilyMember.genderError")}</small>);
  //   } else {
  //     setGenderError(null);
  //   }
  // }, [gender]);

  // const getGenders = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/account/genders`)
  //     .then((res) => {
  //       setGenders(res.data);
  //       if (!gender && props.person && props.person.gender) {
  //         setGender(res.data.find((g) => g.key === props.person.gender.key));
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setGenders([]);
  //     });
  // };

  const getProvinces = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/platform/provinces`)
      .then((res) => {
        setProvinces(res.data)
        if (!healthCareCardProvince && props.person && props.person.healthCareCard) {
          setHealthCareCardProvince(res.data.find((g) => g.key === props.person.healthCareCard.province))
        }
      })
      .catch((err) => {
        console.error(err)
        setProvinces([])
      })
  }

  const initializePersonDetails = (personData) => {
    setFirstName(personData.firstName)
    setLastName(personData.lastName)
    setEmail(personData.email)
    setPhone(personData.phone)
    setGender(personData.gender || null)
    setAllergies(personData.issuesAllergies || "")
    if (personData.dateOfBirth) {
      let dob = moment(`${personData.dateOfBirth.day}/${personData.dateOfBirth.month}/${personData.dateOfBirth.year}`, "DD/MM/YYYY").toDate()
      setDate(dob)
    }

    // if (personData.healthCareCard) {
    //   setHealthCareCardNumber(personData.healthCareCard.cardNumber)

    //   if (personData.healthCareCard.expiryDate) {
    //     let hcExpiryDate = moment(
    //       `${personData.healthCareCard.expiryDate.day}/${personData.healthCareCard.expiryDate.month}/${personData.healthCareCard.expiryDate.year}`,
    //       "DD/MM/YYYY"
    //     )
    //     setHealthCareCardExpiry(hcExpiryDate.isValid() ? hcExpiryDate.toDate() : null)
    //   }

    //   setHealthCareCardProvince(
    //     (provinces || []).find((province) => province.key === personData.healthCareCard.province) || {
    //       key: "QC",
    //     }
    //   )

    //   if (personData.healthCareCard.uploadedFile) {
    //     setUploadedHealthCardFile(personData.healthCareCard.uploadedFile)
    //     try {
    //       setPreviewImage(URL.createObjectURL(personData.healthCareCard.uploadedFile))
    //     } catch (err) {
    //       console.error(err)
    //     }
    //   } else if (personData.healthCareCard.picture && personData.healthCareCard.picture.fileName) {
    //     setExistingHealthCardFile(personData.healthCareCard.picture.fileName)
    //     getHealthCardImagePreview()
    //   }
    // } else {
    //   setProvinceError(<small className="text-danger">{t("healthCare.provinceError")}</small>)
    //   setCardNumberError(<small className="text-danger">{t("healthCare.healthCardNumberError")}</small>)
    // }

    // if (personData.insurance) {
    //   setInsuranceNumber(personData.insurance.policyNumber || "")
    //   setInsuranceProvider(personData.insurance.provider || "")
    // }
  }

  const preparePersonObject = () => {
    let person = Object.assign({}, props.person || {})

    person.firstName = firstName
    person.lastName = lastName
    person.phone = phone
    person.email = email
    person.gender = gender
    person.dateOfBirth = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    }
    // person.healthCareCard = {
    //   cardNumber: healthCareCardNumber,
    //   expiryDate:
    //     healthCareCardExpiry != null
    //       ? {
    //           year: healthCareCardExpiry.getFullYear(),
    //           month: healthCareCardExpiry.getMonth() + 1,
    //           day: healthCareCardExpiry.getDate(),
    //         }
    //       : null,
    //   province: healthCareCardProvince.key,
    //   picture: {
    //     fileName: existingHealthCardFile || null,
    //   },
    //   uploadedFile: uploadedHealthCardFile || null,
    // }
    // person.insurance = {
    //   policyNumber: insuranceNumber || null,
    //   provider: insuranceProvider || null,
    // }
    person.issuesAllergies = allergies

    return person
  }

  const onAddPerson = () => {
    let person = preparePersonObject()
    props.onCreated(person)
    props.toggle()
    resetState()
  }

  const onPersonUpdate = () => {
    let person = preparePersonObject()
    props.onUpdated(person)
    props.toggle()
    resetState()
  }

  const onPersonDelete = () => {
    props.onDeleted(props.person.id)
    props.toggle()
    resetState()
  }

  const resetState = () => {
    setCardNumberError(null)
    setEmailError(null)
    setFirstNameError(null)
    setLastNameError(null)
    setDOBError(null)
    //setGenderError(null);
    setPhoneError(null)
    setProvinceError(null)

    setFirstName("")
    setLastName("")
    setEmail("")
    setDate(null)
    setPhone("")
    setAllergies("")
    setGender(null)
    setHealthCareCardProvince(null)
    setHealthCareCardNumber("")
    setHealthCareCardExpiry(null)
    setInsuranceNumber("")
    setInsuranceProvider("")
    setAllergies("")
    setMode("new")

    removeImage()
  }

  const handleNameValidation = (e, type) => {
    if (type !== "email") {
      if (type === "firstName") {
        setFirstName(e.target.value)
        setFirstNameError(e.target.value ? null : <small className="text-danger">{t("personalInfo.firstNameError")}</small>)
      } else if (type === "lastName") {
        setLastName(e.target.value)
        setFirstNameError(e.target.value ? null : <small className="text-danger">{t("personalInfo.lastNameError")}</small>)
      }
    } else {
      setEmail(e.target.value)
      if (e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) == null) {
        setEmailError(<small className="text-danger">{t("addFamilyMember.emailError")}</small>)
      } else {
        setEmailError(null)
      }
    }
  }

  const disableFutureDt = (current) => {
    return moment(current).isBefore(moment(), "day")
  }

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      setUploadedHealthCardFile(file)

      try {
        setPreviewImage(URL.createObjectURL(file))
      } catch (err) {
        console.error(err)
      }
    }
  }

  const removeImage = () => {
    if (previewImage) {
      try {
        URL.revokeObjectURL(previewImage)
      } catch {}
    }

    setPreviewImage(null)
    setExistingHealthCardFile(null)
    setUploadedHealthCardFile(null)

    if (inputFile && inputFile.current && inputFile.current.value) {
      try {
        inputFile.current.value = ""
      } catch (err) {}
    }
  }

  const getHealthCardImagePreview = () => {
    let hcUrl = ""

    if (props.person.id === authProvider.getUser().id) {
      hcUrl = `${process.env.REACT_APP_API_URL}/account/health-card`
    } else if (props.person.isFamilyMember) {
      hcUrl = `${process.env.REACT_APP_API_URL}/account/familymembers/${props.person.id}/health-card`
    } else if (props.appointment && props.person.healthCareCard && props.person.healthCareCard.picture && props.person.healthCareCard.picture.fileName) {
      hcUrl = `${process.env.REACT_APP_API_URL}/appointments/${props.appointment.id}/health-card/${props.person.healthCareCard.picture.fileName}`
    }

    if (!hcUrl) {
      return
    }

    axios
      .get(hcUrl, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        try {
          const base64 = btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ""))
          setPreviewImage("data:;base64," + base64)
        } catch (err) {
          console.error(err)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <React.Fragment>
      <SideBar
        title={mode === "new" ? t("newBooking.addPersonBtn") : t("editPerson.title")}
        {...props}
        footer={
          <div className="d-flex items-center">
            {mode === "edit" && (
              <Button size="sm" className="px-5 py-2 mr-auto" color="danger" onClick={() => onPersonDelete()}>
                {t("editPerson.btnDelete")}
              </Button>
            )}

            <Button
              size="sm"
              className="px-5 py-2 ml-auto btn-accent"
              disabled={
                !firstName ||
                firstNameError !== null ||
                !lastName ||
                lastNameError !== null ||
                !date ||
                !phone ||
                phoneError !== null ||
                !email ||
                emailError !== null
              }
              onClick={() => {
                if (mode === "new") {
                  onAddPerson()
                } else {
                  onPersonUpdate()
                }
              }}>
              {t("addFamilyMember.saveButton")}
            </Button>
          </div>
        }>
        <div className="d-flex flex-column gap-12">
          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.firstNameLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                value={firstName}
                maxLength={50}
                onChange={(e) => handleNameValidation(e, "firstName")}
                placeholder={t("personalInfo.firstNamePlaceholder")}
                type="text"
              />
            </InputGroup>
            {firstNameError}
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.lastNameLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                value={lastName}
                maxLength={50}
                onChange={(e) => handleNameValidation(e, "lastName")}
                placeholder={t("personalInfo.lastNamePlaceholder")}
                type="text"
              />
            </InputGroup>
            {lastNameError}
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.emailLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                value={email}
                onChange={(e) => handleNameValidation(e, "email")}
                placeholder={t("personalInfo.emailPlaceholder")}
                type="email"
                onBlur={(event) => {
                  event.target.value === ""
                    ? setEmailError(<small className="text-danger">{t("addFamilyMember.emailError")}</small>)
                    : handleNameValidation(event, "email")
                }}
              />
            </InputGroup>
            {emailError}
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.phoneLabel")}</Label>
            <div className="form-group phone-input">
              <PhoneInput
                maxLength="16"
                defaultCountry="CA"
                extension="true"
                placeholder={t("addFamilyMember.phonePlaceholder")}
                value={phone}
                onChange={(value) => {
                  setPhone(value)
                  if (value && !isPossiblePhoneNumber(value)) {
                    setPhoneError(<small className="text-danger px-4">{t("addFamilyMember.phoneError")}</small>)
                  } else {
                    setPhoneError(null)
                  }
                }}
                required
              />
              {phoneError}
            </div>
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.dateOfBirthLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  className: "dob-readonly-input form-control",
                  placeholder: t("personalInfo.dateOfBirthLabel"),
                  readOnly: true,
                }}
                locale={i18n.language}
                dateFormat={dateFormat}
                onBlur={(event) => {
                  event === "" && setDOBError(<small className="text-danger">{t("personalInfo.dateOfBirthError")}</small>)
                }}
                onChange={(e) => {
                  if (e && typeof e.toDate === "function") {
                    setDate(e.toDate())
                    setDOBError(null)
                  } else {
                    setDOBError(<small className="text-danger">{t("addFamilyMember.dateOfBirthInvalid")}</small>)
                  }
                }}
                value={date}
                timeFormat={false}
                closeOnSelect={true}
                isValidDate={disableFutureDt}
              />
            </InputGroup>
            {DOBError}
          </FormGroup>

          {/*
          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.genderLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Dropdown
                direction="down"
                style={{ width: "100%", height: "44px" }}
                isOpen={gendersOpen}
                toggle={() => setGendersOpen((prev) => !prev)}
              >
                <Svg
                  className={gendersOpen ? "flip-y w-24 h-24" : "w-24 h-24"}
                  style={{ position: "absolute", right: 10, top: 10, pointerEvents: "none" }}
                  name="icon_down"
                />
                <DropdownToggle
                  tag="div"
                  data-toggle="dropdown"
                  style={{ cursor: "pointer", padding: "9.5px 12px", width: "100%" }}
                  aria-expanded={gendersOpen}
                >
                  {gender
                    ? genders.find((g) => g.key === gender.key)
                      ? genders.find((g) => g.key === gender.key).value
                      : t("addFamilyMember.genderPlaceholder")
                    : t("addFamilyMember.genderPlaceholder")}
                </DropdownToggle>

                <DropdownMenu style={{ width: "100%", marginTop: 10, padding: "10px" }}>
                  {genders &&
                    genders.map((item) => {
                      return (
                        <DropdownItem
                          key={item.key}
                          onClick={() => {
                            setGender(item);
                            setGenderError(null);
                          }}
                        >
                          {item.value}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </InputGroup>
            {genderError}
          </FormGroup>
          */}
          <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />

          <FormGroup>
            <Label className="bold-blue">{t("addFamilyMember.allergies")}</Label>
            <InputGroup>
              <Input
                style={{ height: 100 }}
                type="textarea"
                value={allergies}
                onChange={(event) => {
                  setAllergies(event.target.value)
                }}
              />
            </InputGroup>
          </FormGroup>

          <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />

          {/* <FormGroup>
            <Label className="bold-blue">{t("healthCare.provinceLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Dropdown isOpen={provincesOpen} toggle={() => setProvincesOpen((prev) => !prev)} direction="down" style={{ width: "100%", height: "44px" }}>
                <Svg
                  className={provincesOpen ? "flip-y w-24 h-24" : "w-24 h-24"}
                  style={{ position: "absolute", right: 10, top: 10, pointerEvents: "none" }}
                  name="icon_down"
                />
                <DropdownToggle
                  tag="div"
                  data-toggle="dropdown"
                  style={{ cursor: "pointer", padding: "9.5px 12px", width: "100%" }}
                  aria-expanded={provincesOpen}>
                  {healthCareCardProvince ? healthCareCardProvince.value : t("healthCare.provincePlaceholder")}
                </DropdownToggle>
                <DropdownMenu style={{ width: "100%", marginTop: 10, padding: "10px" }}>
                  {provinces &&
                    provinces.map((item) => {
                      return (
                        <DropdownItem
                          key={item.key}
                          onClick={() => {
                            setHealthCareCardProvince(item)
                          }}>
                          {item.value}
                        </DropdownItem>
                      )
                    })}
                </DropdownMenu>
              </Dropdown>
            </InputGroup>
            {provinceError}
          </FormGroup>

          <div className="d-flex items-start flex-row gap-16">
            <FormGroup style={{ width: "50%" }}>
              <Label className="bold-blue">{t("healthCare.healthCardNumberLabel")}</Label>
              <InputGroup className="input-group-alternative">
                <Input
                  value={healthCareCardNumber}
                  onChange={(event) => {
                    setHealthCareCardNumber(event.target.value)
                  }}
                  onBlur={(event) => {
                    event.target.value === ""
                      ? setCardNumberError(<small className="text-danger">{t("healthCare.healthCardNumberError")}</small>)
                      : setCardNumberError(null)
                  }}
                  placeholder={t("healthCare.healthCardNumberLabel")}
                  type="text"
                />
              </InputGroup>
              {cardNumberError}
            </FormGroup>

            <FormGroup style={{ width: "50%" }}>
              <Label className="bold-blue">{t("healthCare.expiryDateLabel")}</Label>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  locale={i18n.language}
                  dateFormat={dateFormat}
                  className="right-picker"
                  onChange={(e) => {
                    if (e && typeof e.toDate === "function") {
                      setHealthCareCardExpiry(e.toDate())
                    } else {
                      setHealthCareCardExpiry(null)
                    }
                  }}
                  inputProps={{
                    placeholder: t("healthCare.expiryDatePlaceholder"),
                    className: "dob-readonly-input form-control",
                  }}
                  value={healthCareCardExpiry}
                  timeFormat={false}
                  closeOnSelect={true}
                />
              </InputGroup>
              <p className="sub-text text-small" style={{ marginTop: "6px", marginBottom: 0, fontWeight: 400 }}>
                {t("personalInfo.healthCardExpiryNote")}
              </p>
            </FormGroup>
          </div>

          {uploadedHealthCardFile || existingHealthCardFile ? (
            <div
              style={{
                overflow: "hidden",
                position: "relative",
                minHeight: "160px",
                border: "1px solid var(--border-color)",
                borderRadius: "6px",
                background: "#f8f8f8",
              }}
              className="upload d-flex flex-column">
              <Button
                onClick={() => removeImage()}
                color="link"
                style={{
                  margin: 0,
                  padding: 0,
                  color: "var(--error-color)",
                  background: "white",
                  position: "absolute",
                  right: "6px",
                  top: "6px",
                }}>
                <Svg name="icon_cancel" style={{ width: "24px", height: "24px" }} />
              </Button>

              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "360px",
                  objectPosition: "top",
                  objectFit: "contain",
                }}
                alt="Card Preview"
                src={previewImage}
              />
            </div>
          ) : (
            <div className="upload-file">
              <div
                className="upload-file-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  inputFile.current.click()
                }}>
                <div className="d-flex flex-column items-center gap-6">
                  <Svg name="icon_camera" className="theme-dark-text" style={{ width: "32px", height: "32px" }} />
                  <span className="theme-dark-text">{t("requisitionForm.text")}</span>
                </div>
              </div>
            </div>
          )} */}

          {false && (
            <React.Fragment>
              <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />
              <FormGroup className="mb-3">
                <Label className="bold-blue">{t("insurance.providerNameLabel")}</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    value={insuranceProvider}
                    placeholder={t("insurance.providerNamePlaceholder")}
                    type="text"
                    onChange={(event) => {
                      setInsuranceProvider(event.target.value)
                    }}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="bold-blue">{t("insurance.policyNumberPlaceholder")}</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder={t("insurance.policyNumberPlaceholder")}
                    type="text"
                    value={insuranceNumber}
                    onChange={(event) => {
                      setInsuranceNumber(event.target.value)
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </React.Fragment>
          )}
        </div>
      </SideBar>

      <input
        id="family-member-healthcard-upload"
        type="file"
        accept=".jpeg,.jpg,.png"
        ref={inputFile}
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
    </React.Fragment>
  )
}

export default EditAddPatient
