import React, { useEffect, useState, useRef } from "react";
import SideBar from ".";
import { produce } from "immer";
import { useSelector, useDispatch } from "react-redux";
import ReactDatetime from "react-datetime";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import PhoneInput, {
  isPossiblePhoneNumber,
} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import {
  Button,
  FormGroup,
  InputGroup,
  Input,
  DropdownToggle,
  DropdownItem,
  Dropdown,
  InputGroupAddon,
  InputGroupText,
  DropdownMenu,
  Label,
} from "reactstrap";
import ConfirmModal from "../Modals/ConfirmModal";
import { useTranslation } from "react-i18next";
import Svg from "../Svg";
import { dateFormat, parseError } from "../../helpers/common";

const EditAddFamilyMember = (props) => {
  const inputFile = useRef(null);
  const { t, i18n } = useTranslation("common");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState("new");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [allergies, setAllergies] = useState("");
  const [date, setDate] = useState();
  const [phone, setPhone] = useState("");
  const [genders, setGenders] = useState([]);
  const [genderError, setGenderError] = useState();
  const [selectedGender, setGender] = useState("");
  const [healthCareCardProvince, setHealthCareCardProvince] = useState(null);
  const [healthCareCardNumber, setHealthCareCardNumber] = useState("");
  const [healthCareCardExpiry, setHealthCareCardExpiry] = useState(null);
  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [insuranceProvider, setInsuranceProvider] = useState("");

  const [cardNumberError, setCardNumberError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [DOBError, setDOBError] = useState(null);
  //const [genderError, setGenderError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [provinceError, setProvinceError] = useState(null);

  const [confirmModal, setConfirmModal] = useState(false);
  //const [genders, setGenders] = useState([]);
  //const [gendersOpen, setGendersOpen] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [provincesOpen, setProvincesOpen] = useState(false);

  const [existingHealthCardFile, setExistingHealthCardFile] = useState(null);
  const [uploadedHealthCardFile, setUploadedHealthCardFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const user = useSelector((state) => state.user);
  const corporate_or_family = user.hasCorporateProfile
    ? `CorporateClients/${user?.corporateClientId}/members`
    : "account/familymembers";

  useEffect(() => {
    if (props.isOpen) {
      // if (genders.length === 0) {
      //   getGenders();
      // }
      // if (provinces.length === 0) {
      //   getProvinces()
      // }
      getMedicalConditionsAndAlerts();
      if (props.person) {
        console.log(props.person);
        initializePersonDetails(props.person);
      } else {
        resetState();
      }
    } else {
      resetState();
    }
  }, [props.isOpen, props.person]);

  useEffect(() => {
    setMode(props.mode);
  }, [props.mode]);

  useEffect(() => {
    if (!healthCareCardProvince) {
      setProvinceError(
        <small className="text-danger">
          {t("addFamilyMember.provinceError")}
        </small>
      );
    } else {
      setProvinceError(null);
    }
  }, [healthCareCardProvince]);

  console.log(props);

  const [medicalConditions, setMedicalConditions] = useState([]);
  const [selectedMedicalConditions, setSelectedMedicalConditions] = useState(
    []
  );
  const [medicalConditionsOthers, setMedicalConditionsOthers] = useState("");
  const [alerts, setAlerts] = useState([]);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [alertsOthers, setAlertsOthers] = useState("");
  const getMedicalConditionsAndAlerts = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Account/medicalconditions-and-alerts`
      )
      .then((res) => {
        setMedicalConditions(res.data.medicalConditions);
        setAlerts(res.data.alerts);
      })
      .catch((err) => {
        //
      });
  };

  const initializePersonDetails = (personData) => {
    console.log("test");
    console.log(personData);
    console.log("test");
    setFirstName(personData.firstName);
    setLastName(personData.lastName);
    setEmail(personData.email);
    setPhone(personData.phone);
    setGender(personData.gender.key || null);
    setAllergies(personData.issuesAllergies || "");

    if (!user.hasCorporateProfile) {
      let lastMedicalConditions =
        personData.medicalConditions[personData?.medicalConditions?.length - 1];
      if (medicalConditions.includes(lastMedicalConditions)) {
        setSelectedMedicalConditions(personData.medicalConditions);
        setMedicalConditionsOthers("");
      } else {
        let arr = personData.medicalConditions;
        let last = arr.pop();
        setSelectedMedicalConditions(arr);
        setMedicalConditionsOthers(last);
      }

      let lastAlerts = personData.alerts[personData?.alerts?.length - 1];
      if (alerts.includes(lastAlerts)) {
        setSelectedAlerts(personData.alerts);
        setAlertsOthers("");
      } else {
        let arr = personData.alerts;
        let last = arr.pop();
        setSelectedAlerts(arr);
        setAlertsOthers(last);
      }
    }
    if (personData.dateOfBirth) {
      let dob = moment(
        `${personData.dateOfBirth.day}/${personData.dateOfBirth.month}/${personData.dateOfBirth.year}`,
        "DD/MM/YYYY"
      ).toDate();
      setDate(dob);
    }

    if (personData.healthCareCard) {
      setHealthCareCardNumber(personData.healthCareCard.cardNumber);

      if (personData.healthCareCard.expiryDate) {
        let hcExpiryDate = moment(
          `${personData.healthCareCard.expiryDate.day}/${personData.healthCareCard.expiryDate.month}/${personData.healthCareCard.expiryDate.year}`,
          "DD/MM/YYYY"
        );

        setHealthCareCardExpiry(
          hcExpiryDate.isValid() ? hcExpiryDate.toDate() : null
        );
      }

      setHealthCareCardProvince(
        (provinces || []).find(
          (province) => province.key === personData.healthCareCard.province
        ) || {
          key: "QC",
        }
      );

      if (
        personData.healthCareCard.picture &&
        personData.healthCareCard.picture.fileName
      ) {
        setExistingHealthCardFile(personData.healthCareCard.picture.fileName);

        if (personData.id) {
          getHealthCardImagePreview();
        }
      }
    }

    if (personData.insurance) {
      setInsuranceNumber(personData.insurance.policyNumber || "");
      setInsuranceProvider(personData.insurance.provider || "");
    }
  };

  const preparePersonObject = () => {
    let person = Object.assign({}, props.person || {});

    person.firstName = firstName;
    person.lastName = lastName;
    person.phone = phone;
    person.email = email;
    person.alerts = [...selectedAlerts];
    person.alerts.push(alertsOthers);
    person.medicalConditions = [...selectedMedicalConditions];
    person.medicalConditions.push(medicalConditionsOthers);
    person.gender = [
      { key: "female", value: "Female" },
      { key: "male", value: "Male" },
      { key: "transgender-female", value: "Transgender Female" },
      { key: "transgender-male", value: "Transgender Male" },
      { key: "gender-variant", value: "Gender Variant/Non-Conforming" },
      { key: "not-listed", value: "Not Listed" },
      { key: "not_to_say", value: "Prefer Not to Answer" },
    ].filter((item) => item.key == selectedGender)[0];
    person.dateOfBirth = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    person.insurance = {
      policyNumber: insuranceNumber || null,
      provider: insuranceProvider || null,
    };
    person.issuesAllergies = allergies;
    if (person.healthCareCard && person.healthCareCard.picture) {
      person.healthCareCardImageFileId = person.healthCareCard.picture.fileName;
    }
    delete person.healthCareCard;

    return person;
  };

  const onAddPerson = () => {
    let person = preparePersonObject();
    let formData = new FormData();
    formData.append("Data", JSON.stringify(person));
    if (uploadedHealthCardFile) {
      formData.append("HealthCareCardImage", uploadedHealthCardFile);
    }

    setLoading(true);
    setError(null);
    if (user.hasCorporateProfile) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/${corporate_or_family}`, person)
        .then((res) => {
          console.log(user);
          setLoading(false);
          props.onCreated(res.data);
          props.toggle();
          resetState();
        })
        .catch((err) => {
          setLoading(false);
          let error = parseError(err);
          setError(error);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/account/familymembers`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          console.log(user);
          setLoading(false);
          props.onCreated(res.data);
          props.toggle();
          resetState();
        })
        .catch((err) => {
          setLoading(false);
          let error = parseError(err);
          setError(error);
        });
    }
  };

  const onPersonUpdate = () => {
    let person = preparePersonObject();
    let formData = new FormData();
    formData.append("Data", JSON.stringify(person));
    if (uploadedHealthCardFile) {
      formData.append("HealthCareCardImage", uploadedHealthCardFile);
    }

    setLoading(true);
    setError(null);
    if (user.hasCorporateProfile) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/corporateClients/${user.corporateClientId}/members/${props.person.id}`,
          person
        )
        .then((res) => {
          setLoading(false);
          props.onUpdated(res.data);
          props.toggle();
          resetState();
        })
        .catch((err) => {
          setLoading(false);
          let error = parseError(err);
          setError(error);
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/account/familymembers/${person.id}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          setLoading(false);
          props.onUpdated(res.data);
          props.toggle();
          resetState();
        })
        .catch((err) => {
          setLoading(false);
          let error = parseError(err);
          setError(error);
        });
    }
  };

  const onPersonDelete = () => {
    if (props.person.id) {
      setLoading(true);
      setError(null);

      console.log(props.person);
      if (user.hasCorporateProfile) {
        axios
          .delete(
            `${process.env.REACT_APP_API_URL}/corporateClients/${user.corporateClientId}/members/${props.person.id}`
          )
          .then((res) => {
            setLoading(false);
            props.onDeleted(props.person.id);
            props.toggle();
            resetState();
          })
          .catch((err) => {
            setLoading(false);
            let error = parseError(err);
            setError(error);
          });
      } else {
        axios
          .delete(
            `${process.env.REACT_APP_API_URL}/account/familymembers/${props.person.id}`
          )
          .then((res) => {
            setLoading(false);
            props.onDeleted(props.person.id);
            props.toggle();
            resetState();
          })
          .catch((err) => {
            setLoading(false);
            let error = parseError(err);
            setError(error);
          });
      }
    }
  };

  const resetState = () => {
    setCardNumberError(null);
    setEmailError(null);
    setFirstNameError(null);
    setLastNameError(null);
    setDOBError(null);
    //setGenderError(null);
    setPhoneError(null);
    setProvinceError(null);

    setFirstName("");
    setLastName("");
    setEmail("");
    setDate();
    setPhone("");
    setAllergies("");
    setGender(null);
    setHealthCareCardProvince(null);
    setHealthCareCardNumber("");
    setHealthCareCardExpiry(null);
    setInsuranceNumber("");
    setInsuranceProvider("");
    setAllergies("");
    setMode("new");
    setSelectedAlerts([]);
    setSelectedMedicalConditions([]);
    setAlertsOthers("");
    setMedicalConditionsOthers("");

    removeImage();
  };

  const handleNameValidation = (e, type) => {
    if (type !== "email") {
      if (type === "firstName") {
        setFirstName(e.target.value);
        setFirstNameError(
          e.target.value ? null : (
            <small className="text-danger">
              {t("personalInfo.firstNameError")}
            </small>
          )
        );
      } else if (type === "lastName") {
        setLastName(e.target.value);
        setFirstNameError(
          e.target.value ? null : (
            <small className="text-danger">
              {t("personalInfo.lastNameError")}
            </small>
          )
        );
      }
    } else {
      setEmail(e.target.value);
      if (e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) == null) {
        setEmailError(
          <small className="text-danger">{t("personalInfo.emailError")}</small>
        );
      } else {
        setEmailError(null);
      }
    }
  };

  const disableFutureDt = (current) => {
    return moment(current).isBefore(moment(), "day");
  };

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setUploadedHealthCardFile(file);

      try {
        setPreviewImage(URL.createObjectURL(file));
      } catch (err) {
        console.error(err);
      }
    }
  };

  const removeImage = () => {
    if (previewImage) {
      try {
        URL.revokeObjectURL(previewImage);
      } catch {}
    }

    setPreviewImage(null);
    setExistingHealthCardFile(null);
    setUploadedHealthCardFile(null);

    if (inputFile && inputFile.current && inputFile.current.value) {
      try {
        inputFile.current.value = "";
      } catch (err) {}
    }
  };

  const getHealthCardImagePreview = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/account/familymembers/${props.person.id}/health-card`,
        {
          responseType: "arraybuffer",
        }
      )
      .then((res) => {
        try {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setPreviewImage("data:;base64," + base64);
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  console.log(selectedAlerts);
  console.log(selectedMedicalConditions);
  return (
    <React.Fragment>
      <SideBar
        loading={loading}
        error={error}
        onOverlayDismiss={() => setError(null)}
        title={
          mode === "new" ? t("addFamilyMember.title") : t("editPerson.title")
        }
        {...props}
        footer={
          <div className="d-flex items-center">
            {mode === "edit" && (
              <Button
                size="sm"
                className="px-4 py-2 mr-auto"
                color="danger"
                disabled={loading}
                onClick={() => setConfirmModal(true)}>
                {t("editPerson.btnDelete")}
              </Button>
            )}

            <Button
              size="sm"
              className="px-4 py-2 ml-auto btn-accent"
              disabled={
                loading ||
                !firstName ||
                firstNameError !== null ||
                !lastName ||
                lastNameError !== null ||
                !date ||
                !phone ||
                phoneError !== null ||
                !email ||
                emailError !== null
                // || !healthCareCardProvince ||
                // provinceError !== null ||
                // !healthCareCardNumber ||
                // cardNumberError !== null ||
                // (!existingHealthCardFile && !uploadedHealthCardFile)
              }
              onClick={() => {
                if (mode === "new") {
                  onAddPerson();
                } else {
                  onPersonUpdate();
                }
              }}>
              {t("addFamilyMember.saveButton")}
            </Button>
          </div>
        }>
        <div className="d-flex flex-column gap-12">
          <FormGroup>
            <Label className="bold-blue">
              {t("personalInfo.firstNameLabel")}
            </Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                value={firstName}
                maxLength={50}
                onChange={(e) => handleNameValidation(e, "firstName")}
                placeholder={t("personalInfo.firstNamePlaceholder")}
                type="text"
              />
            </InputGroup>
            {firstNameError}
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">
              {t("personalInfo.lastNameLabel")}
            </Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                maxLength={50}
                value={lastName}
                onChange={(e) => handleNameValidation(e, "lastName")}
                placeholder={t("personalInfo.lastNamePlaceholder")}
                type="text"
              />
            </InputGroup>
            {lastNameError}
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">Gender</Label>
            <Input
              className="mb-3"
              type="select"
              name="select"
              id="exampleSelect"
              onBlur={(event) => {
                event.target.value === ""
                  ? setGenderError(
                      <small className="text-danger">Gender is required.</small>
                    )
                  : setGenderError(null);
              }}
              onChange={(event) => {
                setGender(event.target.value);
                console.log(event.target.value);
              }}>
              {/* <option  >Gender</option> */}
              <option disabled selected value="">
                Gender
              </option>
              {[
                { key: "female", value: "Female" },
                { key: "male", value: "Male" },
                { key: "transgender-female", value: "Transgender Female" },
                { key: "transgender-male", value: "Transgender Male" },
                {
                  key: "gender-variant",
                  value: "Gender Variant/Non-Conforming",
                },
                { key: "not-listed", value: "Not Listed" },
                { key: "not_to_say", value: "Prefer Not to Answer" },
              ].map((gender) => {
                return (
                  <option
                    onClick={() => console.log("test")}
                    key={gender.key}
                    defaultValue={gender.key === selectedGender}
                    value={gender.key}>
                    {gender.value}
                  </option>
                );
              })}
            </Input>
            {genderError}
          </FormGroup>
          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.emailLabel")}</Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                value={email}
                onChange={(e) => handleNameValidation(e, "email")}
                placeholder={t("personalInfo.emailPlaceholder")}
                type="email"
                onBlur={(event) => {
                  event.target.value === ""
                    ? setEmailError(
                        <small className="text-danger">
                          {t("addFamilyMember.emailError")}
                        </small>
                      )
                    : handleNameValidation(event, "email");
                }}
              />
            </InputGroup>
            {emailError}
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">{t("personalInfo.phoneLabel")}</Label>
            <div className="form-group phone-input">
              <PhoneInput
                maxLength="16"
                defaultCountry="CA"
                extension="true"
                placeholder={t("addFamilyMember.phonePlaceholder")}
                value={phone}
                onChange={(value) => {
                  setPhone(value);
                  if (value && !isPossiblePhoneNumber(value)) {
                    setPhoneError(
                      <small className="text-danger px-4">
                        {t("addFamilyMember.phoneError")}
                      </small>
                    );
                  } else {
                    setPhoneError(null);
                  }
                }}
                required
              />
              {phoneError}
            </div>
          </FormGroup>

          <FormGroup>
            <Label className="bold-blue">
              {t("personalInfo.dateOfBirthLabel")}
            </Label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  className: "dob-readonly-input form-control",
                  placeholder: t("personalInfo.dateOfBirthLabel"),
                  readOnly: true,
                }}
                locale={i18n.language}
                dateFormat={dateFormat}
                onBlur={(event) => {
                  event === "" &&
                    setDOBError(
                      <small className="text-danger">
                        {t("personalInfo.dateOfBirthError")}
                      </small>
                    );
                }}
                onChange={(e) => {
                  if (e && typeof e.toDate === "function") {
                    setDate(e.toDate());
                    setDOBError(null);
                  } else {
                    setDOBError(
                      <small className="text-danger">
                        {t("addFamilyMember.dateOfBirthInvalid")}
                      </small>
                    );
                  }
                }}
                value={date}
                timeFormat={false}
                closeOnSelect={true}
                isValidDate={disableFutureDt}
              />
            </InputGroup>
            {DOBError}
          </FormGroup>
          {!user.hasCorporateProfile && (
            <>
              <FormGroup>
                <Label className="bold-blue">
                  {t("addFamilyMember.allergies")}
                </Label>
                <InputGroup>
                  <Input
                    style={{ height: 100 }}
                    type="textarea"
                    value={allergies}
                    onChange={(event) => {
                      setAllergies(event.target.value);
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />
              <FormGroup>
                <Label className="bold-blue">
                  {t("addFamilyMember.medicalConditions")}
                </Label>
                <div style={{ display: "flex-wrap" }}>
                  {medicalConditions &&
                    medicalConditions.length > 0 &&
                    medicalConditions.map((item) => {
                      return (
                        <button
                          key={item.id}
                          style={{
                            border: "1px solid black",
                            borderRadius: "100px",
                            background: selectedMedicalConditions?.includes(
                              item.condition
                            )
                              ? "#3DCB90"
                              : "#fff",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            margin: "6px",
                            fontSize: "12px",
                          }}
                          onClick={() => {
                            if (
                              !selectedMedicalConditions?.includes(
                                item.condition
                              )
                            ) {
                              setSelectedMedicalConditions(
                                produce(selectedMedicalConditions, (draft) => {
                                  draft.push(item.condition);
                                })
                              );
                            } else {
                              setSelectedMedicalConditions(
                                produce(selectedMedicalConditions, (draft) => {
                                  const index = draft.indexOf(item.condition);
                                  const x = draft.splice(index, 1);
                                  console.log(x);
                                })
                              );
                            }
                          }}>
                          {item.condition}
                        </button>
                      );
                    })}
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="bold-blue">
                  {t("addFamilyMember.medicalConditionsOthers")}
                </Label>
                <InputGroup>
                  <Input
                    type="textarea"
                    placeholder="..."
                    value={medicalConditionsOthers}
                    onChange={(event) => {
                      setMedicalConditionsOthers(event.target.value);
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />
              <FormGroup>
                <Label className="bold-blue">
                  {t("addFamilyMember.alerts")}
                </Label>
                <div style={{ display: "flex-wrap" }}>
                  {alerts &&
                    alerts.length > 0 &&
                    alerts.map((item) => {
                      return (
                        <button
                          key={item.id}
                          style={{
                            border: "1px solid black",
                            borderRadius: "100px",
                            background: selectedAlerts.includes(item.title)
                              ? "#3DCB90"
                              : "#fff",
                            paddingTop: "10px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            margin: "6px",
                            fontSize: "12px",
                          }}
                          onClick={() => {
                            if (!selectedAlerts.includes(item.title)) {
                              setSelectedAlerts(
                                produce(selectedAlerts, (draft) => {
                                  draft.push(item.title);
                                })
                              );
                            } else {
                              setSelectedAlerts(
                                produce(selectedAlerts, (draft) => {
                                  const index = draft.indexOf(item.title);
                                  const x = draft.splice(index, 1);
                                  console.log(x);
                                })
                              );
                            }
                          }}>
                          {item.title}
                        </button>
                      );
                    })}
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="bold-blue">
                  {t("addFamilyMember.alertsOthers")}
                </Label>
                <InputGroup>
                  <Input
                    type="textarea"
                    placeholder="Additional Alerts"
                    value={alertsOthers}
                    onChange={(event) => {
                      setAlertsOthers(event.target.value);
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </>
          )}
          <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />
          {false && (
            <React.Fragment>
              <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />
              <FormGroup className="mb-3">
                <Label className="bold-blue">
                  {t("insurance.providerNameLabel")}
                </Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    value={insuranceProvider}
                    placeholder={t("insurance.providerNamePlaceholder")}
                    type="text"
                    onChange={(event) => {
                      setInsuranceProvider(event.target.value);
                    }}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup className="mb-3">
                <Label className="bold-blue">
                  {t("insurance.policyNumberPlaceholder")}
                </Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder={t("insurance.policyNumberPlaceholder")}
                    type="text"
                    value={insuranceNumber}
                    onChange={(event) => {
                      setInsuranceNumber(event.target.value);
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </React.Fragment>
          )}
        </div>

        <ConfirmModal
          modalOpen={confirmModal}
          toggle={() => {
            setConfirmModal(false);
          }}
          callBack={() => {
            onPersonDelete();
            setConfirmModal(false);
          }}
          confirmButton={
            user.hasCorporateProfile
              ? t("confirmDeletionPatients.btn")
              : t("confirmDeletion.btn")
          }
          header={
            user.hasCorporateProfile
              ? t("confirmDeletionPatients.title")
              : t("confirmDeletion.title")
          }
          body={
            user.hasCorporateProfile
              ? t("confirmDeletionPatients.text")
              : t("confirmDeletion.text")
          }
        />
      </SideBar>

      <input
        id="family-member-healthcard-upload"
        type="file"
        accept=".jpeg,.jpg,.png"
        ref={inputFile}
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
    </React.Fragment>
  );
};

export default EditAddFamilyMember;
