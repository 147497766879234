import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { CREATE_APPOINTMENT_URL } from "../../urls.js";
import { Card, Container, CardBody, Button } from "reactstrap";
import AppointmentCard from "../../components/AppointmentCard";
import PendingCard from "../../components/AppointmentPendingCard";
import ServicesSideBar from "../../components/SideBar/Services";
import ServiceSideBar from "../../components/SideBar/Service";
import RescheduleSideBar from "../../components/SideBar/Reschedule";
import RescheduleCheckoutSideBar from "../../components/SideBar/RescheduleCheckout.js";
import RequisitionForm from "../../components/SideBar/RequisitionForm";
import Loader from "../../components/Loader/index.js";
import Svg from "../../components/Svg/index.js";
import CancelModal from "../../components/Modals/CancelModal.js";
import HealthDetailsModal from "../../components/Modals/HealthDetailsModal";
import { toast } from "react-toastify";
import { parseError } from "../../helpers/common.js";
import { useTranslation } from "react-i18next";
import authProvider from "../../helpers/authProvider.js";
import ServiceCard from "../../components/ServiceCard/index.js";
import ServiceFolderCard from "../../components/ServiceFolderCard";

const Dashboard = (props) => {
  const { t } = useTranslation("common");
  const [currentProfile, setCurrentProfile] = useState(
    authProvider.getCurrentProfileType()
  );
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [appointmentsError, setAppointmentsError] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [nextPageToken, setNextPageToken] = useState(null);
  const [servicesSideBarOpen, setServicesSideBarOpen] = useState(false);
  const [serviceSideBarOpen, setServiceSideBarOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [servicesFolders, setServicesFolders] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(false);
  const [servicesError, setServicesError] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServiceFolder, setSelectedServiceFolder] = useState(null);
  const [servicesBackNavigation, setServicesBackNavigation] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [rescheduleData, setRescheduleData] = useState(null);
  const [requisitionSideBarOpen, setRequisitionSideBarOpen] = useState(false);
  const [healthModalOpen, setHealthModalOpen] = useState(false);
  const [banner, setBanner] = useState(undefined);
  const [showServices, setShowServices] = useState(false);

  const currentUser = authProvider.getUser();

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });

    if (services.length === 0) {
      getServicesFolders();
    }

    if (pendingAppointments.length === 0 || appointments.length === 0) {
      getPendingAppointments();
    }

    if (!banner) {
      getBanner();
    }

    window.addEventListener("profileTypeChanged", onProfileTypeChanged, true);

    return function cleanup() {
      window.removeEventListener(
        "profileTypeChanged",
        onProfileTypeChanged,
        true
      );
    };
  }, []);

  const onProfileTypeChanged = () => {
    setCurrentProfile(authProvider.getCurrentProfileType());
    setBanner(undefined);
    getBanner();
    getServicesFolders();
    getPendingAppointments();
  };

  const getBanner = () => {
    const profileType = authProvider.getCurrentProfileType();

    if (profileType === "corporate") {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/CorporateClients/${currentUser?.corporateClientId}`
        )
        .then((res) => {
          localStorage.setItem(
            "isBilledExternally",
            res?.data?.isBilledExternally
          );
          setBanner(res.data.banner ? res.data.banner : undefined);
        })
        .catch((err) => {
          setBanner(undefined);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/banners/default`)
        .then((res) => {
          if (res.status === 204) {
            setBanner(undefined);
          } else {
            setBanner(res.data);
          }
        })
        .catch((err) => {
          setBanner(undefined);
        });
    }
  };

  const getServicesFolders = () => {
    setServicesLoading(true);
    setServicesError(null);
    setServices([]);

    axios
      .get(`${process.env.REACT_APP_API_URL}/Services/folders`)
      .then((res) => {
        setServicesLoading(false);
        setServicesFolders(res.data);
      })
      .catch((err) => {
        setServicesLoading(false);
        let error = parseError(err);
        setServicesError(error);
      });
  };

  const getServices = () => {
    setServicesLoading(true);
    setServicesError(null);
    setServices([]);

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/services?profile=${authProvider.getCurrentProfileType()}`
      )
      .then((res) => {
        setServicesLoading(false);
        setServices(res.data);
      })
      .catch((err) => {
        setServicesLoading(false);
        let error = parseError(err);
        setServicesError(error);
      });
  };

  const getAppointments = (pageToken = null) => {
    setAppointmentsLoading(true);
    setAppointmentsError(null);

    if (!pageToken) {
      setAppointments([]);
      setNextPageToken(null);
    }

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/appointments/active?pageSize=20&isBackward=false&paginationToken=${
          pageToken || ""
        }&profile=${authProvider.getCurrentProfileType()}`
      )
      .then((res) => {
        setAppointmentsLoading(false);
        setAppointments((prev) => prev.concat(res.data.items));
        setNextPageToken(res.data.nextPageToken);
      })
      .catch((err) => {
        setAppointmentsLoading(false);
        let error = parseError(err);
        setAppointmentsError(error);
      });
  };

  const getPendingAppointments = () => {
    setPendingAppointments([]);
    setAppointmentsLoading(true);
    setAppointmentsError(null);

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/appointments/asap?profile=${authProvider.getCurrentProfileType()}`
      )
      .then((res) => {
        setPendingAppointments(res.data || []);
      })
      .catch((err) => {
        let error = parseError(err);
        console.warn(error);
      })
      .finally(() => {
        getAppointments();
      });
  };

  const onServiceSelected = (service) => {
    setSelectedService(service);
    setServiceSideBarOpen(true);
  };

  const onServiceFolderSelected = (serviceFolder) => {
    setSelectedServiceFolder(serviceFolder);
    setServices(serviceFolder?.services);
    if (serviceFolder?.id == selectedServiceFolder?.id || !showServices)
      setShowServices(!showServices);
  };

  const onServiceBookConfirm = (service) => {
    var user = authProvider.getUser();
    // if (!user.healthCareCard || !user.healthCareCard.cardNumber) {
    //   setSelectedService(service)
    //   setHealthModalOpen(true)
    // } else {
    props.history.push({
      pathname: CREATE_APPOINTMENT_URL,
      state: { people: [authProvider.getUser()], service: service },
    });
    // }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Noosu - {t("navbar.appointments")}</title>
      </Helmet>
      <main style={{ paddingTop: 64, paddingBottom: 64 }}>
        {banner && (
          <section className="section-appointments-cover">
            <div className="home-banner-text d-flex gap-6">
              {currentProfile === "personal" && (
                <Svg
                  name="logo-nav"
                  style={{ height: "32px" }}
                  className="theme-dark-text"
                />
              )}
              <p
                className="bold"
                style={{
                  margin: 0,
                  maxWidth: "620px",
                  width: "80%",
                  color: banner.titleColor || "#002b90",
                }}>
                {banner.title}
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 400,
                  maxWidth: "620px",
                  width: "80%",
                  lineHeight: "28px",
                  color: banner.descriptionColor || "#002b90",
                }}>
                {banner.description}
              </p>
            </div>
            {currentProfile === "personal" && (
              <Svg name="star-piece" className="home-star-piece" />
            )}
            <img
              className="home-banner"
              src={banner.images.large.url}
              alt="Avvy Health Banner"
            />
          </section>
        )}

        <section className="section d-flex flex-column gap-24 pt-4">
          {(appointmentsLoading ||
            appointmentsError ||
            appointments.length > 0 ||
            pendingAppointments.length > 0) && (
            <Container>
              <div className="d-flex flex-column gap-24 mt-3 w-full">
                <h5 className="m-0">{t("dashboard.upcomingTitle")}</h5>

                {props.reorder.finalPayment && <div>true</div>}
                {!appointmentsLoading && !appointmentsError && (
                  <div
                    className="gap-24"
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(320px, 1fr))",
                    }}>
                    {pendingAppointments.map((app) => {
                      return (
                        <PendingCard
                          onCancel={(app) => {
                            setSelectedAppointment(app);
                            setIsCancelModalOpen(true);
                          }}
                          onReschedule={(app) => {
                            setSelectedAppointment(app);
                            setIsRescheduleModalOpen(true);
                          }}
                          key={app.appointmentId}
                          appointment={app}
                        />
                      );
                    })}

                    {appointments.map((app) => {
                      return (
                        <AppointmentCard
                          onCancel={(app) => {
                            setSelectedAppointment(app);
                            setIsCancelModalOpen(true);
                          }}
                          onReschedule={(app) => {
                            setSelectedAppointment(app);
                            setIsRescheduleModalOpen(true);
                          }}
                          key={app.appointmentId}
                          appointment={app}
                        />
                      );
                    })}
                  </div>
                )}

                {appointmentsLoading && <Loader title={t("common.loading")} />}
                {appointmentsError && (
                  <div
                    className="error-alert mt-3 mx-auto"
                    style={{ padding: "0.6rem", maxWidth: "100%" }}>
                    {appointmentsError}
                  </div>
                )}

                {!appointmentsError &&
                  !appointmentsLoading &&
                  nextPageToken && (
                    <div className="d-flex items-center mx-auto mt-4">
                      <Button
                        outline
                        color="primary"
                        onClick={() => {
                          getAppointments(nextPageToken);
                        }}>
                        {t("dashboard.moreAppointments")}
                      </Button>
                    </div>
                  )}
              </div>
            </Container>
          )}

          <Container className="mt-4">
            <div className="d-flex flex-column gap-24 w-full">
              <div className="d-flex items-end">
                <h5 className="m-0">{t("services.allServices")}</h5>
              </div>

              {servicesLoading && <Loader title={t("common.loading")} />}
              {servicesError && (
                <div
                  className="error-alert mt-3 mx-auto"
                  style={{ padding: "0.6rem", maxWidth: "100%" }}>
                  {servicesError}
                </div>
              )}

              {!servicesLoading &&
                !servicesError &&
                servicesFolders.length > 0 && (
                  <div
                    className="gap-24"
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(280px, 1fr))",
                    }}>
                    {servicesFolders.map((svc) => (
                      <ServiceFolderCard
                        key={svc.id}
                        service={svc}
                        onClick={() => {
                          onServiceFolderSelected(svc);
                          // setServicesBackNavigation(false)
                        }}
                      />
                    ))}
                  </div>
                )}
              {!servicesLoading &&
                !servicesError &&
                showServices &&
                services.length > 0 && (
                  <div
                    className="gap-24"
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(280px, 1fr))",
                    }}>
                    {services.map((svc) => (
                      <ServiceCard
                        key={svc.id}
                        service={svc}
                        onClick={() => {
                          onServiceSelected(svc);
                          setServicesBackNavigation(false);
                        }}
                      />
                    ))}
                  </div>
                )}

              {/* {!servicesLoading && !servicesError && services.length === 0 && (
                <div
                  className="mx-auto my-5"
                  style={{
                    padding: "0.6rem",
                    maxWidth: "100%",
                    fontSize: "14px",
                    color: "var(--sub-text-color)",
                  }}>
                  {t("dashboard.noServicesAvailable")}
                </div>
              )} */}
            </div>
          </Container>
        </section>

        <ServicesSideBar
          sidebarGroup="dashboard-services"
          isOpen={servicesSideBarOpen}
          onServiceSelected={(service) => {
            onServiceSelected(service);
            setServicesBackNavigation(true);
          }}
          selectedServices={[]}
          toggle={() => {
            setServicesBackNavigation(false);
            setServicesSideBarOpen(false);
          }}
        />

        <ServiceSideBar
          sidebarGroup="dashboard-services"
          isOpen={serviceSideBarOpen}
          service={selectedService}
          nextStep={(service) => {
            setSelectedService(service);
            if (service.requisitionFormRequired) {
              setRequisitionSideBarOpen(true);
            } else {
              onServiceBookConfirm(service);
            }
          }}
          toggle={() => {
            setServiceSideBarOpen(false);
            if (servicesBackNavigation) {
              setServicesSideBarOpen(true);
            }
          }}
        />

        <RequisitionForm
          sidebarGroup="app-requisition"
          isOpen={requisitionSideBarOpen}
          toggle={() => setRequisitionSideBarOpen(false)}
          selectedService={selectedService}
          addService={(service) => {
            setRequisitionSideBarOpen(false);
            onServiceBookConfirm(service);
          }}
          onBack={() => {
            setServiceSideBarOpen(true);
          }}
        />

        {healthModalOpen && (
          <HealthDetailsModal
            onConfirmed={() => {
              onServiceBookConfirm(selectedService);
              setHealthModalOpen(false);
            }}
            isOpen={healthModalOpen}
            toggle={() => setHealthModalOpen(false)}
          />
        )}

        <CancelModal
          appointment={selectedAppointment}
          isOpen={isCancelModalOpen && selectedAppointment != null}
          toggle={() => {
            setIsCancelModalOpen(false);
            setSelectedAppointment(null);
          }}
          onCancelled={() => {
            toast.dark(t("appointmentCancelled.text"), {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setSelectedAppointment(null);
            getAppointments();
          }}
        />

        <RescheduleSideBar
          sidebarGroup="dashboard-reschedule"
          isOpen={isRescheduleModalOpen && selectedAppointment != null}
          appointment={selectedAppointment}
          toggle={() => {
            setIsRescheduleModalOpen(false);
            setSelectedAppointment(null);
          }}
          onDateSelected={(data) => {
            setRescheduleData(data);
            setIsCheckoutModalOpen(true);
          }}
        />

        <RescheduleCheckoutSideBar
          onRescheduled={() => {
            toast.dark(t("schedule.rescheduleComplete"), {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setSelectedAppointment(null);
            setRescheduleData(null);
            getAppointments();
          }}
          sidebarGroup="dashboard-reschedule"
          rescheduleOptions={rescheduleData}
          isOpen={isCheckoutModalOpen && rescheduleData != null}
          toggle={() => {
            setRescheduleData(null);
            setIsCheckoutModalOpen(false);
          }}
        />
      </main>
    </React.Fragment>
  );
};

// export default Dashboard
export default connect((state) => state, null)(Dashboard);
