import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { Link, Redirect } from "react-router-dom"
import { Button, FormGroup, InputGroup, Input } from "reactstrap"
import { AUTH_SIGN_UP_PERSONAL_INFO_URL } from "../../../urls"
import Svg from "../../../components/Svg"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { changeLanguage, detectDefaultLanguage, parseError } from "../../../helpers/common"
import Loader from "../../../components/Loader"
import authProvider from "../../../helpers/authProvider"

const SignUpEmailVerification = (props) => {
  const { i18n, t } = useTranslation("common")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [code, setCode] = useState("")

  useEffect(() => {
    if (!window.signupState || !window.signupState.phone || !window.signupState.password) {
      return
    }

    window.scrollTo({
      behavior: "smooth",
      top: 0,
    })

    setCode("")
    setError(null)
    setLoading(false)
  }, [])

  const onVerifyCode = () => {
    setLoading(true)
    setError(null)

    const verificationData = {
      email: window.signupState.email,
      code: code,
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/ConfirmEmail`, verificationData)
      .then((res) => {
        setLoading(false)
        setError(null)
        onLogin()
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)
        setError(error)
      })
  }

  const onLogin = () => {
    setError(null)
    setLoading(true)

    authProvider
      .login(window.signupState.email, window.signupState.password)
      .then((res) => {
        const userLanguage = res.data.language
        const currentLang = detectDefaultLanguage()

        if (currentLang !== userLanguage) {
          changeLanguage(i18n, t, userLanguage)
        }

        window.signupState = null
        window.location.href = "/"
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)
        setError(error)
      })
  }

  if (!window.signupState || !window.signupState.email || !window.signupState.password) {
    return <Redirect to={AUTH_SIGN_UP_PERSONAL_INFO_URL} />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Noosu - {t("emailVerification.title")}</title>
      </Helmet>
      <div className="d-flex flex-column" style={{ width: "90%", maxWidth: "480px" }}>
        <Link to={AUTH_SIGN_UP_PERSONAL_INFO_URL}>
          <Svg className="page-back-arrow" name="arrow-left" />
        </Link>

        <h2 className="mb-3">{t("emailVerification.title")}</h2>

        <div style={{ width: "100%" }} className="d-flex flex-column gap-12">
          <div className="main-text text-normal mb-2" dangerouslySetInnerHTML={{ __html: t("emailVerification.subText") }}></div>

          <FormGroup>
            <InputGroup className="input-group-alternative">
              <Input
                required
                placeholder={t("emailVerification.placeholder")}
                type="text"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value)
                }}
              />
            </InputGroup>
          </FormGroup>

          <Button disabled={loading || !code} className="btn-accent mt-4" onClick={() => onVerifyCode()}>
            {t("emailVerification.ButtonSubmit")}
          </Button>

          {loading && <Loader className="mt-4" title={t("common.loading")} />}
          {error && (
            <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
              {error}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default SignUpEmailVerification
