//redux with immer doc
//https://redux-toolkit.js.org/usage/immer-reducers
import { createAction, createReducer } from "@reduxjs/toolkit";

let initialState = {
  userAction: "",
  technician: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    status: "enabled",
    rating: 4,
    picture: null,
  },
  patient: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    rating: 0,
    type: "regular",
    medicalConditions: null,
    alerts: null,
  },
  bookedAt: { hour: 0, minute: 0, second: 0, year: 0, month: 0, day: 0 },
  address: {
    unitNumber: "",
    street: "",
    city: "",
    postalCode: "",
    province: "",
  },
  location: { latitude: 51.133952, longitude: -114.133722 },
  startTime: { hour: 0, minute: 0, second: 0, year: 0, month: 0, day: 0 },
  startedAt: { hour: 0, minute: 0, second: 0, year: 0, month: 0, day: 0 },
  endedAt: { hour: 0, minute: 0, second: 0, year: 0, month: 0, day: 0 },
  estimatedArrivalMinutes: 0,
  discountCodeUsed: null,
  patientNotes: null,
  appointmentPatients: [
    {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: { year: 2023, month: 1, day: 4 },
      gender: { key: "not_to_say", value: "not_to_say" },
      appointmentItems: [
        {
          key: "",
          service: {
            id: "",
            title: "Park visit",
            description: null,
            price: 2500,
            dynamicPrice: false,
            questionnaire: [],
            checklist: [{ id: "01HASNQDYVX5KE0959DJ80MNJ9", completed: true }],
            requisitionForm: false,
            insuranceForm: false,
            requisitionFormId: null,
            insuranceFormId: null,
            dropOffLocationRequired: false,
            requisitionFormRequired: false,
            serviceNoteRequired: false,
            reportId: null,
            serviceNotes: null,
            medicalNotesReportId: null,
            localizations: { Description: {} },
          },
        },
      ],
      medicalConditions: null,
      alerts: null,
      services: [
        {
          key: "KHEDMiEnOic6PD0YNipxaXESA3BjYhsRBQFjAgVjYhBiaxQUHWoYGBIZAGtkC3F%2FcQA8IScYNipxaXEaB3AAFnBjYhsSAB0CFwoFB2YHAAkbChkEFAFiBQUeanBjYhsRBQFjAgVjGBkLZRdqAmMYY2ABFWYAEXEu",
          id: "01HASNQDYVT5TSZHYJWGR1VVM9",
          title: "Park visit",
          description: null,
          price: 2500,
          dynamicPrice: false,
          questionnaire: [],
          checklist: [{ id: "01HASNQDYVX5KE0959DJ80MNJ9", completed: true }],
          requisitionForm: false,
          insuranceForm: false,
          requisitionFormId: null,
          insuranceFormId: null,
          dropOffLocationRequired: false,
          requisitionFormRequired: false,
          serviceNoteRequired: false,
          reportId: null,
          serviceNotes: null,
          medicalNotesReportId: null,
          localizations: {
            Description: {},
          },
        },
      ],
    },
  ],
  type: "scheduled",
  status: "ended",
  cost: {
    tip: 0,
    subtotal: 4500,
    discount: 0,
    total: 4500,
    amountPaid: 4500,
    toPay: 0,
    currency: "CAD",
    services: [
      { id: "01HASNQDYVT5TSZHYJWGR1VVM9", price: 2500, dynamicPrice: false },
      { id: "01HBE5A0N0H33GJBEJWB8EWAX3", price: 2000, dynamicPrice: false },
    ],
  },
  isRated: false,
  rating: 0,
  chatRoomId: null,
  profile: "personal",
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase("reorder/init", (state, action) => {
      return initialState;
    })
    .addCase("reorder/setReorder", (state, action) => {
      //load reorder data, replace null values
      state.userAction = "reorder";
      state.address = action.payload.address;
      state.people = action.payload.appointmentPatients;
      state.appointmentType = action.payload.type;
      state.technician = action.payload.technician;
      state.patient = action.payload.patient;
      state.bookedAt = action.payloadbookedAt;
      state.location = action.payload.location;
      state.startTime = action.payload.startTime;
      state.startedAt = action.payload.startedAt;
      state.endedAt = action.payload.endedAt;
      state.estimatedArrivalMinutes = action.payload.estimatedArrivalMinutes;
      state.discountCodeUsed = action.payload.discountCodeUsed;
      state.patientNotes = action.payload.patientNotes;
      state.appointmentPatients = action.payload.appointmentPatients;
      state.appointmentPatients.forEach((item) => {
        item.appointmentItems.forEach((ele) => {
          ele.serviceId = ele.service?.id;
          ele.questionnaire = ele.service?.questionnaire;
          ele.requisitionFormFileId = ele.service?.requisitionFormId;
          ele.serviceNotes = ele.service?.serviceNotes;
        });
      });
      state.type = action.payload.type;
      state.cost = action.payload.cost;
      state.isRated = action.payload.isRated;
      state.profile = action.payload.profile;
      state.address.latitude = action.payload.location.latitude;
      state.address.longitude = action.payload.location.longitude;
      state.latitude = action.payload.location.latitude;
      state.longitude = action.payload.location.longitude;
    })
    .addCase("reorder/time", (state, action) => {
      state.type = action.payload.type;
      state.appointmentType = action.payload.type;
      state.startTime = {
        year: action.payload?.date?.getFullYear(),
        month: action.payload?.date?.getMonth() + 1,
        day: action.payload?.date?.getDate(),
        hour: action.payload?.date?.getHours(),
        minute: action.payload?.date?.getMinutes(),
      };
    })
    .addCase("reorder/pay", (state, action) => {
      state.finalPayment = action.payload;
    })
    .addCase("formTemplate/clearTemplate", (state, action) => {
      return initialState;
    })
    .addDefaultCase((state, action) => state);
});
