import React, { useEffect, useState } from "react"
import { Button, Input } from "reactstrap"
import EditAddressMap from "../SideBar/EditAddressMap"
import EditAddPatient from "../SideBar/EditAddPatient"
import Services from "../SideBar/Services"
import Service from "../SideBar/Service"
import RequisitionForm from "../SideBar/RequisitionForm"
import Schedule from "../SideBar/Schedule"
import SelectPerson from "../SideBar/SelectPerson"
import { formatPhoneNumber } from "../../assets/js/formatePhoneNumber"
import { useTranslation } from "react-i18next"
import Svg from "../Svg"
import { detectDefaultLanguage, formatFullAddress } from "../../helpers/common"
import InsuranceForm from "../SideBar/InsuranceForm"
import authProvider from "../../helpers/authProvider"

const AppointmentDetails = (props) => {
  const { i18n, t } = useTranslation("common")
  const [people, setPeople] = useState([])
  const [address, setAddress] = useState(null)
  const [scheduledDate, setScheduledDate] = useState(null)
  const [appointmentType, setAppointmentType] = useState("scheduled")
  const [discountCode, setDiscountCode] = useState("")
  const [patientNotes, setPatientNotes] = useState("")
  const [locationSideBarOpen, setLocationSideBarOpen] = useState(false)

  const [personSideBarOpen, setPersonSideBarOpen] = useState(false)
  const [servicesSideBarOpen, setServicesSideBarOpen] = useState(false)
  const [serviceSideBarOpen, setServiceSideBarOpen] = useState(false)
  const [requisitionSideBarOpen, setRequisitionSideBarOpen] = useState(false)
  const [insuranceSideBarOpen, setInsuranceSideBarOpen] = useState(false)
  const [scheduleSideBarOpen, setScheduleSideBarOpen] = useState(false)
  const [membersSidebarOpen, setMembersSidebarOpen] = useState(false)

  const [selectedModal, setSelectedModal] = useState(false)
  const [servicePerson, setServicePerson] = useState(null)
  const [selectedService, setSelectedService] = useState(null)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [editMode, setEditMode] = useState(props.editMode)

  const [notesWordCount, setNotesWordCount] = useState(0)

  useEffect(() => {
    const peopleList = props.people || []
    setPeople(peopleList)

    const personWithAddress = peopleList.find((s) => s.address && s.location && s.location.longitude !== 0 && s.location.latitude !== 0)

    if (!address && personWithAddress) {
      const userAddress = {
        latitude: personWithAddress.location.latitude,
        longitude: personWithAddress.location.longitude,
        province: personWithAddress.address.province,
        provinceCode: personWithAddress.address.province,
        city: personWithAddress.address.city,
        postalCode: personWithAddress.address.postalCode,
        street: personWithAddress.address.street,
        unitNumber: personWithAddress.address.unitNumber,
        fullAddress: formatFullAddress(personWithAddress.address),
      }
      setAddress(userAddress)
      props.updateLocation(userAddress)
    }
  }, [props.people])

  useEffect(() => {
    if (props.address && JSON.stringify(address) !== JSON.stringify(props.address)) {
      setAddress(props.address)
    }
  }, [props.address, address])

  useEffect(() => {
    if (props.scheduledDate) {
      setScheduledDate(props.scheduledDate)
    } else {
      setScheduledDate(null)
    }
  }, [props.scheduledDate])

  useEffect(() => {
    if (props.appointmentType) {
      setAppointmentType(props.appointmentType)
    } else {
      setAppointmentType("scheduled")
    }
  }, [props.appointmentType])

  useEffect(() => {
    if (props.discountCode) {
      setDiscountCode(props.discountCode)
    } else {
      setDiscountCode("")
    }
  }, [props.discountCode])

  useEffect(() => {
    if (props.notes) {
      setPatientNotes(props.notes)
    } else {
      setPatientNotes("")
    }
  }, [props.notes])

  useEffect(() => {
    setEditMode(props.editMode)
  }, [props.editMode])

  const removeService = (personIndex, serviceIndex) => {
    let peopleArr = [...people]
    let person = peopleArr[personIndex]
    let personServices = person.services
    const filteredServices = personServices.filter((service, index) => index !== serviceIndex)
    person.services = filteredServices
    setPeople(peopleArr)
    props.updatePeopleArray(peopleArr)
    resetScheduledDate()
  }

  const onPersonRemoved = (personId) => {
    let peopleArr = [...people]
    let filterPeople = peopleArr.filter((p) => p.id !== personId)
    setPeople(filterPeople)
    props.updatePeopleArray(filterPeople)
  }

  const onPersonUpdated = (updatedPerson) => {
    let newArr = [...people]
    let personIndex = newArr.findIndex((s) => s.id === updatedPerson.id)
    newArr[personIndex] = updatedPerson
    setPeople(newArr)
    props.updatePeopleArray(newArr)
  }

  const onPersonAdded = (newPerson) => {
    let newArr = [...people]

    if (!newPerson.id) {
      newPerson.id = people.length + 1
    }

    if (!newPerson.services) {
      newPerson.services = []
    }

    newArr.push(newPerson)
    setPeople(newArr)
    props.updatePeopleArray(newArr)
    resetScheduledDate()
  }

  const onMembersAdded = (members) => {
    let newArr = [...people]
    newArr = newArr.concat(members)
    setPeople(newArr)
    props.updatePeopleArray(newArr)
    resetScheduledDate()
    setMembersSidebarOpen(false)
  }

  const onAddPerson = () => {
    setMembersSidebarOpen(true)
    setSelectedPatient(null)
  }

  const onEditPatient = (person) => {
    setSelectedPatient(person)
    setPersonSideBarOpen(true)
  }

  const onDateSelected = (scheduleData) => {
    setScheduledDate(scheduleData.date)
    setAppointmentType(scheduleData.type)
    props.scheduleChanged(scheduleData.date, scheduleData.type)
  }

  const resetScheduledDate = () => {
    if (!props.blockSchedule) {
      setScheduledDate(null)
      props.scheduleChanged(null, appointmentType)
    }
  }

  const onServiceAdded = (service) => {
    let index = servicePerson.index
    let peopleArr = [...people]
    let services = peopleArr[index].services

    if (services) {
      services.push(service)
    } else {
      services = [service]
    }

    peopleArr[index].services = services
    setPeople(peopleArr)
    props.updatePeopleArray(peopleArr)
    setSelectedModal(!selectedModal)
    setServicePerson(null)
    setSelectedService(null)
    resetScheduledDate()
  }

  const onSelectService = (person, index) => {
    setServicePerson({ person: person, index: index })
    setServicesSideBarOpen(true)
  }

  const onServiceSelected = (service) => {
    setSelectedService(service)
    setServicesSideBarOpen(false)
    setServiceSideBarOpen(true)
  }

  const calculateNotesWords = (source) => {
    source = source || ""
    source.replace(/(^\s*)|(\s*$)/gi, "")
    source.replace(/[ ]{2,}/gi, " ")
    source.replace(/\n /, "\n")

    const words = source.split(" ")
    const wordsCount = !source ? 0 : words.length
    return wordsCount
  }

  const ScheduleComponent = () => {
    const language = detectDefaultLanguage()

    return (
      <div className="avvy-card d-flex flex-column gap-12">
        <div className="d-flex flex-row gap-12">
          <Svg name={appointmentType === "asap" ? "icon_clock" : "calendar-day-blue"} className="avvy-card-icon" />
          <span className="avvy-card-header">{appointmentType === "asap" ? t("schedule.asap") : t("newBooking.schedule")}</span>

          {editMode && !props.blockSchedule && (
            <Button
              className="ml-auto"
              style={{ padding: 0 }}
              color="link"
              // disabled={
              //   !editMode ||
              //   !address ||
              //   people.length === 0 ||
              //   people.filter((p) => !p.services || p.services.length === 0).length > 0
              // }
              onClick={() => setScheduleSideBarOpen(true)}>
              <Svg name="edit" className="accent-text w-24 h-24" />
            </Button>
          )}
        </div>

        {editMode && (!address || people.length === 0 || people.filter((p) => !p.services || p.services.length === 0).length > 0) && (
          <span className="sub-text" style={{ fontSize: "13px", marginLeft: "36px" }}>
            {t("newBooking.scheduleNoAddressNote")}
          </span>
        )}

        {editMode &&
          address &&
          people.length > 0 &&
          people.filter((p) => !p.services || p.services.length === 0).length === 0 &&
          appointmentType === "scheduled" &&
          !scheduledDate && (
            <span className="sub-text" style={{ fontSize: "13px", marginLeft: "36px" }}>
              {t("newBooking.scheduleNote")}
            </span>
          )}

        {scheduledDate && appointmentType === "scheduled" && (
          <div className="d-flex flex-row gap-12">
            <div className="avvy-card-small d-flex flex-column">
              <span className="theme-dark-text bold">
                {new Intl.DateTimeFormat(language, { month: "long" }).format(scheduledDate)} {scheduledDate.getDate()}
              </span>
              <span className="sub-text" style={{ fontSize: "14px" }}>
                {new Intl.DateTimeFormat(language, { weekday: "long" }).format(scheduledDate)}
              </span>
            </div>

            <div className="avvy-card-small d-flex flex-column">
              <span className="theme-dark-text bold">
                {new Intl.DateTimeFormat(i18n.language, {
                  hour: "numeric",
                  minute: "numeric",
                })
                  .format(scheduledDate)
                  .replace(/^0+/, "")}
              </span>
              <span className="sub-text" style={{ fontSize: "14px" }}>
                {t("appointmentDetails.time")}
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }

  const AddressComponent = () => {
    return (
      <div className="avvy-card d-flex flex-column gap-6">
        <div className="d-flex flex-row gap-12">
          <Svg className="avvy-card-icon" name="icon_location_outline" />
          <span className="avvy-card-header">{t("newBooking.address")}</span>

          {editMode && (
            <Button disabled={!editMode} onClick={() => setLocationSideBarOpen(true)} color="link" className="ml-auto" style={{ padding: 0 }}>
              <Svg name="edit" className="accent-text w-24 h-24" />
            </Button>
          )}
        </div>

        {!address && (
          <span className="sub-text" style={{ fontSize: "13px", marginLeft: "36px" }}>
            {t("newBooking.noAddressNote")}
          </span>
        )}

        {address && (
          <span style={{ marginLeft: "34px" }} className="bold theme-dark-text">
            {address.fullAddress}
          </span>
        )}
      </div>
    )
  }

  return (
    <section className="d-flex flex-column appointment-details-section" style={Object.assign({ gap: "24px" }, props.style || {})}>
      <AddressComponent />

      {people.length === 0 && (
        <div className="avvy-card d-flex flex-column" style={{ gap: "10px" }}>
          <div className="d-flex flex-row" style={{ alignItems: "flex-start" }}>
            <div className="d-flex flex-row mr-auto" style={{ alignItems: "center", gap: "12px" }}>
              <Svg className="avvy-card-icon" name="user-icon" alt="Person Avatar" />
              <span className="avvy-card-header">{t("newBooking.patients")}</span>
            </div>
          </div>

          <span className="sub-text" style={{ fontSize: "13px", marginLeft: "36px" }}>
            {t("newBooking.patientsEmpty")}
          </span>
        </div>
      )}

      {people.map((person, personIndex) => {
        return (
          <div key={personIndex} className="avvy-card d-flex flex-column" style={{ gap: "16px" }}>
            <div className="d-flex flex-row" style={{ alignItems: "flex-start" }}>
              <div className="d-flex flex-row mr-auto" style={{ alignItems: "center", gap: "12px" }}>
                <Svg className="avvy-card-icon" name="user-icon" alt="Person Avatar" />
                <span className="avvy-card-header">
                  {person.firstName} {person.lastName}
                </span>
              </div>

              {editMode && (
                <Button
                  className="ml-auto"
                  onClick={() => {
                    onEditPatient(person)
                  }}
                  color="link"
                  style={{ padding: 0 }}>
                  <Svg name="edit" className="accent-text w-24 h-24" alt="Edit Pencil" />
                </Button>
              )}
            </div>

            <div className="d-flex flex-column" style={{ gap: "6px" }}>
              <div className="app-details-row">
                <span className="app-details-label">{t("newBooking.email")}</span>
                <span className="app-details-value">{person.email}</span>
              </div>
              <div className="app-details-row">
                <span className="app-details-label">{t("newBooking.phone")}</span>
                <span className="app-details-value">{formatPhoneNumber(person.phone)}</span>
              </div>
            </div>

            <div className="d-flex flex-column" style={{ gap: "12px" }}>
              <span className="semibold theme-dark-text" style={{ fontSize: "14px" }}>
                Services
              </span>

              <div className="d-flex flex-row flex-wrap gap-6">
                {(person.services || []).map((service, index) => {
                  return (
                    <Button
                      key={index}
                      size="sm"
                      color="primary"
                      style={{ padding: "0.3rem 0.8rem 0.2rem 0.8rem", alignItems: "center" }}
                      onClick={() => {
                        if (editMode) {
                          removeService(personIndex, index)
                        }
                      }}>
                      {service.title || service.name || service.services}
                      {editMode && <Svg className="ml-3" style={{ width: "20px", height: "20px" }} name="icon_cancel" alt="Cancel" />}
                    </Button>
                  )
                })}

                {editMode && (
                  <div className="d-flex flex-column items-start gap-12">
                    <Button
                      size="sm"
                      color="primary"
                      className="d-flex flex-row items-center"
                      style={{ padding: "0.3rem 0.6rem", alignItems: "center", flexGrow: "1" }}
                      outline
                      onClick={() => {
                        onSelectService(person, personIndex)
                      }}>
                      {t("newBooking.addServiceBtn")}
                      <Svg className="ml-3" style={{ width: "20px", height: "20px" }} name="icon_plus" alt="Plus Sign" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      })}

      {editMode && (
        <Button style={{ width: "100%" }} disabled={!editMode} onClick={() => onAddPerson()} outline>
          {t("newBooking.addPersonBtn")}
        </Button>
      )}

      <ScheduleComponent />

      <section className="discount-section d-flex flex-column gap-6">
        <span className="bold theme-dark-text">{t("newBooking.discountCode")}</span>
        <Input
          onChange={(e) => {
            setDiscountCode(e.target.value)
            props.updateDiscountCode(e.target.value)
          }}
          value={discountCode}
          disabled={!editMode}
          className="main-text"
          placeholder={!editMode ? "" : t("newBooking.discountPlaceholder")}
        />
      </section>
      <section className="discount-section d-flex flex-column gap-6 position-relative">
        <span className="bold theme-dark-text d-flex items-end">
          {t("appointmentDetails.notes")}{" "}
          {props.maxNotesWords && editMode && (
            <span className={`ml-auto text-normal ${notesWordCount > props.maxNotesWords ? "error-text" : ""}`}>
              {notesWordCount} / {props.maxNotesWords} {t("appointmentDetails.wordsCount")}
            </span>
          )}
        </span>
        <Input
          onChange={(e) => {
            const notesText = e.target.value || ""
            const words = calculateNotesWords(notesText)
            setPatientNotes(notesText)
            setNotesWordCount(words)
            props.updateNotes(notesText, words)
          }}
          type="textarea"
          rows={6}
          value={patientNotes}
          className="main-text"
          disabled={!editMode}
          placeholder={!editMode ? "" : t("appointmentDetails.notesPlaceholder")}
        />
      </section>

      <EditAddressMap
        sidebarGroup="app-address"
        title={t("newBooking.address")}
        address={address}
        callBack={(addr) => {
          setAddress(addr)
          props.updateLocation(addr)
        }}
        isOpen={locationSideBarOpen}
        toggle={() => {
          setLocationSideBarOpen(false)
        }}
      />

      {editMode && (
        <React.Fragment>
          <EditAddPatient
            appointment={props.appointment}
            sidebarGroup="app-patient-edit"
            person={selectedPatient}
            mode={selectedPatient ? "edit" : "new"}
            onCreated={(person) => onPersonAdded(person)}
            onUpdated={(person) => onPersonUpdated(person)}
            onDeleted={(personId) => onPersonRemoved(personId)}
            isOpen={personSideBarOpen}
            toggle={() => {
              setPersonSideBarOpen(false)
            }}
          />

          <SelectPerson
            sidebarGroup="app-member-select"
            selectedMembers={people}
            onMembersSelected={(members) => onMembersAdded(members)}
            isOpen={membersSidebarOpen}
            toggle={() => setMembersSidebarOpen(false)}
          />
        </React.Fragment>
      )}

      <Services
        sidebarGroup="app-services"
        isOpen={servicesSideBarOpen}
        toggle={() => setServicesSideBarOpen(false)}
        selectedServices={servicePerson ? servicePerson.person.services || [] : []}
        onServiceSelected={onServiceSelected}
        profileType={props.appointment ? props.appointment.profile : authProvider.getCurrentProfileType()}
      />

      <Service
        sidebarGroup="app-service"
        isOpen={serviceSideBarOpen}
        toggle={() => setServiceSideBarOpen(false)}
        service={selectedService}
        setServiceSelected={(svc) => {
          setSelectedService(svc)
        }}
        bookings={true}
        nextStep={(svc) => {
          setServiceSideBarOpen(false)
          if (svc.requisitionFormRequired === true) {
            setRequisitionSideBarOpen(true)
          } else {
            onServiceAdded(svc)
          }
        }}
      />

      <RequisitionForm
        sidebarGroup="app-requisition"
        isOpen={requisitionSideBarOpen}
        toggle={() => setRequisitionSideBarOpen(false)}
        selectedService={selectedService}
        addService={(service) => {
          setRequisitionSideBarOpen(false)
          onServiceAdded(service)
        }}
        onBack={() => {
          setServiceSideBarOpen(true)
        }}
      />

      {false && (
        <InsuranceForm
          sidebarGroup="app-insurance"
          isOpen={insuranceSideBarOpen}
          toggle={() => setInsuranceSideBarOpen(false)}
          selectedService={selectedService}
          addService={onServiceAdded}
          onBack={() => {
            setInsuranceSideBarOpen(false)
            if (selectedService && selectedService.requisitionFormRequired) {
              setRequisitionSideBarOpen(true)
            } else {
              setServiceSideBarOpen(true)
            }
          }}
        />
      )}

      <Schedule
        sidebarGroup="app-schedule"
        isOpen={scheduleSideBarOpen}
        toggle={() => setScheduleSideBarOpen(false)}
        onDateSelected={onDateSelected}
        people={people}
        address={address}
        selectedDate={scheduledDate}
        appointmentType={appointmentType}
      />
    </section>
  )
}

export default AppointmentDetails
