import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import axios from "axios"
import { Link } from "react-router-dom"
import { Button, FormGroup, Input, Label, InputGroup } from "reactstrap"
import { AUTH_LOGIN_URL, AUTH_FORGOT_PASSWORD_URL } from "../../urls"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import Svg from "../../components/Svg"
import Loader from "../../components/Loader"
import { parseError } from "../../helpers/common"

const ResetPassword = (props) => {
  const { t } = useTranslation("common")
  const [error, setError] = useState(null)
  const [passwordType, setPasswordType] = useState("password")
  const [confirmType, setConfirmType] = useState("password")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [securityCode, setSecurityCode] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordError, setPasswordError] = useState(null)
  const [confirmPasswordError, setConfirmPasswordError] = useState(null)
  const [securityCodeLoading, setSecurityCodeLoading] = useState(false)
  const [securityCodeSeconds, setSecurityCodeSeconds] = useState(0)

  useEffect(() => {
    return () => {
      clearInterval(window.securityCodeInterval)
    }
  }, [])

  useEffect(() => {
    if (securityCodeSeconds <= 0) {
      clearInterval(window.securityCodeInterval)
    } else if (securityCodeSeconds === 60) {
      window.securityCodeInterval = setInterval(() => {
        setSecurityCodeSeconds((seconds) => seconds - 1)
      }, 1000)
    }
  }, [securityCodeSeconds])

  useEffect(() => {
    const search = new URLSearchParams(props.history.location.search).get("email")
    setEmail(search)
    setLoading(false)
    setError(null)
    setPasswordError(null)
    setConfirmPasswordError(null)
    setSecurityCode("")
    setPassword("")
    setConfirmPassword("")
    setPasswordType("password")
    setConfirmType("password")
  }, [props.history])

  const onResendCode = () => {
    setSecurityCodeLoading(true)

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/forgotpassword`, JSON.stringify(email), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setSecurityCodeLoading(false)
        setSecurityCodeSeconds(60)
        toast.info(res.data, {
          position: "top-left",
          autoClose: 6000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      })
      .catch((err) => {
        setSecurityCodeLoading(false)
        let error = parseError(err)
        setError(error)
      })
  }

  const onResetPassword = () => {
    setLoading(true)
    setError(null)

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/confirmforgotpassword`, {
        code: securityCode,
        email: email,
        password: password,
        passwordConfirmation: confirmPassword,
      })
      .then((res) => {
        setLoading(false)
        props.history.push({
          pathname: AUTH_LOGIN_URL,
          search: "?email=" + encodeURIComponent(email),
        })
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)
        setError(error)
      })
  }

  const checkValidPassowrd = () => {
    if (password.match("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})")) {
      setPasswordError()
    } else {
      setPasswordError(<small className="text-danger">{t("password.passwordError")}</small>)
    }
    if (confirmPassword && confirmPassword !== "") {
      checkIfPasswordsMatch()
    }
  }
  const checkIfPasswordsMatch = () => {
    if (password === confirmPassword) {
      setConfirmPasswordError()
    } else {
      setConfirmPasswordError(<small className="text-danger">Passwords do not match.</small>)
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Noosu - {t("resetPassword.title")}</title>
      </Helmet>

      <div className="d-flex flex-column items-start gap-6" style={{ width: "90%", maxWidth: "420px" }}>
        <Link to={AUTH_FORGOT_PASSWORD_URL} className="mb-4">
          <Svg className="page-back-arrow" name="arrow-left" />
        </Link>

        <h2 className="m-0">{t("resetPassword.title")}</h2>

        <FormGroup className="mb-0 w-full">
          <Label className="theme-dark-text bold">{t("resetPassword.code")}</Label>
          <InputGroup className="input-group-alternative">
            <Input
              onChange={(event) => setSecurityCode(event.target.value)}
              placeholder={t("resetPassword.codePlaceholder")}
              type="text"
              value={securityCode}
            />
          </InputGroup>
        </FormGroup>

        <Button
          onClick={() => onResendCode()}
          color="link"
          disabled={securityCodeLoading || securityCodeSeconds > 0}
          className="text-underline ml-auto mr-0 p-0 text-normal">
          {t("resetPassword.resendBtn")} {securityCodeSeconds > 0 ? `(${securityCodeSeconds}s)` : null}
        </Button>

        <FormGroup className="mb-0 mt-4 w-full">
          <Label className="theme-dark-text bold">{t("resetPassword.newPassword")}</Label>
          <InputGroup className="input-group-alternative">
            <Input
              onChange={(event) => setPassword(event.target.value)}
              onBlur={(e) => checkValidPassowrd(e)}
              placeholder={t("resetPassword.newPasswordPlaceholder")}
              type={passwordType}
              value={password}
            />
            {passwordType === "input" ? (
              <Svg className="password-eye-icon" name="eye-off" style={{ top: 10 }} onClick={() => setPasswordType("password")} />
            ) : (
              <Svg className="password-eye-icon" name="eye-on" style={{ top: 10 }} onClick={() => setPasswordType("input")} />
            )}
          </InputGroup>
          {passwordError}
        </FormGroup>

        <FormGroup className="mb-0 mt-2 w-full">
          <Label className="theme-dark-text bold">{t("resetPassword.confirmPass")}</Label>
          <InputGroup className="input-group-alternative">
            <Input
              onChange={(event) => setConfirmPassword(event.target.value)}
              onBlur={() => checkIfPasswordsMatch()}
              placeholder={t("resetPassword.confirmPasswordPlaceholder")}
              type={confirmType}
              value={confirmPassword}
            />
            {confirmType === "input" ? (
              <Svg className="password-eye-icon" name="eye-off" style={{ top: 10 }} onClick={() => setConfirmType("password")} />
            ) : (
              <Svg className="password-eye-icon" name="eye-on" style={{ top: 10 }} onClick={() => setConfirmType("input")} />
            )}
          </InputGroup>
          {confirmPasswordError}
        </FormGroup>

        <Button
          className="mt-4 btn-accent"
          onClick={() => onResetPassword()}
          disabled={!email || !securityCode || !password || !confirmPassword || confirmPasswordError || passwordError || loading}
          type="button">
          {t("resetPassword.title")}
        </Button>

        {loading && <Loader className="align-self-center mt-2" title={t("common.loading")} />}

        {error && (
          <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
            {error}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
