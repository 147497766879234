import axios from "axios"
import store from "../store/index"

const authProvider = {
  isAuthenticated() {
    const session = authProvider.getSession()
    if (session && session.accessToken) {
      return true
    }
  },
  setSession(sessionData) {
    try {
      localStorage.setItem("session", JSON.stringify(sessionData))
    } catch (err) {
      console.error(err)
    }
  },
  getSession() {
    const sessionData = localStorage.getItem("session")
    if (sessionData) {
      try {
        return JSON.parse(sessionData)
      } catch (err) {
        return null
      }
    }
  },
  setUser(userData) {
    try {
      localStorage.setItem("user", JSON.stringify(userData))
      window.dispatchEvent(new CustomEvent("profileUpdated"))

      if (userData.hasCorporateProfile !== true) {
        localStorage.removeItem("profileType")
      }
    } catch (err) {
      console.error(err)
    }
  },
  getUser() {
    const userData = localStorage.getItem("user")
    if (userData) {
      try {
        return JSON.parse(userData)
      } catch (err) {
        return null
      }
    }
  },
  getCurrentProfileType() {
    const savedProfile = localStorage.getItem("profileType")
    const userData = this.getUser()
    if (userData && userData.hasCorporateProfile === true) {
      if (savedProfile) {
        return savedProfile
      }
      return "personal"
    }

    return "personal"
  },
  login(email, password) {
    const self = this

    let data = {
      email: email,
      password: password,
    }

    return new Promise(function (resolve, reject) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/account/login`, data)
        .then((res) => {
          self.setSession(res.data)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  logout() {
    localStorage.removeItem("session")
    localStorage.removeItem("user")
  },
  getUserProfile() {
    return new Promise(function (resolve, reject) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/account/information`)
        .then((res) => {
          store.dispatch({ type: "user/setUser", payload: res.data })
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  refreshToken(refreshToken) {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/account/refreshtoken`, {
          refreshToken: refreshToken,
        })
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
}

export default authProvider
