import React from "react"
import { Helmet } from "react-helmet"
import Index from "../Index"
import { Button } from "reactstrap"
import SectionImage from "../../assets/img/backgrounds/getting-started.jpg"
import { useHistory } from "react-router-dom"
import { AUTH_WELCOME_URL } from "../../urls"
import { useTranslation } from "react-i18next"
import { changeLanguage } from "../../helpers/common"

const Languages = (props) => {
  const { t, i18n } = useTranslation("common")
  const history = useHistory()

  const setLanguage = (lang) => {
    changeLanguage(i18n, t, lang)
    history.push(AUTH_WELCOME_URL)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Noosu - {t("languages.title")}</title>
      </Helmet>

      <Index image={SectionImage} {...props}>
        <h1 className="text-center position-relative stylized-header">{t("languagePage.banner")}</h1>
        <Button className="btn-accent" onClick={() => setLanguage("en")}>
          {t("languages.english")}
        </Button>
        <Button outline onClick={() => setLanguage("fr-CA")}>
          {t("languages.french")}
        </Button>
      </Index>
    </React.Fragment>
  )
}

export default Languages
