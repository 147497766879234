import React, { useEffect, useState } from "react"
import SideBar from "."
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
import { Button } from "reactstrap"
import MemberCard from "../MembersCard"
import Loader from "../Loader"
import EditAddFamilyMemberView from "./EditAddFamilyMember"
import { useTranslation } from "react-i18next"
import { parseError } from "../../helpers/common"

const SelectPerson = (props) => {
  const { t } = useTranslation("common")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showMemberSidebar, setShowMemberSidebar] = useState(false)
  const [members, setMembers] = useState([])
  const [nextPageToken, setNextPageToken] = useState(null)
  const user = useSelector((state) => state.user)
  // GET ​/v1​/CorporateClients​/{id}​/members
  const corporate_or_family = user.hasCorporateProfile ? `CorporateClients/${user?.corporateClientId}/members` : "account/familymembers"

  useEffect(() => {
    if (props.isOpen) {
      if (members.length === 0) {
        getMembers()
      }
    } else {
      setLoading(false)
      setError(null)
      members.forEach((s) => (s.checked = false))
    }
  }, [props.isOpen, props.person])

  const onMemberSelected = (memberId) => {
    let memberArray = [...members]
    const memberIndex = memberArray.findIndex((m) => m.id === memberId)
    memberArray[memberIndex].checked = !memberArray[memberIndex].checked
    setMembers(memberArray)
  }

  const onMemberCreated = (person) => {
    person.checked = false
    setMembers((members) => [...members, person])
  }

  const getMembers = (pageToken = null) => {
    setLoading(true)
    setError(null)

    if (!pageToken) {
      setMembers([])
      setNextPageToken(null)
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/${corporate_or_family}?pageSize=15&paginationToken=${pageToken || ""}`)
      .then((res) => {
        setLoading(false)
        const list = res.data.items
        list.forEach((s) => {
          s.checked = false
        })
        setMembers((prev) => prev.concat(list))
        setNextPageToken(res.data.nextPageToken)
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)
        setError(error)
      })
  }

  return (
    <React.Fragment>
      <SideBar
        {...props}
        title={t("newBooking.addPersonBtn")}
        footer={
          <div className="d-flex items-center">
            <Button
              size="sm"
              className="px-5 py-2 ml-auto btn-accent"
              disabled={loading || !members.find((m) => m.checked === true)}
              onClick={() => {
                const selectedMembers = members.filter((s) => s.checked === true)
                selectedMembers.forEach((m) => {
                  m.isFamilyMember = true
                  m.services = []
                })

                props.onMembersSelected(selectedMembers)
              }}>
              {t("newBooking.addPersonBtn")}
            </Button>
          </div>
        }>
        <div className="d-flex flex-column">
          <div
            className="gap-16"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gridTemplateRows: "minmax(1fr, auto)",
            }}>
            {members.length === 0 && !loading && !error && (
              <div className="avvy-card">
                <span className="sub-text text-normal">{t("selectMembers.noMembers")}</span>
              </div>
            )}

            {members
              .filter((m) => !(props.selectedMembers || []).find((sm) => sm.id && sm.id === m.id))
              .map((member) => {
                return (
                  <MemberCard
                    key={member.id}
                    member={member}
                    onChecked={() => {
                      onMemberSelected(member.id)
                    }}
                  />
                )
              })}
          </div>

          {loading && <Loader title={t("common.loading")} />}
          {error && (
            <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
              {error}
            </div>
          )}

          {!error && !loading && nextPageToken && (
            <div className="d-flex items-center mx-auto mt-4">
              <Button
                outline
                disabled={loading}
                color="primary"
                onClick={() => {
                  getMembers(nextPageToken)
                }}>
                {t("memberSelect.loadMore")}
              </Button>
            </div>
          )}

          <hr style={{ margin: "16px 0" }} />

          <div className="d-flex items-center gap-32 mx-auto" style={{ width: "90%", maxWidth: "500px" }}>
            <Button
              className="px-5 m-0 mr-auto ml-auto"
              outline
              disabled={loading}
              style={{ whiteSpace: "nowrap" }}
              color="primary"
              onClick={() => setShowMemberSidebar(true)}>
              {t("memberSelect.newButton")}
            </Button>
          </div>
        </div>
      </SideBar>

      <EditAddFamilyMemberView
        mode="new"
        sidebarGroup="app-member-select"
        onCreated={(person) => onMemberCreated(person)}
        toggle={() => setShowMemberSidebar(false)}
        isOpen={showMemberSidebar}
      />
    </React.Fragment>
  )
}

export default SelectPerson
