import React, { useEffect, useState } from "react"
import Svg from "../../components/Svg"
import { useSelector, useDispatch } from "react-redux"
import { AUTH_LOGIN_URL } from "../../urls"
import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useTranslation } from "react-i18next"
import authProvider from "../../helpers/authProvider"
import DeleteAccountModal from "../Modals/DeleteAccountModal"

const ProfileMenu = (props) => {
  const { t } = useTranslation("common")
  const [userData, setUserData] = useState(authProvider.getUser())
  const [sidebarState, setSidebarState] = useState({})
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const user = useSelector((state) => state.user)

  useEffect(() => {
    let userData = authProvider.getUser()
    setUserData(userData)

    window.addEventListener("profileUpdated", updateUserDetails, true)
    return function cleanup() {
      window.removeEventListener("profileUpdated", updateUserDetails, true)
    }
  }, [])

  useEffect(() => {
    if (props.onSidebarUpdate) {
      props.onSidebarUpdate(sidebarState)
    }
  }, [sidebarState])

  const onLogout = () => {
    authProvider.logout()
    window.location.href = AUTH_LOGIN_URL
  }

  const updateUserDetails = () => {
    setUserData(authProvider.getUser())
  }

  return (
    <React.Fragment>
      <UncontrolledDropdown
        nav
        className={props.className || ""}
        isOpen={profileMenuOpen}
        toggle={(e) => {
          setProfileMenuOpen((prev) => !prev)
        }}>
        <DropdownToggle
          nav
          className="p-0"
          onClick={() => {
            setSidebarState({})
          }}>
          <Svg className="w-16 h-16 mr-2 nav-profile-text" name="user-silhouette" />
          <span className="nav-link-inner--text nav-profile-text text-normal bold">{userData ? `${userData.firstName} ${userData.lastName}` : ""}</span>
        </DropdownToggle>

        <DropdownMenu
          right
          className="profile-menu"
          style={{
            top: "48px",
            left: "-100%",
            maxHeight: "80vh",
            overflow: "auto",
            padding: 0,
          }}>
          <DropdownItem
            onClick={() => {
              setSidebarState({ profileEdit: true })
            }}
            className="py-2 profile-details-item">
            <div className="d-flex items-center w-full overflow-hidden gap-12">
              <Svg className="w-24 h-24 theme-dark-text flex-shrink-0" name="user-icon" />

              <div className="d-flex flex-column mr-2 flex-shrink-1" style={{ maxWidth: "260px" }}>
                <span style={{ fontSize: 16, lineHeight: "18px" }} className="bold">
                  {userData.firstName + " " + userData.lastName}
                </span>
                <span
                  className="sub-text"
                  style={{
                    fontSize: "13px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}>
                  {userData.email}
                </span>
              </div>

              <span className="ml-auto flex-shrink-0">
                <Svg name="edit" className="w-24 h-24 accent-text" />
              </span>
            </div>
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              setSidebarState({ apptHistory: true })
            }}>
            <div className="nav px-3 items-center d-flex w-full">
              <span className="bold">{t("appointmentHistory.title")}</span>
              <span className="ml-auto">
                <Svg name="arrow-right-small" className="w-24 h-24 accent-text" />
              </span>
            </div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setSidebarState({ familyMembers: true })
            }}>
            <div className="nav px-3 items-center d-flex w-full">
              {user.hasCorporateProfile ? (
                <span className="bold">{t("managePatients.title")}</span>
              ) : (
                <span className="bold">{t("manageFamilyMembers.title")}</span>
              )}
              <span className="ml-auto">
                <Svg name="arrow-right-small" className="w-24 h-24 accent-text" />
              </span>
            </div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setSidebarState({ paymentInfo: true })
            }}>
            <div className="nav px-3 items-center d-flex w-full">
              <span className="bold">{t("paymentInformation.title")}</span>
              <span className="ml-auto">
                <Svg name="arrow-right-small" className="w-24 h-24 accent-text" />
              </span>
            </div>
          </DropdownItem>
          {/* <DropdownItem
            onClick={() => {
              setSidebarState({ corporateProfile: true })
            }}>
            <div className="nav px-3 items-center d-flex w-full">
              <span className="bold">{t("corporate.pageTitle")}</span>
              <span className="ml-auto">
                <Svg name="arrow-right-small" className="w-24 h-24 accent-text" />
              </span>
            </div>
          </DropdownItem> */}
          <DropdownItem
            onClick={() => {
              setSidebarState({ language: true })
            }}>
            <div className="nav px-3 items-center d-flex w-full">
              <span className="bold">{t("languages.title")}</span>
              <span className="ml-auto">
                <Svg name="arrow-right-small" className="w-24 h-24 accent-text" />
              </span>
            </div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setSidebarState({ feedback: true })
            }}>
            <div className="nav px-3 items-center d-flex w-full">
              <span className="bold">{t("feedback.title")}</span>
              <span className="ml-auto">
                <Svg name="arrow-right-small" className="w-24 h-24 accent-text" />
              </span>
            </div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setSidebarState({ about: true })
            }}>
            <div className="nav px-3 items-center d-flex w-full">
              <span className="bold">{t("about.title")}</span>
              <span className="ml-auto">
                <Svg name="arrow-right-small" className="w-24 h-24 accent-text" />
              </span>
            </div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setSidebarState({ termsOfService: true })
            }}>
            <div className="nav px-3 items-center d-flex w-full">
              <span className="bold">{t("tos.title")}</span>
              <span className="ml-auto">
                <Svg name="arrow-right-small" className="w-24 h-24 accent-text" />
              </span>
            </div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setSidebarState({ privacyPolicy: true })
            }}>
            <div className="nav px-3 items-center d-flex w-full">
              <span className="bold">{t("pp.title")}</span>
              <span className="ml-auto">
                <Svg name="arrow-right-small" className="w-24 h-24 accent-text" />
              </span>
            </div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setDeleteModalOpen(true)
            }}>
            <div className="danger nav px-3 items-center d-flex w-full">
              <span className="error-text bold">{t("accountDeletion.confirmText")}</span>
              <span className="ml-auto">
                <Svg name="user-remove" className="w-24 h-24 error-text" />
              </span>
            </div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              onLogout()
            }}>
            <div className="danger nav px-3 items-center d-flex w-full">
              <span className="error-text bold">{t("logout.text")}</span>
              <span className="ml-auto">
                <Svg name="exit" className="w-24 h-24 error-text" />
              </span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <DeleteAccountModal
        modalOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        onDeleted={() => {
          setDeleteModalOpen(false)
          setTimeout(() => {
            onLogout()
          }, 2500)
        }}
      />
    </React.Fragment>
  )
}

export default ProfileMenu
