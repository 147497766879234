import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import SideBar from ".";
import { useTranslation } from "react-i18next";
import Svg from "../Svg";
import moment from "moment";
import "moment/locale/fr";
import Loader from "../Loader";
import ReactDateTime from "react-datetime";
import { parseError } from "../../helpers/common";
import useAbortController from "../../helpers/useAbortController";

const RescheduleSideBar = (props) => {
  const getAbortSignal = useAbortController();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [availabilityDates, setAvailabilityDates] = useState([]);
  const { i18n, t } = useTranslation("common");
  const [appointmentData, setAppointmentData] = useState(null);

  useEffect(() => {
    if (!props.isOpen) {
      setSelectedTime(null);
      setSelectedDate(null);
      setCurrentMonth(null);
      setInitialized(false);
      setAppointmentData(null);
      setAvailabilityDates([]);
    } else {
      if (props.appointment) {
        setCurrentMonth(
          moment(
            `${props.appointment.start.year}-${props.appointment.start.month}-${props.appointment.start.day}`,
            "YYYY-MM-DD"
          ).toDate()
        );
        getAppointmentDetails(props.appointment.appointmentId);
      }
    }
  }, [props.isOpen, props.appointment]);

  useEffect(() => {
    setSelectedTime(null);
    setSelectedDate(null);

    if (appointmentData && currentMonth) {
      getAvailabilities();
    }
  }, [appointmentData, currentMonth]);

  const getAppointmentDetails = (appCode) => {
    setLoading(true);
    setError(null);

    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/${appCode}`)
      .then((res) => {
        setAppointmentData(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setError(error);
        setLoading(false);
      });
  };

  const getAvailabilities = () => {
    if (!props.isOpen || !appointmentData || appointmentData.type === "asap") {
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    var monthStart = (currentMonth ? moment(currentMonth) : moment())
      .startOf("month")
      .toDate();
    var monthEnd = (currentMonth ? moment(currentMonth) : moment())
      .endOf("month")
      .toDate();

    const allServices = appointmentData.appointmentPatients
      .flatMap((s) => s.appointmentItems)
      .map((ai) => ai.service);

    const serviceIds = allServices
      .map((s) => s.id)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

    const requestedServices = serviceIds.map((sId) => {
      return {
        serviceId: sId,
        count: allServices.filter((s) => s.id === sId).length,
      };
    });

    let availabilityData = {
      latitude: appointmentData.location.latitude,
      longitude: appointmentData.location.longitude,
      startDate: {
        year: monthStart.getFullYear(),
        month: monthStart.getMonth() + 1,
        day: monthStart.getDate(),
      },
      endDate: {
        year: monthEnd.getFullYear(),
        month: monthEnd.getMonth() + 1,
        day: monthEnd.getDate(),
      },
      requestedServices: requestedServices,
      appointmentCode: appointmentData.id,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/appointments/availabilities-range`,
        availabilityData,
        { signal: getAbortSignal() }
      )
      .then((res) => {
        setLoading(false);

        if (res.data && res.data.length > 0) {
          setAvailabilityDates(
            res.data.map((ad) => {
              let aDate = moment(
                `${ad.date.year}-${ad.date.month}-${ad.date.day}`,
                "YYYY-MM-DD"
              ).toDate();
              return {
                date: aDate,
                availableTimes: ad.froms.map((cTime) => {
                  let parsedTime = moment(
                    `${ad.date.year}-${ad.date.month}-${ad.date.day} ${cTime.hour}:${cTime.minute}`,
                    "YYYY-MM-DD H:m"
                  );
                  return {
                    id: parsedTime.format("YYYY-MM-DD H:m"),
                    time: parsedTime.toDate(),
                  };
                }),
              };
            })
          );

          if (appointmentData && !initialized) {
            const appStartTime = appointmentData.startTime;
            const appStart = moment(
              `${appStartTime.day}/${appStartTime.month}/${appStartTime.year} ${appStartTime.hour}/${appStartTime.minute}/${appStartTime.second}`,
              "DD/MM/YYYY HH:mm:ss"
            );

            setSelectedDate(appStart);
            setSelectedTime({
              id: appStart.format("YYYY-MM-DD H:m"),
              time: appStart,
            });
            setInitialized(true);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (!axios.isCancel(err)) {
          let error = parseError(err);
          setError(error);
        }
      });
  };

  const onDateChanged = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const onSelectTime = (time) => {
    setSelectedTime(time);
  };

  const onSaveDate = () => {
    let scheduleData = {
      date: selectedTime.time,
      type: appointmentData.type,
      appointment: appointmentData,
    };

    props.onDateSelected(scheduleData);
    props.toggle();
  };

  const Footer = () => {
    return (
      <div className="d-flex items-center">
        <Button
          size="sm"
          className="px-4 py-2 ml-auto btn-accent"
          disabled={
            props.appointment && props.appointment.type === "asap"
              ? true
              : !selectedDate || !selectedTime
          }
          onClick={() => onSaveDate()}>
          {t("cancelAppointment.btn")}
        </Button>
      </div>
    );
  };

  const disableInvalidDt = (current, selected) => {
    return (
      !loading &&
      moment(current).isSameOrAfter(moment(), "day") &&
      availabilityDates.some(
        (ad) =>
          moment(ad.date).isSame(moment(current), "date") &&
          ad.availableTimes.length > 0
      )
    );
  };

  return (
    <SideBar title={t("schedule.selectDate")} {...props} footer={<Footer />}>
      <div className="d-flex flex-column gap-12">
        {appointmentData && appointmentData.type === "asap" && (
          <div className="d-flex flex-column gap-12">
            <div className="d-flex items-center gap-6">
              <Svg className="w-24 h-24 theme-text" name="icon_clock" />
              <div className="bold main-text">{t("schedule.asap")}</div>
            </div>

            <span className="main-text" style={{ fontSize: "13px" }}>
              {t("schedule.cannotRescheduleAsap")}
            </span>
          </div>
        )}

        {appointmentData && appointmentData.type === "scheduled" && (
          <div className="d-flex flex-column">
            <span
              className="error-text text-normal"
              style={{ fontSize: "13px" }}>
              {t("services.dynamicPriceDisclaimer")}
            </span>

            <hr style={{ margin: 0, marginTop: "12px" }} />
            <div className="d-flex flex-column gap-2 mt-2">
              <div className="flex flex-col schedule-calendar">
                <ReactDateTime
                  minDate={moment().toDate()}
                  initialViewMode="days"
                  input={false}
                  locale={i18n.language}
                  onChange={(e) => {
                    if (e && typeof e.toDate === "function") {
                      onDateChanged(e.toDate());
                    }
                  }}
                  value={selectedDate}
                  timeFormat={false}
                  isValidDate={disableInvalidDt}
                  onNavigateBack={(amount, viewMode) => {
                    setCurrentMonth(
                      moment(currentMonth ? currentMonth : new Date())
                        .startOf("month")
                        .subtract(amount, "month")
                        .toDate()
                    );
                  }}
                  onNavigateForward={(amount, viewMode) => {
                    setCurrentMonth(
                      moment(currentMonth ? currentMonth : new Date())
                        .startOf("month")
                        .add(amount, "month")
                        .toDate()
                    );
                  }}
                  onBeforeNavigate={(nextView, currentView, viewDate) => {
                    return false;
                  }}
                />
              </div>

              {selectedDate &&
                availabilityDates.some((s) =>
                  moment(s.date).isSame(moment(selectedDate), "day")
                ) && (
                  <React.Fragment>
                    <h4
                      className="text-normal text-center"
                      style={{
                        fontSize: "16px",
                        paddingBottom: "12px",
                        borderBottom: "2px solid var(--theme-dark-color)",
                      }}>
                      {t("newBooking.availableTimes")}
                    </h4>

                    {availabilityDates.some((s) =>
                      moment(s.date).isSame(moment(selectedDate), "day")
                    ) &&
                      availabilityDates.find((s) =>
                        moment(s.date).isSame(moment(selectedDate), "day")
                      ).availableTimes.length === 0 && (
                        <div
                          className="sub-text"
                          style={{
                            fontSize: "14px",
                            alignSelf: "center",
                            marginTop: "24px",
                            textAlign: "center",
                          }}>
                          {t("newBooking.noTimeSlots")}
                          <br />
                          {t("newBooking.noTimeSlotsDifferentDate")}
                        </div>
                      )}

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "6px",
                      }}>
                      {availabilityDates
                        .find((s) =>
                          moment(s.date).isSame(moment(selectedDate), "day")
                        )
                        .availableTimes.map((slotTime) => {
                          return (
                            <div
                              key={slotTime.id}
                              className={`schedule-time ${
                                selectedTime && selectedTime.id === slotTime.id
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => onSelectTime(slotTime)}>
                              <span>
                                {new Intl.DateTimeFormat(i18n.language, {
                                  hour: "numeric",
                                  minute: "numeric",
                                })
                                  .format(slotTime.time)
                                  .replace(/^0+/, "")}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </React.Fragment>
                )}
            </div>
          </div>
        )}

        {loading && <Loader title={t("newBooking.loadingTimeSlots")} />}

        {error && (
          <div
            className="error-alert"
            style={{
              margin: "12px auto",
              padding: "0.6rem",
              maxWidth: "500px",
            }}>
            {error}
          </div>
        )}
      </div>
    </SideBar>
  );
};

export default RescheduleSideBar;
