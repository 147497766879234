import React, { useEffect, useState } from "react"
import { Link, NavLink, useHistory } from "react-router-dom"
import Svg from "../../components/Svg"
import ProfileMenu from "../ProfileMenu"
import Headroom from "headroom.js"
import authProvider from "../../helpers/authProvider"
import { NavbarBrand, Navbar, Container, Button, UncontrolledCollapse, NavItem, Nav } from "reactstrap"
import { useTranslation } from "react-i18next"
import ManageFamilyMembersSidebar from "../SideBar/ManageFamilyMembers"
import EditPersonInfo from "../SideBar/EditPersonInfo"
import PPSidebar from "../SideBar/PP"
import TOSSidebar from "../SideBar/TOS"
import AboutSidebar from "../SideBar/About"
import FeedBackSidebar from "../SideBar/FeedBack"
import LanguagesSidebar from "../SideBar/Languages"
import { CREATE_APPOINTMENT_URL, DASHBOARD_URL } from "../../urls"
import PaymentInfoSidebar from "../SideBar/PaymentInfo"
import HistorySidebar from "../SideBar/History"
import CorporateProfileSidebar from "../SideBar/CorporateProfile"
import ProfileTypeMenu from "../ProfileMenu/ProfileTypeMenu"

const AvvyNavBar = () => {
  const history = useHistory()
  const { t } = useTranslation("common")
  const [userData, setUserData] = useState(authProvider.getUser())
  const [collapseClasses, setCollapseClasses] = useState("ml-auto")
  const [sidebarState, setSidebarState] = useState({})

  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"))
    headroom.init()

    if (authProvider.isAuthenticated()) {
      let corporateItem = sessionStorage.getItem("corporate-code-link")
      if (corporateItem !== null) {
        setTimeout(() => {
          setSidebarState({ corporateProfile: true })
        }, 800)
      }
    }

    window.addEventListener("profileUpdated", updateUserDetails, true)
    return function cleanup() {
      window.removeEventListener("profileUpdated", updateUserDetails, true)
    }
  }, [])

  useEffect(() => {
    setSidebarState({})
  }, [history.location])

  const updateUserDetails = () => {
    setUserData(authProvider.getUser())
  }

  const onExiting = () => {
    setCollapseClasses("collapsing-out")
  }

  const onExited = () => {
    setCollapseClasses("")
  }

  return (
    <React.Fragment>
      <header className="header-global">
        <Navbar className="navbar-main navbar-avvy navbar-light headroom" expand="lg" id="navbar-main">
          <Container>
            <NavbarBrand to="/dashboard" tag={Link}>
              <Svg name="logo-nav" style={{ height: "36px" }} className="accent-text h-32 nav-logo-full" />
              <Svg name="logo-nav" style={{ height: "36px" }} className="accent-text h-32 nav-logo-compact" />
            </NavbarBrand>

            {authProvider.isAuthenticated() && userData.hasCorporateProfile && <ProfileTypeMenu className="mb-0 ml-3" style={{ marginTop: "-1px" }} />}

            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>

            {authProvider.isAuthenticated() && (
              <UncontrolledCollapse toggler="#navbar_global" navbar className={collapseClasses} onExiting={onExiting} onExited={onExited}>
                <div className="navbar-collapse-header">
                  <Link to="/dashboard">
                    <Svg name="logo-nav" className="theme-text h-32" />
                  </Link>

                  <button className="navbar-toggler ml-auto main-text" id="navbar_global">
                    <Svg name="close" className="main-text w-24 h-24" />
                  </button>
                </div>

                {history.location.pathname !== "/dashboard" && (
                  <Nav className="navbar-nav-hover mb-0 ml-auto" navbar>
                    <NavItem className="d-flex items-center" style={{ marginTop: "2px" }}>
                      <NavLink to={DASHBOARD_URL} className="d-flex items-center">
                        <Svg className="w-16 h-16 mr-2 text-white" name="calendar-check-g" />
                        <span className="nav-link-inner--text text-normal bold text-white">{t("navbar.appointments")}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                )}

                <ProfileMenu
                  className={`mb-0 ml-3 profile-menu-dropdown ${history.location.pathname !== "/dashboard" ? "" : "ml-auto"}`}
                  onSidebarUpdate={(state) => setSidebarState(state)}
                />

                <Nav className="navbar-nav-hover align-items-lg-center mb-0 ml-4" navbar>
                  <NavItem className="d-lg-block">
                    <Button
                      className="btn-accent"
                      onClick={() => {
                        history.push({
                          pathname: CREATE_APPOINTMENT_URL,
                        })
                      }}>
                      <span className="btn-inner--icon">
                        <i className="fa fa-plus mr-2" />
                      </span>
                      <span className="nav-link-inner--text ml-1">{t("navbar.newBookingBtn")}</span>
                    </Button>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            )}
          </Container>
        </Navbar>
      </header>

      <ManageFamilyMembersSidebar isOpen={sidebarState.familyMembers === true} toggle={() => setSidebarState({})} sidebarGroup="profile-members" />

      <EditPersonInfo
        editPerson={userData}
        isOpen={sidebarState.profileEdit === true}
        translations={t}
        toggle={() => setSidebarState({})}
        sidebarGroup="profile-edit"
      />
      <FeedBackSidebar sidebarGroup="feedback" isOpen={sidebarState.feedback === true} toggle={() => setSidebarState({})} />
      <AboutSidebar sidebarGroup="about" isOpen={sidebarState.about === true} toggle={() => setSidebarState({})} />
      <PPSidebar sidebarGroup="privacy-policy" isOpen={sidebarState.privacyPolicy === true} toggle={() => setSidebarState({})} />
      <TOSSidebar sidebarGroup="terms-of-service" isOpen={sidebarState.termsOfService === true} toggle={() => setSidebarState({})} />
      <LanguagesSidebar sidebarGroup="profile-language" isOpen={sidebarState.language === true} toggle={() => setSidebarState({})} />
      <PaymentInfoSidebar sidebarGroup="profile-payments" isOpen={sidebarState.paymentInfo === true} toggle={() => setSidebarState({})} />
      <HistorySidebar sidebarGroup="app-history" isOpen={sidebarState.apptHistory === true} toggle={() => setSidebarState({})} />
      <CorporateProfileSidebar sidebarGroup="corporate-profile" isOpen={sidebarState.corporateProfile === true} toggle={() => setSidebarState({})} />
    </React.Fragment>
  )
}

export default AvvyNavBar
