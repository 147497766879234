import React, { useEffect, useState } from "react";
import axios from "axios";
import SideBar from ".";
import { Button, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import Checkbox from "../Checkbox";
import RadioButton from "../RadioButton";
import { formatMoney, parseError } from "../../helpers/common";
import Loader from "../Loader";
import authProvider from "../../helpers/authProvider";
import Svg from "../Svg";

const ServiceSidebar = (props) => {
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [serviceSelected, setServiceSelected] = useState(null);

  useEffect(() => {
    if (props.service && props.isOpen) {
      getService(props.service.id);
    } else {
      setServiceSelected(null);
    }
  }, [props.service, props.isOpen]);

  const getService = (id) => {
    setServiceSelected(null);
    setLoading(true);
    setError(null);

    axios
      .get(
        `${process.env.REACT_APP_API_URL.replace(
          "v1",
          "v2"
        )}/services/${id}?profile=${authProvider.getCurrentProfileType()}`
      )
      .then((res) => {
        setLoading(false);

        let svc = res.data;
        if (svc.questionnaire && svc.questionnaire.length > 0) {
          svc.questionnaire.forEach((q) => {
            const eq = (props.service.questionnaire || []).find(
              (qq) => qq.id === q.id
            );
            q.completed = eq ? eq.completed || false : false;
            q.optionIds = eq ? eq.optionIds || [] : [];
            q.notes = eq ? eq.notes || "" : "";
            q.version = "v2";
          });
        }

        setServiceSelected(svc);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const cleanup = () => {
    setServiceSelected(null);
  };

  return (
    <SideBar
      onClose={() => cleanup()}
      {...props}
      service={false}
      title={serviceSelected && serviceSelected.title}
      forceImage={serviceSelected !== null}
      picutreUrl={serviceSelected ? serviceSelected.picture : null}
      footer={
        <div className="d-flex flex-row items-center">
          <Button
            disabled={
              serviceSelected &&
              serviceSelected.questionnaire &&
              serviceSelected.questionnaire.length > 0 &&
              !serviceSelected.questionnaire.every(
                (q) =>
                  q.completed &&
                  ((q.notes.length > 0 && q.allowFreeText) || !q.allowFreeText)
              )
            }
            size="sm"
            className="px-4 py-2 ml-auto btn-accent"
            style={{ marginRight: "16px", padding: "6px 24px" }}
            onClick={() => {
              if (props.bookings === true) {
                props.setServiceSelected(serviceSelected);
                props.nextStep(serviceSelected);
                cleanup();
              } else {
                props.nextStep(serviceSelected);
                cleanup();
              }
            }}>
            {t("healthCare.buttonText")}
          </Button>
        </div>
      }>
      <div className="d-flex flex-column gap-16">
        {loading && <Loader className="mt-6" title={t("common.loading")} />}
        {error && (
          <div
            className="error-alert mt-3 mx-auto"
            style={{ padding: "0.6rem", maxWidth: "100%" }}>
            {error}
          </div>
        )}

        {serviceSelected && (
          <div className="main-text mt--4">
            {formatMoney(serviceSelected.price, i18n.language)}
          </div>
        )}

        {serviceSelected && serviceSelected.ageRestriction && (
          <div className="main-text bold mt--3">
            {serviceSelected.ageRestriction}
          </div>
        )}

        {serviceSelected?.picture?.url ? (
          <img
            src={serviceSelected.picture.url}
            className="service-image ml-auto mr-auto"
            alt={serviceSelected.title}
          />
        ) : (
          <div
            className="service-image d-flex items-center justify-content-center ml-auto mr-auto"
            style={{ opacity: 0.7 }}>
            <Svg
              name="avvy-shape"
              className="sub-text"
              style={{ width: "32px", height: "32px" }}
            />
          </div>
        )}

        {serviceSelected && serviceSelected.description && (
          <div
            className="main-text"
            dangerouslySetInnerHTML={{
              __html: serviceSelected.description,
            }}></div>
        )}

        {serviceSelected && serviceSelected.disclaimer && (
          <div
            className="main-text text-small mb-2"
            dangerouslySetInnerHTML={{
              __html: serviceSelected.disclaimer,
            }}></div>
        )}

        <div className="d-flex flex-column gap-24 w-full">
          {(serviceSelected ? serviceSelected.questionnaire : []).map(
            (question, idx) => {
              return question.options.length === 0 ? (
                <div
                  key={`${question.id}`}
                  className="d-flex flex-column gap-6"
                  style={{
                    borderBottom:
                      idx === serviceSelected.questionnaire.length
                        ? "none"
                        : "1px solid #eee",
                    paddingBottom: "24px",
                  }}>
                  <div className="bold text-dark-blue text-normal">
                    {question.description}
                    <span className="text-red ml-2">Required</span>
                  </div>
                  <Input
                    maxLength={500}
                    required
                    value={question.notes || ""}
                    onChange={(e) => {
                      let text = e.target.value;
                      setServiceSelected((prev) => {
                        return Object.assign({}, prev, {
                          questionnaire: prev.questionnaire.map((q) => {
                            if (q.id === question.id) {
                              return Object.assign(q, {
                                notes:
                                  text.length > 500
                                    ? text.substring(0, 499)
                                    : text,
                                completed: text.length > 0,
                              });
                            }
                            return q;
                          }),
                        });
                      });
                    }}
                    placeholder={t("appointmentDetails.questionAnswer")}
                    type="text"
                  />
                  <span
                    className="theme-dark-text semibold text-small"
                    style={{
                      marginLeft: "auto",
                      marginRight: "6px",
                      marginTop: "0px",
                    }}>
                    {question.notes ? question.notes.length : 0}/500
                  </span>
                </div>
              ) : (
                <div
                  className="d-flex flex-column gap-16"
                  key={`${question.id}-${question.optionIds.length}`}
                  style={{
                    borderBottom:
                      idx === serviceSelected.questionnaire.length - 1
                        ? "none"
                        : "1px solid #eee",
                    paddingBottom: "24px",
                    marginRight: "22px",
                  }}>
                  <span className="theme-dark-text bold text-normal">
                    {question.description}
                  </span>
                  <div
                    className="d-flex flex-column gap-12"
                    style={{ paddingLeft: "24px" }}>
                    {question.allowMultipleOptions
                      ? question.options.map((opt) => (
                          <Checkbox
                            id={opt.id}
                            style={{ fontWeight: "400" }}
                            className="main-text"
                            key={`${opt.id}-${
                              question.optionIds.includes(opt.id) === true
                            }`}
                            checked={question.optionIds.includes(opt.id)}
                            label={opt.description}
                            handleChange={(id, checked) => {
                              let newSvc = Object.assign({}, serviceSelected, {
                                questionnaire:
                                  serviceSelected.questionnaire.map((q) => {
                                    if (q.id === question.id) {
                                      let newOptions = !checked
                                        ? q.optionIds.filter(
                                            (optId) => optId !== opt.id
                                          )
                                        : q.optionIds.concat([opt.id]);

                                      return Object.assign({}, q, {
                                        optionIds: newOptions,
                                        completed: newOptions.length > 0,
                                      });
                                    }
                                    return q;
                                  }),
                              });

                              setServiceSelected(newSvc);
                            }}
                          />
                        ))
                      : question.options.map((opt) => (
                          <RadioButton
                            groupName={`${question.id}-answers`}
                            style={{ fontWeight: "400" }}
                            className="main-text"
                            id={opt.id}
                            key={`${opt.id}-${
                              question.optionIds.includes(opt.id) === true
                            }`}
                            checked={question.optionIds.includes(opt.id)}
                            label={opt.description}
                            handleChange={(id, checked) => {
                              let newSvc = Object.assign({}, serviceSelected, {
                                questionnaire:
                                  serviceSelected.questionnaire.map((q) => {
                                    if (q.id === question.id) {
                                      let newOptions = !checked
                                        ? q.optionIds.filter(
                                            (optId) => optId !== opt.id
                                          )
                                        : [opt.id];

                                      return Object.assign({}, q, {
                                        optionIds: newOptions,
                                        completed: newOptions.length > 0,
                                      });
                                    }
                                    return q;
                                  }),
                              });

                              setServiceSelected(newSvc);
                            }}
                          />
                        ))}
                  </div>

                  {question.allowFreeText === true && (
                    <React.Fragment>
                      <Input
                        maxLength={500}
                        // style={{ marginLeft: "24px" }}
                        required
                        value={question.notes || ""}
                        onChange={(e) => {
                          let text = e.target.value;
                          setServiceSelected((prev) => {
                            return Object.assign({}, prev, {
                              questionnaire: prev.questionnaire.map((q) => {
                                if (q.id === question.id) {
                                  return Object.assign(q, {
                                    notes:
                                      text.length > 500
                                        ? text.substring(0, 499)
                                        : text,
                                  });
                                }
                                return q;
                              }),
                            });
                          });
                        }}
                        placeholder={t("appointmentDetails.questionAnswer")}
                        type="text"
                      />
                      <div
                        style={{
                          marginTop: "-12px",
                        }}>
                        <span className="text-red text-small semibold float-left">
                          Required
                        </span>

                        <span
                          className="theme-dark-text semibold text-small float-right"
                          style={{
                            marginLeft: "auto",
                            marginRight: "-16px",
                          }}>
                          {question.notes ? question.notes.length : 0}/500
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
    </SideBar>
  );
};

export default ServiceSidebar;
