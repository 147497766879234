import React, { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import MaskShapeImage from "../MaskShapeImage"
import Svg from "../../components/Svg"
import Loader from "../Loader"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"

const SideBar = (props) => {
  const { t } = useTranslation("common")
  const rootRef = useRef(null)
  const user = useSelector((state) => state.user)

  useEffect(() => {
    window.addEventListener("sidebarOpened", onSidebarOpened)
    return function cleanup() {
      window.removeEventListener("sidebarOpened", onSidebarOpened)
    }
  }, [])

  useEffect(() => {
    if (props.isOpen) {
      window.dispatchEvent(
        new CustomEvent("sidebarOpened", {
          detail: {
            group: props.sidebarGroup,
          },
        })
      )
    }
  }, [props.isOpen, props.sidebarGroup])

  const onSidebarOpened = (instance) => {
    try {
      const openedGroup = instance.detail.group

      if (props.sidebarGroup !== openedGroup && rootRef && rootRef.current && rootRef.current.classList) {
        if (rootRef.current.classList.contains("sidebar-open")) {
          props.toggle()
        }
      }
    } catch (err) {
      console.warn(err)
    }
  }

  const handleOutsideClick = (e) => {
    if (e.target.getAttribute("name") !== "outsidesidebar") {
      e.preventDefault()
      e.stopPropagation()
    } else {
      props.toggle()
      closeCallback()
    }
  }

  const closeCallback = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const backCallback = () => {
    if (props.onBack) {
      props.onBack()
    }
  }

  return (
    <React.Fragment>
      <div
        ref={rootRef}
        name="outsidesidebar"
        onClick={(e) => {
          handleOutsideClick(e)
        }}
        className={props.isOpen ? "opacity-background sidebar-open" : ""}>
        <div className={props.isOpen ? "side-bar open" : "side-bar close"} style={props.style}>
          <div className="side-bar-content">
            {props.service ? (
              <div className={"side-bar-header filled d-flex flex-column gap-6"}>
                <div
                  onClick={() => {
                    props.toggle()
                    closeCallback()

                    if (props.back) {
                      backCallback()
                    }
                  }}
                  className={"side-bar-toggle knockout"}>
                  <Svg style={{ height: "32px", width: "32px", color: "white" }} name={props.back ? "icon_arrow_left_blue" : "icon_x"} />
                </div>
                <h1 className={"side-bar-title knockout"}>{props.title}</h1>
                {(props.forceImage || (props.picutreUrl && props.picutreUrl.url)) && (
                  <MaskShapeImage image={props.picutreUrl ? props.picutreUrl.url : null} className="service-sidebar-mask" index={0} />
                )}
              </div>
            ) : (
              <div className="side-bar-header d-flex flex-column gap-6">
                <div
                  onClick={() => {
                    props.toggle()
                    closeCallback()

                    if (props.back) {
                      backCallback()
                    }
                  }}
                  className={"side-bar-toggle"}>
                  <Svg className="theme-dark-text" style={{ height: "32px", width: "32px" }} name={props.back ? "icon_arrow_left_blue" : "icon_x"} />
                </div>
                {user.hasCorporateProfile ? <h1 className={"side-bar-title"}>{props.title}</h1> : <h1 className={"side-bar-title"}>{props.title}</h1>}
              </div>
            )}

            <div className="side-bar-body">
              <div className="d-flex flex-column">{props.children}</div>
            </div>
            {props.footer && <div className="side-bar-footer">{props.footer}</div>}
          </div>
          {(props.loading || props.error) && (
            <div className="side-bar-overlay">
              {props.loading && <Loader title={t("common.loading")} />}
              {props.error && (
                <div className="d-flex flex-column" style={{ margin: "0 5%" }}>
                  <Button
                    className="p-0"
                    style={{ marginRight: "-16px", marginBottom: "-12px" }}
                    onClick={() => {
                      if (props.onOverlayDismiss) {
                        props.onOverlayDismiss()
                      }
                    }}
                    color="link error-text ml-auto">
                    <Svg name="icon_cancel" style={{ width: "24px", height: "24px" }} />
                  </Button>
                  <div className="error-alert mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
                    {props.error}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default SideBar
