import React from "react"
import { Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import { formatMoney } from "../../helpers/common"
import Svg from "../Svg"

const ServiceCard = (props) => {
  const { i18n } = useTranslation("common")

  return (
    <Card className="service-card" title={props.service.title} onClick={props.onClick}>
      <CardBody style={{ position: "relative", overflow: "hidden" }}>
        <div className="d-flex items-center justify-content-start">
          <div className="flex flex-col items-start">
            <p
              className="theme-dark-text text-normal bold m-0"
              style={{
                lineHeight: "18px",
                maxHeight: "36px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "82px",
              }}>
              {props.service.title}
            </p>
            {/* <p className="main-text text-small text-normal semibold m-0 mt-1">
              {formatMoney(props.service.price, i18n.language)}
            </p> */}

            {props.service.ageRestriction && <p className="main-text text-small text-normal bold m-0 mt-3">{props.service.ageRestriction}</p>}
          </div>
        </div>

        {props.service.picture?.url ? (
          <img src={props.service.picture.url} className="service-image" alt={props.service.title} />
        ) : (
          <div className="service-image d-flex items-center justify-content-center" style={{ opacity: 0.7 }}>
            <Svg name="avvy-shape" className="sub-text" style={{ width: "32px", height: "32px" }} />
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default ServiceCard
