import React from "react"
import * as url from "../../urls"
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom"

import GetStarted from "../../views/IndexSections/GetStarted"
import Languages from "../../views/IndexSections/Languages"
import Dashboard from "../../views/Dashboard/index.js"
import CreateAppointment from "../../views/Appointments/Create.js"
import CreateAppointmentMember from "../../views/Appointments/SelectMember.js"
import EditAppointment from "../../views/Appointments/Edit.js"
import ViewAppointment from "../../views/Appointments/View.js"
import Welcome from "../../views/Auth/Welcome.js"
import Login from "../../views/Auth/Login.js"
import SignUpPersonalInfo from "../../views/Auth/SignUp/SignUpPersonalInfo.js"
import SignUpPassword from "../../views/Auth/SignUp/SignUpPassword.js"
import SignUpPhoneVerification from "../../views/Auth/SignUp/SignUpPhoneVerification.js"
import SignUpEmailVerification from "../../views/Auth/SignUp/SignUpEmailVerification.js"
import ForgotPassword from "../../views/Auth/ForgotPassword.js"
import ResetPassword from "../../views/Auth/ResetPassword.js"
import CorporateLinkView from "../../views/Corporate/LinkView"

import PrivateRoute from "./PrivateRoute"
import NavBar from "../Navbars/NavBar.js"
import Footer from "../Navbars/Footer.js"
import Svg from "../../components/Svg"

import PrivacyPolicy from "../../views/PlatformInformation/PrivacyPolicy.js"
import TermsOfService from "../../views/PlatformInformation/TermsOfService.js"

const AppRouter = () => {
  const AuthLayout = ({ children }) => {
    return (
      <div className="default-layout">
        <div className="logo-section">
          <Svg name="app-masthead" className="top-layout-arc" />
          <Svg name="logo-nav" className="accent-text top-layout-logo" />
        </div>
        <div className="content-section">{children}</div>
      </div>
    )
  }

  const SignUpLayout = ({ children }) => {
    return (
      <div className="default-layout">
        <div className="logo-section">
          <Svg name="app-masthead" className="top-layout-arc" />
          <Svg name="logo-nav" className="accent-text top-layout-logo" />
        </div>
        <div className="content-section">{children}</div>
      </div>
    )
  }

  const DashboardLayout = ({ children }) => {
    return (
      <div className="authorized-layout">
        <NavBar />
        {children}
        <Footer />
      </div>
    )
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path={url.LANGUAGE} exact component={Languages} />
        <Route path={url.AUTH_WELCOME_URL} exact component={Welcome} />
        <Route path="/" exact component={GetStarted} />

        <Route path={url.AUTH_PRIVACY_POLICY_PAGE}>
          <AuthLayout>
            <Switch>
              <Route path={url.AUTH_PRIVACY_POLICY_PAGE} exact component={PrivacyPolicy} />
            </Switch>
          </AuthLayout>
        </Route>

        <Route path={url.AUTH_TERMS_OF_SERVICE_PAGE}>
          <AuthLayout>
            <Switch>
              <Route path={url.AUTH_TERMS_OF_SERVICE_PAGE} exact component={TermsOfService} />
            </Switch>
          </AuthLayout>
        </Route>

        <Route path="/corporate/join">
          <DashboardLayout>
            <Switch>
              <Route path={url.CORPORATE_LINK_PAGE} exact component={CorporateLinkView} />
            </Switch>
          </DashboardLayout>
        </Route>

        <Route path="/appointment/:path?">
          <DashboardLayout>
            <Switch>
              <PrivateRoute path={url.CREATE_APPOINTMENT_URL} exact component={CreateAppointment} />
              <PrivateRoute path={url.CREATE_APPOINTMENT_MEMEBER_URL} exact component={CreateAppointmentMember} />
              <PrivateRoute path={url.EDIT_APPOINTMENT_URL} component={EditAppointment} />
              <PrivateRoute path={url.VIEW_APPOINTMENT_URL} component={ViewAppointment} />
            </Switch>
          </DashboardLayout>
        </Route>

        <Route path={url.DASHBOARD_URL}>
          <DashboardLayout>
            <Switch>
              <PrivateRoute path={url.DASHBOARD_URL} exact component={Dashboard} />
            </Switch>
          </DashboardLayout>
        </Route>

        <Route path="/auth/:path?">
          <AuthLayout>
            <Switch>
              <Route path={url.AUTH_LOGIN_URL} exact component={Login} />
              <Route path={url.AUTH_RESET_PASSWORD_URL} exact component={ResetPassword} />
              <Route path={url.AUTH_FORGOT_PASSWORD_URL} exact component={ForgotPassword} />
            </Switch>
          </AuthLayout>
        </Route>

        <Route path="/sign-up/:path?">
          <SignUpLayout>
            <Switch>
              <Route path={url.AUTH_SIGN_UP_PERSONAL_INFO_URL} exact component={SignUpPersonalInfo} />
              <Route path={url.AUTH_SIGN_UP_PASSWORD_URL} exact component={SignUpPassword} />
              <Route path={url.AUTH_SIGN_UP_PHONE_VERIFICATION} exact component={SignUpPhoneVerification} />
              <Route path={url.AUTH_SIGN_UP_EMAIL_VERIFICATION} exact component={SignUpEmailVerification} />
            </Switch>
          </SignUpLayout>
        </Route>

        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  )
}

export default AppRouter
