import React from "react"
import { Modal, ModalBody, Row, Col, Button } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

const ConfirmModal = (props) => {
  const { t } = useTranslation("common")

  return (
    <Modal className="modal-dialog-centered confirm-modal" style={{ minWidth: 600 }} isOpen={props.modalOpen} toggle={() => props.toggle()}>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <h2 style={{ color: "#313131", fontSize: 19, fontWeight: 700 }}>{props.header}</h2>
          </Col>
          <Col lg={12}>
            <p>{props.body}</p>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "end" }} lg={12}>
            <Button outline color="secondary" onClick={() => props.toggle()}>
              {props.cancelButton ? props.cancelButton : t("appointmentDetails.btnCancel")}
            </Button>
            <Button className="btn-accent" onClick={() => props.callBack()}>
              {props.confirmButton ? props.confirmButton : t("cancelAppointment.btn")}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmModal
