import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { Link, Redirect } from "react-router-dom"
import { Button, FormGroup, InputGroup, Input } from "reactstrap"
import { AUTH_SIGN_UP_PASSWORD_URL, AUTH_SIGN_UP_PERSONAL_INFO_URL } from "../../../urls"
import Svg from "../../../components/Svg"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { parseError } from "../../../helpers/common"
import Loader from "../../../components/Loader"

const SignUpPhoneVerification = (props) => {
  const { t } = useTranslation("common")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [code, setCode] = useState("")
  const [securityCodeSeconds, setSecurityCodeSeconds] = useState(0)

  useEffect(() => {
    if (!window.signupState || !window.signupState.phone) {
      return
    }

    window.scrollTo({
      behavior: "smooth",
      top: 0,
    })

    setCode("")
    setError(null)
    setLoading(false)

    resendCode()

    return () => {
      clearInterval(window.securityCodeInterval)
    }
  }, [])

  useEffect(() => {
    if (securityCodeSeconds <= 0) {
      clearInterval(window.securityCodeInterval)
    } else if (securityCodeSeconds === 60) {
      window.securityCodeInterval = setInterval(() => {
        setSecurityCodeSeconds((seconds) => seconds - 1)
      }, 1000)
    }
  }, [securityCodeSeconds])

  const resendCode = () => {
    setLoading(true)
    setError(null)

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/sms/${encodeURIComponent(window.signupState.phone)}`)
      .then((res) => {
        setLoading(false)
        setSecurityCodeSeconds(60)
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)
        setError(error)
      })
  }

  const verifyCode = () => {
    setLoading(true)
    setError(null)

    const phoneData = {
      phone: window.signupState.phone,
      code: code,
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/confirmphone`, phoneData)
      .then((res) => {
        setLoading(false)
        props.history.push(AUTH_SIGN_UP_PASSWORD_URL)
      })
      .catch((err) => {
        setLoading(false)
        let error = parseError(err)
        setError(error)
      })
  }

  if (!window.signupState || !window.signupState.phone) {
    return <Redirect to={AUTH_SIGN_UP_PERSONAL_INFO_URL} />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Noosu - {t("accountVerification.title")}</title>
      </Helmet>
      <div className="d-flex flex-column" style={{ width: "90%", maxWidth: "480px" }}>
        <Link to={AUTH_SIGN_UP_PERSONAL_INFO_URL}>
          <Svg className="page-back-arrow" name="arrow-left" />
        </Link>

        <h2 className="mb-3">{t("accountVerification.title")}</h2>

        <div style={{ width: "100%" }} className="d-flex flex-column gap-12">
          <div className="main-text text-normal mb-2" dangerouslySetInnerHTML={{ __html: t("personalInfo.phoneVerificationNote") }}></div>

          <FormGroup>
            <InputGroup className="input-group-alternative">
              <Input required placeholder={t("emailVerification.placeholder")} type="text" value={code} onChange={(e) => setCode(e.target.value)} />
            </InputGroup>
          </FormGroup>

          <Button disabled={loading || securityCodeSeconds > 0} className="btn-link text-underline ml-auto mr-0" onClick={() => resendCode()}>
            {t("accountVerification.ButtonResend")} {securityCodeSeconds > 0 ? `(${securityCodeSeconds}s)` : null}
          </Button>

          <Button disabled={loading || !code} className="btn-accent mt-4" type="button" onClick={() => verifyCode()}>
            {t("personalInfo.buttonText")}
          </Button>

          {loading && <Loader className="mt-4" title={t("common.loading")} />}
          {error && (
            <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
              {error}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default SignUpPhoneVerification
